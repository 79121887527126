import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from '../util/api-client';
import DataTable from 'react-data-table-component';
import themeconfig from '../Config/themeconfig';
const Users = () => {
  let active_user_id = localStorage.getItem('active-user-id')
  const [all_user_data, setall_user_data] = useState([])
  const [pending, setpending] = useState(true);

  // get all user list

  const get_all_user = () => {
    let obj = {
      parent_id: active_user_id
    }
    var config = {
      method: 'get',
      url: themeconfig.APILinks.user_list,
      headers: {

      },
      params: obj
    };

    axios(config)
      .then(function (response) {
        if (response) {
          if (response.data.result === true) {
            setall_user_data(response.data.data)
            // console.log(response.data.data)
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      }).finally(() => {
        setpending(false);
      });

  }

  // get all user data table list columns

  const columns = [
    {
      name: '#',
      selector: (row) => row.id,
      sortable: true,
      width: '100px',
      cell: (row) => {
        return (
          <div>{row.id}</div>
        )
      }
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      width: '300px',
      cell: (row) => {
        return (
          <div>{row.name}</div>
        )
      }
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      width: '450px',
      cell: (row) => {
        return (
          <div>{row.email}</div>
        )
      }
    },
    {
      name: 'Edit',
      selector: row => row.id,
      width: '250px',
      cell: (row) => {
        return (
          <div>
            <Link to={`/users/edit-user/?id=${row.id}`}>
              <span><i className="fas fa-fw fa-edit"></i></span>
            </Link>
          </div>
        )
      }
    }
  ];

  // date table pagination

  const paginationComponentOptions = {
    rowsPerPageText: 'Per Page',
    rangeSeparatorText: 'of',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  useEffect(() => {
    get_all_user()
  }, [])


  return (
    <div>
      <div className="container-fluid">

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">All Users</h1>
          <Link to="/users/add-user" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-sm text-white-50"></i> Add User</Link>
        </div>

        <div className="row recent-scans">

          <DataTable
            columns={columns}
            data={all_user_data}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            progressPending={pending}
            progressComponent={
              <h6>Loading...</h6>
            }
          />

        </div>
      </div>
    </div>
  )
}

export default Users