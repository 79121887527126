
import React, { useEffect, useState } from 'react'
import axios from '../util/api-client';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import themeconfig from '../Config/themeconfig';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const Model = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [project_data, setproject_data] = useState({})
  const get_project_data = () => {
    let params_obj = {
      id: id
    }
    var config = {
      method: 'get',
      url: themeconfig.APILinks.get_all_project,
      headers: {

      },
      params: params_obj
    };

    axios(config)
      .then(function (response) {
        if (response) {
          if (response.data.result === true) {
            let project_data = response.data.data
            project_data.map((project) => {
              setproject_data(project)
              get_models(project.embed)
            })
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      }).finally(() => {
        // Set loading to false after the request is complete
        setIsLoading(false);
      });

  }

  const get_models = (models = []) => {
    if (models) {
      if (models.length > 0) {
        return (
          <div>
            <div className="scan-nav">
              {
                models.map((url, index) => {
                  return (
                    <a key={index} href={`#${url.id}`}>{url.name}</a>
                  )
                })
              }
            </div>
            {
              models.map((data, index) => {
                return (
                  <div className="col-xl-12 col-lg-12" key={index}>
                    <div>
                      {
                        (window.innerWidth <= 768)
                          ?
                          <div className='position-relative'>
                            <a href={`https://my.matterport.com/show/?m=${data.url}`} className='w-100 h-100 d-block z-1 position-absolute top left' target='_blank'></a>
                            <iframe
                              id={data.id}
                              src={`https://my.matterport.com/show/?m=${data.url}`}
                              className="x-iframe"
                              height="400px"
                              width="100%"
                              allowFullScreen
                            />
                          </div>
                          :
                          <iframe
                            id={data.id}
                            src={`https://my.matterport.com/show/?m=${data.url}`}
                            className="x-iframe"
                            height="400px"
                            width="100%"
                            allowFullScreen
                          />
                      }
                    </div>
                    <hr />
                  </div>
                )
              })
            }

          </div>
        )
      } else {
        return (
          <div>
            <div className="wc-nomodel-found-main d-flex flex-column justify-content-center align-items-center">
              <h1>No models to display</h1>
              <div className='wc-nomodel-found'>
                <img src={themeconfig.image.xtour_image} />
              </div>
              <Link to={`/project-booking`}><button type='button' className='btn btn-dark'>Click here to book a project</button></Link>
            </div>
          </div>
        )
      }
    }
  }

  const Skeleton_show = () => {
    return (
      <div>
        <div className="container-fluid">
          <div className="row project-scan">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800"><Skeleton height={38} width={340} /></h1>
            </div>
            <Skeleton height={600} width={1500} />
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    get_project_data()
  }, [])

  if (isLoading) {
    return (<div>
      {Skeleton_show()}
    </div>)
  } else {
    return (
      <div>
        <div className="container-fluid">
          <div className="row project-scan">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">{project_data && project_data.title}</h1>
            </div>
            {get_models(project_data.embed)}
          </div>
        </div>
      </div>
    );
  }
}

export default Model