import React, { useState } from 'react'
import { Link } from "react-router-dom";
import themeconfig from '../../Config/themeconfig';
import { ToastContainer, toast } from 'react-toastify'
import $ from 'jquery';
import { sendPasswordResetEmail } from "firebase/auth";
import { useAuth } from "reactfire";

const Forgotpassword = () => {
    const auth = useAuth();
    const [email_error, setemail_error] = useState('');
    const forgotpassword_submit = (event) => {
        event.preventDefault();
        $('#submit_btn').addClass('wc-spinner');
        const formElement = document.querySelector('#forgot_pass_Form');
        const formData = new FormData(formElement);
        const formDataJSON = Object.fromEntries(formData);

        // vaidation

        let isValid = true;
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!formDataJSON.email.trim()) {
            $('#submit_btn').removeClass('wc-spinner');
            setemail_error('Email address is required.')
            isValid = false
        } else if (!formDataJSON.email.match(validRegex)) {
            $('#submit_btn').removeClass('wc-spinner');
            setemail_error('Enter valid email address.');
            isValid = false
        } else {
            setemail_error('')
            isValid = true
        }



        if (isValid === true) {
            sendPasswordResetEmail(auth, formDataJSON.email, {
                url: themeconfig.site_url,
            })

            toast.success(`An email has been sent to ${formDataJSON.email}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            formElement.reset();
            $('#submit_btn').removeClass('wc-spinner');
        }
    }

    return (
        <div className="bg-gradient-primary">
            <ToastContainer />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <img
                                                    className="log-logo"
                                                    src={themeconfig.image.xtour_image}
                                                    alt="Logo"
                                                />
                                                <h6>Forgot Password</h6><br />
                                            </div>
                                            <form
                                                onSubmit={forgotpassword_submit}
                                                className="user"
                                                id="forgot_pass_Form"
                                            >
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-user"
                                                        name="email"
                                                        placeholder="Enter Email Address..."
                                                    />
                                                    {email_error && (
                                                        <p className="error-message text-danger m-1">{email_error}</p>
                                                    )}
                                                </div>
                                                <button
                                                    id='submit_btn'
                                                    type="submit"
                                                    className="btn btn-dark btn-user btn-block"
                                                >
                                                    Reset Password
                                                </button>
                                                <hr />
                                            </form>
                                            <hr />
                                            <div className="text-center">
                                                <Link to='/' >
                                                    <span style={{ color: "black", textDecoration: "none" }}>
                                                        Don’t forgot password? Log in
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Forgotpassword