import React, { useEffect, useState } from "react";
import $ from 'jquery';
import axios from '../../util/api-client';
import { ToastContainer, toast } from 'react-toastify'
import themeconfig from '../../Config/themeconfig';
import { sendSignInLinkToEmail, GoogleAuthProvider, signInWithPopup, browserPopupRedirectResolver } from "@firebase/auth";
import { useAuth, useUser } from "reactfire";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const auth = useAuth();
    const { data: user } = useUser();
    const [usernameError, setusernameError] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    // state variable
    const [formData, setFormData] = useState({
        username: '',
    });

    useEffect(() => {
        const verifyUser = async (accessToken) => {
        var config = {
            method: 'POST',
            url: themeconfig.APILinks.Login,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        };

        axios(config).then(function (response) {
            if (response.data) {
                if (response?.data?.result === true) {
                    const active_user = response?.data?.user_data;
                    const active_user_id = response?.data?.user_data.id;
                    const role = response.data?.user_data?.user_role;
                    const parent_id = active_user.parent_id
                    localStorage.setItem('active-user-id', active_user_id);
                    localStorage.setItem('user_role', role);
                    localStorage.setItem('parent_id', parent_id);

                    var date = new Date();
                    date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
                    var expires = "; expires=" + date.toGMTString();
                    document.cookie = 'login' + "=" + 'true' + expires + "; path=/";

                    $('#login_btn').removeClass('wc-spinner');
                    toast.success(response?.data?.message, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    setTimeout(() => {
                        navigate('/dashboard');
                    }, 1000);
                } else {
                    $('#login_btn').removeClass('wc-spinner');
                    setIsLoading(false);
                }
            }
        }).catch(function (error) {
            console.log(error);
            $('#login_btn').removeClass('wc-spinner');
            setIsLoading(false);
        });
        }
        
        if (user) {
            setIsLoading(true);
            user.getIdToken().then((token) => {
                verifyUser(token);
            }).catch((error) => {
                console.error(error);
                setIsLoading(false);
            })
        } else {
            setIsLoading(false);
        }
    }, [navigate, user]);

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: newValue,
        }));
    };

    // Google Sign In
    const doProviderSignIn = async (provider) => {
        try {
            $('#login_btn_google').addClass('wc-spinner');
            await signInWithPopup(auth, provider, browserPopupRedirectResolver);
            // create user in your database here
            toast({ title: "Signed in!" });
        } catch (err) {
            $('#login_btn_google').removeClass('wc-spinner');
            toast.warn('A problem occurred with Google. Please try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.error(err);
        } finally {
            $('#login_btn_google').removeClass('wc-spinner');
        }
        $('#login_btn_google').removeClass('wc-spinner');
    };

    // Sending Email Sign In Link
    const handleLoginFormSubmit = async (event) => {
        event.preventDefault();

        $('#login_btn').addClass('wc-spinner');

        let isValid = true;

        if (!formData.username.trim()) {
            $('#login_btn').removeClass('wc-spinner');
            setusernameError('Username is required!')
            isValid = false
        } else {
            setusernameError('')
            isValid = true
        }

        if (isValid === true) {
            // First Firebase Authentication
            sendSignInLinkToEmail(auth, formData.username, {
                url: `${themeconfig.site_url}/email-login`,
                handleCodeInApp: true,
            }).then(() => {
                window.localStorage.setItem('emailForSignIn', formData.username);
                $('#login_btn').removeClass('wc-spinner');
                toast.success('Email sent successfully!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }).catch((error) => {
                $('#login_btn').removeClass('wc-spinner');
                toast.warn('Please try after some time!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.error(error);
            });
        }
    };

    return (
        <React.Fragment>
            <>
                <ToastContainer />
            </>
            <div className="bg-gradient-primary">

                <div className="container">
                    <div className="row min-vh-100 align-items-center justify-content-center">
                        <div className="col-12">
                            <div className="card login-wrap o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">

                                    <div className="p-3 p-md-5">
                                        <div className="text-center">
                                            <img
                                                className="log-logo"
                                                src={themeconfig.image.xtour_image}
                                                alt="Logo"
                                            />
                                        </div>
                                        {isLoading ? (
                                            <div className='wc-custom-loading' style={{ minHeight: '200px' }}>
                                                <div className="spinner-border mx-auto text-secondary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (   
                                            <>
                                                <form
                                                    className="user"
                                                    id="loginForm"
                                                    onSubmit={handleLoginFormSubmit}
                                                >
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-user"
                                                            name="username"
                                                            placeholder="Enter Email Address..."
                                                            value={formData.username}
                                                            onChange={handleInputChange}
                                                        // required
                                                        />
                                                        {usernameError && <p className="error-message text-danger">{usernameError}</p>}
                                                    </div>
                                                    <button
                                                        id='login_btn'
                                                        // type="submit"
                                                        className="btn btn-dark btn-user btn-block"
                                                    >
                                                        Login with Email
                                                    </button>
                                                    <hr />
                                                </form>
                                                <button
                                                    id='login_btn_google'
                                                    // type="submit"
                                                    className="btn btn-block btn-google"
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        const provider = new GoogleAuthProvider();
                                                        await doProviderSignIn(provider);
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height="1em"
                                                        viewBox="0 0 488 512"
                                                        fill="currentColor"
                                                        className="mr-2"
                                                    >
                                                        <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
                                                    </svg>
                                                    Login with Google
                                                </button>
                                                <hr />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Login;