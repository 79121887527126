import React, { useEffect, useState } from "react";
import axios from 'axios';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from "react-router-dom";
import themeconfig from '../../Config/themeconfig';
import { isSignInWithEmailLink, signInWithEmailLink } from "@firebase/auth";
import { useAuth } from "reactfire";

const EmailLogin = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [usernameError, setusernameError] = useState('')
    const [isLoading, setIsLoading] = useState(true);

    // state variable
    const [formData, setFormData] = useState({
        username: '',
    });

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: newValue,
        }));
    };

    // Sending Email Sign In Link
    const handleEmailSubmit = async (event) => {
        event.preventDefault();

        $('#login_btn').addClass('wc-spinner');

        let isValid = true;

        if (!formData.username.trim()) {
            $('#login_btn').removeClass('wc-spinner');
            setusernameError('Email is required!')
            isValid = false
        } else {
            setusernameError('')
            isValid = true
        }

        if (isValid === true) {
            handleSignInWithEmail(formData.username);
        }
    };

    // Verify firebase token on the backend
    const verifyUser = async (accessToken) => {
        var config = {
            method: 'POST',
            url: themeconfig.APILinks.Login,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        };

        axios(config).then(function (response) {
            if (response.data) {
                if (response?.data?.result === true) {
                    const active_user = response?.data?.user_data;
                    const active_user_id = response?.data?.user_data.id;
                    const role = response.data?.user_data?.user_role;
                    const parent_id = active_user.parent_id
                    localStorage.setItem('active-user-id', active_user_id);
                    localStorage.setItem('user_role', role);
                    localStorage.setItem('parent_id', parent_id);

                    var date = new Date();
                    date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
                    var expires = "; expires=" + date.toGMTString();
                    document.cookie = 'login' + "=" + 'true' + expires + "; path=/";

                    $('#login_btn').removeClass('wc-spinner');
                    toast.success(response?.data?.message, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    setTimeout(() => {
                        navigate('/dashboard');
                    }, 1000);
                } else {
                    $('#login_btn').removeClass('wc-spinner');
                    toast.error(response?.data?.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                }
            }
        }).catch(function (error) {
            console.log(error);
            $('#login_btn').removeClass('wc-spinner');
            toast.warn('Please try after some time!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        });
    }

    const handleSignInWithEmail = async (email) => {
        signInWithEmailLink(auth, email, window.location.href)
            .then((result) => {
                window.localStorage.removeItem('emailForSignIn');
                result.user.getIdToken().then((accessToken) => {
                    verifyUser(accessToken);
                });
            })
            .catch((error) => {
                toast.warn('Please try after some time!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            });
    }

    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            let emailFromCookies = window.localStorage.getItem('emailForSignIn');

            if (emailFromCookies) {
                handleSignInWithEmail(emailFromCookies);
            } else {
                setIsLoading(false);
            }
        } else {
            navigate('/login');
        }
    }, [])

    return (
        <React.Fragment>
            <>
                <ToastContainer />
            </>
            <div className="bg-gradient-primary">
                <div className="container">
                    <div className="row min-vh-100 align-items-center justify-content-center">
                        <div className="col-12">
                            <div className="card login-wrap o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">

                                    <div className="p-3 p-md-5">
                                        <div className="text-center">
                                            <img
                                                className="log-logo"
                                                src={themeconfig.image.xtour_image}
                                                alt="Logo"
                                            />
                                        </div>
                                        {isLoading ? (
                                            <div className='wc-custom-loading' style={{ minHeight: '200px' }}>
                                                <div className="spinner-border mx-auto text-secondary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (                                                
                                            <form
                                                className="user"
                                                id="loginForm"
                                                onSubmit={handleEmailSubmit}
                                            >
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-user"
                                                        name="username"
                                                        placeholder="Enter Email Address..."
                                                        value={formData.username}
                                                        onChange={handleInputChange}
                                                    // required
                                                    />
                                                    {usernameError && <p className="error-message text-danger">{usernameError}</p>}
                                                </div>
                                                <button
                                                    id='login_btn'
                                                    className="btn btn-dark btn-user btn-block"
                                                >
                                                    Verify
                                                </button>
                                                <hr />
                                            </form>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EmailLogin;