import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from '../util/api-client';
import { ToastContainer, toast } from 'react-toastify'
import $, { event } from 'jquery';
import themeconfig from '../Config/themeconfig';
import { useNavigate } from "react-router-dom";
const AddProject = () => {
    const navigate = useNavigate();
    let user_id = localStorage.getItem('active-user-id')
    // let user_role = localStorage.getItem('user_role')
    const [formFields, setFormFields] = useState({
        title: '',
        embed_url: [{ name: '', url: '' }],
        client_id: '',
        file: [{}]
    });
    const [client, setClient] = useState([]);

    // State variables for validation errors
    const [titleError, setTitleError] = useState('');
    const [urlErrors, setUrlErrors] = useState(['']);
    const [clientError, setClientError] = useState('');
    const [file_error, setfile_error] = useState('')
    // const [multiple_file, setmultiple_file] = useState([]);

    // get client form api 

    const get_client = () => {
        let obj = {
            user_role: 1
        }
        var config = {
            method: 'post',
            url: themeconfig.APILinks.get_client_data,
            headers: { "Content-Type": "multipart/form-data" },
            params: obj
        };

        axios(config)
            .then(function (response) {
                if (response) {
                    if (response.data.result === true) {
                        setClient(response.data.data);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleAddFields = () => {
        let have_url = true
        formFields.embed_url.map((val) => {
            if (val.url == '') {
                have_url = false
            }
        })

        if (have_url) {
            setFormFields((prevFormFields) => ({
                ...prevFormFields,
                embed_url: [...prevFormFields.embed_url, { name: '', url: '' }],
            }));
            setUrlErrors((prevUrlErrors) => [...prevUrlErrors, '']); // Add a new error entry for the newly added input field
        }

    };

    const handle_Add_fileFields = () => {
        let have_file = true
        formFields.file.map((val) => {
            if (val.name === undefined) {
                have_file = false
            }
        })
        if (have_file) {
            setFormFields((prevFormFields) => ({
                ...prevFormFields,
                file: [...prevFormFields.file, {}]
            }));
        }

    }

    const handle_Remove_file_Fields = (index) => {
        const updatedFields = { ...formFields };
        updatedFields.file.splice(index, 1);
        setFormFields(updatedFields);
    }

    const handle_fileChangeInput = (index, event) => {
        const updatedFields = { ...formFields };
        updatedFields.file[index] = event.target.files[0];
        setFormFields(updatedFields)
    }

    // get project value 

    const handleChangeInput = (index, event, type = '') => {
        let name = ''
        let url = ''
        if (type) {
            if (type == 'name') {
                name = event.target.value
            } else {
                url = event.target.value
            }
        }

        const formData = new FormData();
        const updatedFields = { ...formFields };

        if (type) {
            if (type == 'name') {
                updatedFields.embed_url[index].name = name;
            } else {
                updatedFields.embed_url[index].url = url;
            }
        }

        setFormFields(updatedFields);
    };

    // reapeter input remove 

    const handleRemoveFields = (index) => {
        const updatedFields = { ...formFields };
        updatedFields.embed_url.splice(index, 1);
        setFormFields(updatedFields);
        setUrlErrors((prevUrlErrors) => {
            const newErrors = [...prevUrlErrors];
            newErrors.splice(index, 1); // Remove the error entry for the removed input field
            return newErrors;
        });
    };

    // add project save

    const save_project = (event) => {
        event.preventDefault();

        $('#add_project_submit').addClass('wc-spinner');

        // Reset all previous errors
        setTitleError('');
        setClientError('');
        setfile_error('');
        setUrlErrors((prevUrlErrors) => prevUrlErrors.map(() => ''));

        // Perform custom validation here
        let isValid = true;

        if (formFields.title.trim() === '') {
            setTitleError('Project Address is required.');
            isValid = false;
        }
        // if (multiple_file.length === 0) {
        //     setfile_error('Document is required')
        //     isValid = false;
        // }

        const urlFormatRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        if (formFields.embed_url.length > 1) {
            const newUrlErrors = formFields.embed_url.map((field) => {
                if (field.url.trim() === '') {
                    return 'URL is required!';
                }
                // else if (!urlFormatRegex.test(field.url.trim())) {
                //     return 'URL is not Valid!';
                // }
                return '';
            });
            setUrlErrors(newUrlErrors);

            // Check if any of the URLs are empty
            if (newUrlErrors.some((error) => error !== '')) {
                isValid = false;
            }
        }

        // Validate select field
        if (!formFields.client_id) {
            setClientError('Client is required!');
            isValid = false;
        }
        // If any validation fails, stop form submission
        if (!isValid) {
            $('#add_project_submit').removeClass('wc-spinner');
            return;
        } else {
            // If all validations pass, proceed with form submission const formData = new FormData();

            let title = formFields.title;
            let client_id = formFields.client_id;
            let embed_url = formFields.embed_url;
            var formData = new FormData();
            let file = formFields.file;
            formData.append('title', title);
            formData.append('embed_url', JSON.stringify(embed_url));
            formData.append('client_id', client_id);
            formData.append('user_id', user_id);

            for (let i = 0; i < file.length; i++) {
                formData.append(`file[${i}]`, file[i]);
            }

            formData.append('attribute_id', 0);
            formData.append('type', 2);
            formData.append('file_type', 'multiple')

            var config = {
                method: 'post',
                url: themeconfig.APILinks.add_project,
                headers: {},
                data: formData
            };

            axios(config)
                .then(function (response) {
                    if (response) {
                        if (response.data.result === true) {
                            $('#add_project_submit').removeClass('wc-spinner');
                            toast.success('Project Add Successfully!...', {
                                position: "top-right",
                                autoClose: 1000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            setFormFields({
                                title: '',
                                embed_url: [{ name: '', url: '' }],
                                client_id: '',
                                file: [{}]
                            });

                            setTimeout(() => {
                                navigate("/projects-admin");
                            }, 2000);
                        } else {
                            $('#add_project_submit').removeClass('wc-spinner');
                            toast.error(response.data.message, {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                })
                .catch(function (error) {
                    $('#add_project_submit').removeClass('wc-spinner');
                    console.log(error);
                });
        }
    };

    // const get_multiple_file = (event) => {
    //     let files = event.target.files
    //     const multi_file_array = Object.entries(files).map((file) => ({ [file[0]]: file[1] }));
    //     setmultiple_file(multi_file_array)
    // }

    useEffect(() => {
        get_client();
    }, []);

    return (
        <div className='container-fluid'>
            <ToastContainer />
            <form onSubmit={save_project}>
                <h3>Add New Project</h3>
                <div className="form-group">
                    <h6>Project Address</h6>
                    <input
                        type="text"
                        name="title"
                        className="form-control"
                        placeholder="Scan Type - Address"
                        value={formFields.title}
                        onChange={(e) => {
                            setFormFields({ ...formFields, title: e.target.value });
                            setTitleError(''); // Reset error when user types
                        }}
                    />
                    {titleError && <div className="error-message text-danger">{titleError}</div>}
                </div>

                <div className="form-group">
                    <h6>URL Name</h6>
                    {formFields.embed_url.map((field, index) => (
                        <div className="wc-repeater-group" key={index}>
                            <div className="form-group">
                                <h6>{`Project URL ${index + 1}`}</h6>
                                <input
                                    type="text"
                                    name="name"
                                    className="wc-url-name"
                                    placeholder="Enter URL Name"
                                    onChange={(e) => {
                                        handleChangeInput(index, e, 'name');
                                    }}
                                />
                                <input
                                    type="text"
                                    name="embed_url"
                                    className="form-control"
                                    placeholder="Enter URL"
                                    value={field.url}
                                    onChange={(e) => {
                                        handleChangeInput(index, e, 'embed_url');
                                        setUrlErrors((prevUrlErrors) => {
                                            const newErrors = [...prevUrlErrors];
                                            newErrors[index] = '';
                                            return newErrors;
                                        });
                                        // Reset error when user types
                                    }}
                                />
                                {urlErrors[index] && <div className="error-message text-danger">{urlErrors[index]}</div>}
                            </div>
                            {index > 0 && (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleRemoveFields(index)}
                                >
                                    <i className="fa-solid fa-xmark"></i>
                                </button>
                            )}
                        </div>
                    ))}
                </div>
                <div className="form-group">
                    <button type="button" className="btn btn-primary" onClick={handleAddFields}>
                        +
                    </button>
                </div>

                {/* <div className="form-group">
                    <h6 htmlFor="files">Select Documents:</h6>
                    <input type="file" id="files" name="files" onChange={(e) => get_multiple_file(e)} multiple />
                    {file_error && <div className="error-message text-danger">{file_error}</div>}
                </div> */}

                <div className="form-group">
                    <h6 htmlFor="files">Select Documents:</h6>
                    {formFields.file.map((field, index) => (
                        <div className="wc-repeater-group" key={index}>
                            <div className="form-group">
                                <input type="file" id="files" name="files" onChange={(e) => handle_fileChangeInput(index, e)} />
                            </div>
                            {index > 0 && (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handle_Remove_file_Fields(index)}
                                >
                                    <i className="fa-solid fa-xmark"></i>
                                </button>
                            )}
                        </div>
                    ))}
                </div>

                <div className="form-group">
                    <button type="button" className="btn btn-primary" onClick={handle_Add_fileFields}>
                        +
                    </button>
                </div>

                <div className="form-group">
                    <h6>Assign Project to Client</h6>
                    <Select
                        options={client}
                        name="client_id"
                        onChange={(e) => setFormFields({ ...formFields, client_id: e.value })}
                        placeholder="Select"
                    />
                    {clientError && <div className="error-message text-danger">{clientError}</div>}
                </div>

                <button type="submit" className="btn btn-primary mt-3" id='add_project_submit'>
                    Save Project
                </button>
            </form>
        </div>
    );
};

export default AddProject;
