import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import themeconfig from '../Config/themeconfig';
import { useParams, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'
import Select from 'react-select';
import axios from '../util/api-client';
import Swal from 'sweetalert2'
import $ from 'jquery';
import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { CopyToClipboard } from "react-copy-to-clipboard";
import heic2any from "heic2any";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Photo = () => {
    const { id } = useParams();
    let currentUrl = window.location.href;
    let active_user_id = localStorage.getItem('active-user-id');
    let client = currentUrl.split('folder-id=')[1];

    const [show_html, setshow_html] = useState(0)

    const [show2, setShow2] = useState(false);
    const [photo_errors, setphoto_Errors] = useState({});
    const [all_photos_data, setall_photos_data] = useState([])
    const [all_photos_data1, setall_photos_data1] = useState([])


    const handle_uplord_photo_Show = () => setShow2(true)

    const [share_url, setshare_url] = useState('')
    const [share_folder_name, setshare_folder_name] = useState('')


    const [share_model_show, setShare_model_show] = useState(false);
    const handle_share_Close = () => setShare_model_show(false);
    const [show_share_btn, setshow_share_btn] = useState(0)
    const [folder_share_id, setfolder_share_id] = useState(null)

    const [share_photo_model_show, setShare_photo_model_show] = useState(false);
    const handle_photo_share_Close = () => setShare_photo_model_show(false);
    const [share_photo_name, setshare_photo_name] = useState('')
    const [share_doc_url, setshare_doc_url] = useState('')
    const [show_photo_share_btn, setshow_photo_share_btn] = useState(0)
    const [photo_share_id, setphoto_share_id] = useState(null)
    const [photo_breadcrumb, setphoto_breadcrumb] = useState([])
    const [photo_subfolder, setphoto_subfolder] = useState([])
    const [isCopied, setIsCopied] = useState(false);

    const [show_photo_folder_model, set_show_photo_folder_model] = useState(false)
    const [photo_folder_errors, setphoto_folder_errors] = useState({})
    const [photo_folder_data, setphoto_folder_data] = useState([])
    const [photo_fol_option, setphoto_fol_option] = useState([])
    const [showSelect, setShowSelect] = useState(true);
    const [pending, setpending] = useState(true);
    const [sub_fol_pending, setsub_fol_pending] = useState(true);
    const [count_skeleton, setcount_skeleton] = useState(0)
    const [isLoading, setisLoading] = useState('d-none')
    const handle_uplord_photo_Close = () => {
        setShow2(false);
        setphoto_Errors({})
    }

    const hide_photo_folder_model = () => {
        set_show_photo_folder_model(false)
        setphoto_folder_errors({})
    }

    // check folder filter or not

    const get_state = () => {
        var cookies = getCookiesMap(document.cookie);
        var cookieValue = cookies["folder_type"];

        if ((currentUrl.includes(`/projects-single/photos/${id}/?folder-id=`))) {
            setshow_html(cookieValue)
        }
    }

    function getCookiesMap(cookiesString) {
        return cookiesString.split(";")
            .map(function (cookieString) {
                return cookieString.trim().split("=");
            })
            .reduce(function (acc, curr) {
                acc[curr[0]] = curr[1];
                return acc;
            }, {});
    }


    const list_all_atribute_data_photo = () => {
        let obj = {
            project_id: id,
            type: 1,
            parent_id: 0
        }
        var config = {
            method: 'get',
            url: themeconfig.APILinks.list_attribute,
            headers: {
            },
            params: obj
        };

        axios(config)
            .then(function (response) {
                if (response.data.result === true) {
                    setphoto_folder_data(response.data.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            }).finally(() => {
                setpending(false);
            });
    }


    // photo listing 

    const list_all_photo_data = () => {
        let obj = {
            project_id: id,
            type: 1
        }

        const decodedSearch = client !== undefined ? decodeURIComponent(client) : undefined;

        if (decodedSearch !== null && decodedSearch !== undefined) {
            obj.attribute_id = decodedSearch;
        }

        var config = {
            method: 'get',
            url: themeconfig.APILinks.all_list_document,
            headers: {
            },
            params: obj
        };

        axios(config)
            .then(function (response) {
                if (response.data.result === true) {
                    let photo_data = response.data.data
                    setall_photos_data(response.data.data)
                    setall_photos_data1(response.data.data)
                    get_photo_list(response.data.data)
                    setphoto_subfolder(response.data.child_data)
                    setphoto_breadcrumb(response.data.breadcrumb_data)
                    setcount_skeleton(photo_data.length)
                    get_image_url(0, '')
                }
            })
            .catch(function (error) {
                console.log(error);
            }).finally(() => {
                setsub_fol_pending(false);
            });
    }

    // folder list dropdwon 

    const dropdown_show = (e) => {
        const dropdown = $(e.target).closest('.folder').find('.dropdown');
        const dropdownMenu = $(e.target).closest('.folder').find('.dropdown-menu');

        dropdown.toggleClass('show');
        dropdownMenu.toggleClass('show');

        e.preventDefault();

        e.stopPropagation();
    };

    // delete folder name

    const delete_attribute = (id = '') => {
        Swal.fire({
            title: 'Are you sure you want delete this Folder?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    id: id
                }
                var config = {
                    method: 'post',
                    url: themeconfig.APILinks.delete_attribute,
                    headers: {

                    },
                    data: obj
                };

                axios(config)
                    .then(function (response) {
                        if (response) {
                            if (response.data.result === true) {
                                Swal.fire(
                                    'Deleted!',
                                    response.data.message,
                                    'success'
                                )
                                list_all_atribute_data_photo()
                                defult_folder_select_data_photo()
                                list_all_photo_data()
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        })
    }


    const defult_folder_select_data_photo = () => {
        const decodedclient = client !== undefined ? decodeURIComponent(client) : undefined;

        let obj = {};

        if (decodedclient !== null && decodedclient !== undefined) {
            obj.attribute_id = decodedclient;
        } else {
            obj.parent_id = 0
        }

        obj.project_id = id
        obj.type = 1

        var config = {
            method: 'get',
            url: themeconfig.APILinks.defult_folder_select,
            headers: {},
            params: obj
        };

        axios(config)
            .then(function (response) {
                if (response) {
                    if (response.data.result === true) {
                        // console.log(response.data.data)
                        setphoto_fol_option(response.data.data)
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // add photo data 

    const add_photo_data = (event) => {
        event.preventDefault()
        $('#photo_save_btn').addClass('wc-spinner');
        const formData = new FormData(event.target);
        let file = formData.get('file');
        let attribute_id = formData.get('attribute_id');

        const newErrors = {};

        if (!attribute_id) {
            newErrors.attribute_id = 'Folder is required';
        }

        if (file) {
            if (file.name === '') {
                newErrors.file = 'File is required';
            } else {
                const allowedFormats = ['png', 'heic', 'gif', 'webp', 'jpg', 'jpeg', 'dwg']
                const fileExtension = file.name.split('.').pop().toLowerCase();

                if (!allowedFormats.includes(fileExtension)) {
                    newErrors.file = 'File format must be an image (jpg, jpeg, png, gif)';
                }
            }
        }

        setphoto_Errors(newErrors)

        if (Object.keys(newErrors).length > 0) {
            $('#photo_save_btn').removeClass('wc-spinner');
            return;
        } else {
            const formData = new FormData();
            formData.append('attribute_id', attribute_id);
            formData.append('file', file);
            formData.append('user_id', active_user_id);
            formData.append('project_id', id);
            formData.append('type', 1);
            formData.append('file_type', 'single')

            var config = {
                method: 'post',
                url: themeconfig.APILinks.document_add,
                headers: {},
                data: formData
            };

            axios(config)
                .then(function (response) {
                    if (response) {
                        if (response.data.result === true) {
                            $('#photo_save_btn').removeClass('wc-spinner');
                            setShowSelect(true);
                            list_all_atribute_data_photo()
                            defult_folder_select_data_photo()
                            list_all_photo_data()
                            setShow2(false);
                            toast.success(response.data.message, {
                                position: "top-right",
                                autoClose: 200,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setTimeout(() => {
                                window.location.reload()
                            }, 3000);
                        } else {
                            $('#photo_save_btn').removeClass('wc-spinner');
                            toast.error(response.data.message, {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                })
                .catch(function (error) {
                    $('#photo_save_btn').removeClass('wc-spinner');
                    console.log(error);
                });
        }
    }

    // download photo

    const download_photo = (url = '', file_name = '') => {
        if (url) {
            window.location.href = `${themeconfig.APILinks.download_photo}?file_url=${url}`
            // window.location.href = `https://api.xtour.com.au/space/public/api/download_file_to_space_image?file_url=${url}`
        }
    };

    const folder_show = (folder_id = '', folder_type = 1) => {

        const path = '/'; // Specify the desired path for the cookie here

        // Check if the 'folder_type' cookie already exists
        const existingCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('folder_type='));

        if (existingCookie) {
            // If the cookie exists, replace its value
            document.cookie = existingCookie.replace(/folder_type=\d+/, `folder_type=${folder_type}; path=${path}`);
        } else {
            // If the cookie doesn't exist, create a new one
            document.cookie = `folder_type=${folder_type}; path=${path}`;
        }

        // Construct the URL with both folder_id and folder_type as query parameters
        let url = `/projects-single/photos/${id}/?folder-id=${folder_id}`;

        // Redirect the user to the constructed URL
        window.location.href = url;
    }

    // download zip folder

    const download_zip_folder = (attribute_id = '', project_id = '') => {
        window.location.href = themeconfig.APILinks.zip_download + `?project_id=${project_id}&attribute_id=${attribute_id}&user_id=${active_user_id}`
        // window.location.href = themeconfig.APILinks.zip_download + `?project_id=${project_id}&attribute_id=${attribute_id}`
    }

    const get_image_url_data = (data_id = 0, url = '') => {
        if (url !== '') {
            if (url.includes("heic")) {
                fetch(url)
                    .then((res) => {
                        if (!res.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return res.blob();
                    })
                    .then((blob) => {
                        return heic2any({
                            blob,
                            toType: "image/jpeg",
                            quality: 0.5,
                        });
                    })
                    .then((conversionResult) => {
                        const img_url = URL.createObjectURL(conversionResult);
                        $('.photo-gallery-box .img-fluid[data-id="' + data_id + '"]').attr("src", img_url);
                        $('.photo-gallery-box .img-fluid[data-id="' + data_id + '"]').parents('.grid').removeClass('react-loading-skeleton');
                    })
                    .catch((error) => {
                        console.error('Error fetching or converting image:', error);
                        // Handle the error, e.g., set a default image
                    });
            } else {
                const defaultImageUrl = url;
                $('.photo-gallery-box .img-fluid[data-id="' + data_id + '"]').attr("src", defaultImageUrl);
                $('.photo-gallery-box .img-fluid[data-id="' + data_id + '"]').parents('.grid').removeClass('react-loading-skeleton');
                return;
            }
        }
    };

    const get_image_url = () => {
        $('.photo-gallery-box .img-fluid').each(function () {
            let dataId = 0;
            let image_url = '';
            dataId = $(this).data('id');
            image_url = $(this).attr('src');
            get_image_url_data(dataId, image_url)
        });

        // setisLoading('show');
    }

    const convert_image_for_popup = (url = '') => {
        if (url !== '') {
            if (url.includes("heic") || url.includes("heif")) {
                fetch(url)
                    .then((res) => {
                        if (!res.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return res.blob();
                    })
                    .then((blob) => {
                        return heic2any({
                            blob,
                            toType: "image/jpeg",
                            quality: 0.5,
                        });
                    })
                    .then((conversionResult) => {
                        const img_url = URL.createObjectURL(conversionResult);
                        $("div .imageModal[id='img']").attr("src", img_url);
                    }).catch((error) => {
                        console.error('Error:', error);
                    });
            }
        }
    }

    // get photo list

    const get_photo_list = (photos_data = []) => {
        if (1 === 1) {
            if (photos_data && photos_data.length > 0) {
                return (
                    <div className="grid grid-cols-3 mx-auto">
                        {
                            photos_data.map((photo, index) => {
                                return (
                                    <div key={index}>
                                        {
                                            (isLoading === 'd-nones') ?
                                                <>
                                                    <Skeleton style={{ height: '250px' }} />
                                                </>
                                                :
                                                ""
                                        }
                                        <div className={'photo-gallery-box '} onClick={() => convert_image_for_popup(photo.file_url)}>
                                            <SlideshowLightbox className="react-loading-skeleton grid grid-popup">
                                                <img className="img-fluid" src={photo.file_url} data-id={photo.id} />
                                            </SlideshowLightbox>
                                            <div className='photo-gallery-box-icon'>
                                                <span><i className="fa-sharp fa-solid fa-share-from-square" onClick={() => share_photo(photo.project_id, photo.id, photo.key, photo.file_name, photo.status,)}></i></span>
                                                <span><i className="fa-solid fa-download" onClick={() => download_photo(photo.file_url, photo.file_name)}></i></span>
                                                <span><i className="fa-solid fa-trash" onClick={() => delete_photo(photo.id)}></i></span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            } else {
                return (
                    <h4 className="col-12 text-center">
                        No Photos found.
                    </h4>
                )
            }
        } else {
            if (all_photos_data1 && all_photos_data1.length > 0) {
                return (<SlideshowLightbox className="grid grid-cols-3 mx-auto">
                    {
                        all_photos_data1.map((photo, index) => {
                            return (
                                <img key={index} className="img-fluid" src={photo.file_url} />
                            )
                        })
                    }
                </SlideshowLightbox>)
            } else {
                return (
                    <h4 className="col-12 text-center">
                        No Photos found.
                    </h4>
                )
            }
        }
    }

    // share folder

    const share_folder = (folder_id = '', folder_key = '', status = 0, folder_name = '') => {
        setshare_folder_name(folder_name)
        setfolder_share_id(folder_id)
        show_share_button(status)
        setshow_share_btn(status)
        let url = ''
        url = `${themeconfig.site_url}/share/${id}/${folder_id}/${folder_key}`
        // `https://api.xtour.com.au/login/share/${id}/${folder_id}/${folder_key}`
        setshare_url(url)
        setShare_model_show(true);
    }

    // show folder button

    const show_share_button = () => {
        if (show_share_btn === 0) {
            return (
                <Button type="button" className="btn btn-primary w-100 d-block" onClick={() => toggle_btn('Disable')}>Disable Share Link</Button>
            )
        } else {
            return (
                <Button type="button" className="btn btn-primary w-100 d-block" onClick={() => toggle_btn('Enable')}>Enable Share Link</Button>
            )
        }

    }

    // folder disable & unable 

    const toggle_btn = (type = '') => {

        if (type === 'Enable') {
            var data = new FormData();
            data.append('id', folder_share_id);
            data.append('status', '0');
            var config = {
                method: 'post',
                url: themeconfig.APILinks.change_status_folder,
                headers: {
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    if (response) {
                        if (response.data.result === true) {
                            toast.success('Folder Enabled Successfully', {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setshow_share_btn(0)
                            show_share_button()
                            list_all_photo_data()
                            list_all_atribute_data_photo()
                        } else {
                            toast.error('Please try after some time!', {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            var data = new FormData();
            data.append('id', folder_share_id);
            data.append('status', '1');
            var config = {
                method: 'post',
                url: themeconfig.APILinks.change_status_folder,
                headers: {
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    if (response) {
                        if (response.data.result === true) {
                            toast.success('Access to this folder is disabled', {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setshow_share_btn(1)
                            show_share_button()
                            list_all_photo_data()
                            list_all_atribute_data_photo()
                        } else {
                            toast.error('Please try after some time!', {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    // share photo

    const share_photo = (project_id = '', photo_id = '', photo_key = '', photo_name = '', status = '') => {
        setphoto_share_id(photo_id)
        setshow_photo_share_btn(status)
        setshare_photo_name(photo_name)
        let url = ''
        url = `${themeconfig.site_url}/share-photo/${project_id}/${photo_id}/${photo_key}`
        // `https://xqm.xtour.com.au//share-photo/${project_id}/${photo_id}/${photo_key}`
        setshare_doc_url(url)
        setShare_photo_model_show(true)
    }

    // share button toggle 

    const show_doc_share_button = () => {
        if (show_photo_share_btn === 0) {
            return (
                <Button type="button" className="btn btn-primary w-100 d-block" onClick={() => toggle_doc_btn('Disable')}>Disable Share Link</Button>
            )
        } else {
            return (
                <Button type="button" className="btn btn-primary w-100 d-block" onClick={() => toggle_doc_btn('Enable')}>Enable Share Link</Button>
            )
        }
    }

    // share url disable & unable 

    const toggle_doc_btn = (type = '') => {
        if (type === 'Enable') {
            var data = new FormData();
            data.append('id', photo_share_id);
            data.append('status', '0');
            var config = {
                method: 'post',
                url: themeconfig.APILinks.change_status_doc,
                headers: {
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    if (response) {
                        if (response.data.result === true) {
                            list_all_photo_data()
                            setshow_photo_share_btn(0)
                            show_doc_share_button()
                            toast.success('File Enabled Successfully', {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        } else {
                            toast.error('Please try after some time!', {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            var data = new FormData();
            data.append('id', photo_share_id);
            data.append('status', '1');
            var config = {
                method: 'post',
                url: themeconfig.APILinks.change_status_doc,
                headers: {
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    if (response) {
                        if (response.data.result === true) {
                            list_all_photo_data()
                            setshow_photo_share_btn(1)
                            show_doc_share_button()
                            toast.success('Access to this file is disabled', {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        } else {
                            toast.error('Please try after some time!', {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    // copy url share

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    // photo create folder save 

    const save_photo_folder = (event) => {
        event.preventDefault();
        $('#folder_photo_save_btn').addClass('wc-spinner');
        const formData = new FormData(event.target);
        let name = formData.get('name');

        const newErrors = {};

        if (!name) {
            newErrors.name = 'Folder is required';
        }
        setphoto_folder_errors(newErrors)

        if (Object.keys(newErrors).length > 0) {
            $('#folder_photo_save_btn').removeClass('wc-spinner');
            return;
        } else {
            let obj = {
                name: name,
                project_id: id,
                user_id: active_user_id,
                type: 1,
            }
            const decodedclient = client !== undefined ? decodeURIComponent(client) : undefined;

            if (decodedclient !== null && decodedclient !== undefined) {
                obj.parent_id = decodedclient;
            } else {
                obj.parent_id = 0
            }

            var config = {
                method: 'post',
                url: themeconfig.APILinks.add_attribute,
                headers: {
                },
                data: obj
            };

            axios(config)
                .then(function (response) {
                    if (response) {
                        if (response.data.result === true) {
                            $('#folder_photo_save_btn').removeClass('wc-spinner');
                            list_all_atribute_data_photo()
                            defult_folder_select_data_photo()
                            list_all_photo_data()
                            set_show_photo_folder_model(false);
                            toast.success(response.data.message, {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        } else {
                            $('#folder_photo_save_btn').removeClass('wc-spinner');
                            toast.error(response.data.message, {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                })
                .catch(function (error) {
                    $('#folder_photo_save_btn').removeClass('wc-spinner');
                    console.log(error);
                });
        }
    }

    // create photo folder

    const create_photo_folder = () => {
        setShowSelect(false);
        $('#photo_create_fol_btn').addClass('d-none')
    }

    const get_folder_option = (url = '') => {
        if ((url.includes(`/projects-single/photos/${id}/?folder-id=`))) {
            return (<Select
                className="basic-single"
                classNamePrefix="select"
                options={photo_fol_option}
                defaultValue={photo_fol_option}
                name='attribute_id'
                id='photo_fol_select'
            />)
        } else {
            return showSelect ? (
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={photo_fol_option}
                    name='attribute_id'
                    id='photo_fol_select'
                />
            ) : (
                <div className="mt-2">
                    <input
                        type="text"
                        className="form-control"
                        name='attribute_id'
                        placeholder="Create Folder"
                    />
                </div>
            )
        }
    }

    const get_create_btn_photo = (url = '') => {
        if ((url.includes(`/projects-single/photos/${id}/?folder-id=`))) {
            return null
        } else {
            return (
                <Button type="button" id='photo_create_fol_btn' className="btn btn-primary mx-2" onClick={() => create_photo_folder()}>Create Folder</Button>
            )
        }
    }

    // delete photo

    const delete_photo = (id = 1) => {
        Swal.fire({
            title: 'Are you sure you want delete this Photo?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    id: id
                }
                var config = {
                    method: 'post',
                    url: themeconfig.APILinks.delete_document,
                    headers: {

                    },
                    data: obj
                };
                axios(config)
                    .then(function (response) {
                        if (response) {
                            if (response.data.result === true) {
                                Swal.fire(
                                    'Deleted!',
                                    response.data.message,
                                    'success'
                                )
                                setTimeout(() => {
                                    window.location.reload()
                                }, 500);
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        })
    }

    useEffect(() => {

        $(document).on("click", '.container-fluid', function () {
            $('.dropdown-menu.show#drop_down').removeClass('show');
            $('.dropdown.show#drop_dwon_btn').removeClass('show');
            $('.dropdown.show#drop_dwon_btn1').removeClass('show');
            $('.dropdown-menu.show#drop_down1').removeClass('show');
        });
        get_state()
        list_all_atribute_data_photo()
        list_all_photo_data()
        defult_folder_select_data_photo()

    }, [])


    return (
        <>
            <div className="container-fluid">
                <ToastContainer />
                <div>
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <Modal show={share_model_show} onHide={handle_share_Close}>
                            <Modal.Header closeButton>
                                <Modal.Title>{share_folder_name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form className='share-folder'>
                                    <div className="form-group">
                                        <label htmlFor="Name">Share Link Below</label>
                                        {
                                            isCopied ?
                                                <span>
                                                    Copied!
                                                </span>
                                                :
                                                ''
                                        }
                                        <input type="name" className="form-control" defaultValue={share_url} name='Link' />
                                        <CopyToClipboard text={share_url} onCopy={onCopyText}>
                                            <div className="copy-area" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-copy"></i>
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                    {show_share_button(show_share_btn)}
                                </form>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>

                <div className="d-sm-flex align-items-center justify-content-between mb-4">

                    <Modal show={share_photo_model_show} onHide={handle_photo_share_Close}>
                        <Modal.Header closeButton>
                            <Modal.Title>{share_photo_name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className='share-folder'>
                                <div className="form-group">
                                    <label htmlFor="Name">Share Link Below</label>
                                    {
                                        isCopied ?
                                            <span>
                                                Copied!
                                            </span>
                                            :
                                            ''
                                    }
                                    <input type="name" className="form-control" defaultValue={share_doc_url} name='Link' />
                                    <CopyToClipboard text={share_doc_url} onCopy={onCopyText}>
                                        <div className="copy-area" style={{ cursor: 'pointer' }}>
                                            <i className="fa-solid fa-copy"></i>
                                        </div>
                                    </CopyToClipboard>
                                </div>
                                {show_doc_share_button(show_photo_share_btn)}
                            </form>
                        </Modal.Body>
                    </Modal>

                </div>

                <div>
                    {
                        show_html == 0 || show_html == 1 ?
                            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                <div className='d-flex gap-2' style={{ fontSize: '25px' }}>
                                    <a href={`/projects-single/photos/${id}`}>Photos</a>

                                    {photo_breadcrumb.map((breadcrumb, index) => {
                                        if (photo_breadcrumb.length - index > 2) {
                                            return null
                                        } else {
                                            return (
                                                <div key={index} >
                                                    <i className="fa-duotone fa-greater-than mr-2"></i>
                                                    {
                                                        breadcrumb.status === 1 ? (
                                                            <span>{breadcrumb.name}</span>
                                                        ) : (
                                                            <a href={`/projects-single/photos/${id}/?folder-id=${breadcrumb.id}`}>
                                                                {breadcrumb.name}
                                                            </a>
                                                        )
                                                    }
                                                </div>)
                                        }
                                    })}

                                </div>

                                <div className="d-flex gap-2">
                                    <Button variant="primary" onClick={handle_uplord_photo_Show}>
                                        <i className="fa fa-upload mr-2"></i>
                                        Upload
                                    </Button>
                                    <Button variant="primary" onClick={() => set_show_photo_folder_model(true)}>
                                        <i className="fa fa-plus mr-2"></i>
                                        Create Folder
                                    </Button>
                                </div>

                                <Modal show={show_photo_folder_model} onHide={hide_photo_folder_model}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Create Folder</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <form onSubmit={save_photo_folder}>
                                            <div className="form-group">
                                                <label htmlFor="Name">Photo Folder Name</label>
                                                <input type="name" className="form-control" name='name' />
                                                {photo_folder_errors.name && <div className="text-danger">{photo_folder_errors.name}</div>}
                                            </div>
                                            <Button type="submit" className="btn btn-primary mx-2" id='folder_photo_save_btn'>Create Folder</Button>
                                            <Button variant="secondary" onClick={hide_photo_folder_model}>
                                                Close
                                            </Button>
                                        </form>
                                    </Modal.Body>
                                </Modal>

                                <Modal show={show2} onHide={handle_uplord_photo_Close}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Upload Photo</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <form onSubmit={add_photo_data}>
                                            <div className="form-group">
                                                <label htmlFor="Folder">Folder :</label>


                                                {get_folder_option(currentUrl)}

                                                {/* <div className='mt-2'>
                                                    <input type="text" className="form-control d-none" name='attribute_id' id='photo_create_fol' placeholder='Create Folder' />
                                                </div> */}

                                                {get_create_btn_photo(currentUrl)}

                                                {photo_errors.file && <div className="text-danger">{photo_errors.attribute_id}</div>}
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="Name">File</label>
                                                <input type="file" className="form-control" name='file' />
                                                {photo_errors.file && <div className="text-danger">{photo_errors.file}</div>}
                                            </div>
                                            <Button type="submit" className="btn btn-primary mx-2" id='photo_save_btn'>Upload Photo</Button>
                                            <Button variant="secondary" onClick={handle_uplord_photo_Close}>
                                                Close
                                            </Button>
                                        </form>
                                    </Modal.Body>
                                </Modal>
                            </div>
                            :
                            ''
                    }

                    {
                        show_html == 1 ?
                            <>
                                <div className="row x-photos">
                                    <div className="photo-gallery">
                                        {get_photo_list(all_photos_data)}
                                    </div>
                                </div>

                                <hr />

                                <div className="row x-docs">
                                    <div className='d-flex gap-2' style={{ fontSize: '25px' }}><span>Folders</span></div>
                                    {
                                        sub_fol_pending ?
                                            <div className='wc-custom-loading' style={{ minHeight: '100px' }}>
                                                <div className="spinner-border mx-auto text-secondary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            :
                                            photo_subfolder.length === 0 ? (
                                                <h4 className="col-12 text-center">
                                                    No Folders found.
                                                </h4>
                                            ) : (
                                                photo_subfolder.map((data, index) => {
                                                    return (
                                                        <div className="col-xl-3 col-md-6 mb-4" key={index} style={{ cursor: 'pointer' }} onClick={() => folder_show(data.id, data.type)}>
                                                            <div className="card shadow h-100 py-2">
                                                                <div className="card-body">
                                                                    <div className="row no-gutters">
                                                                        <div className="col mr-2">
                                                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{data.name}</div>
                                                                        </div>
                                                                        <div className="col-auto folder">
                                                                            <div className="dropdown no-arrow " id='drop_dwon_btn' onClick={(e) => dropdown_show(e)}>
                                                                                <span className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    <i className="fas fa-ellipsis-h fa-sm fa-fw text-gray-400"></i>
                                                                                </span>
                                                                                <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in " id='drop_down' aria-labelledby="dropdownMenuLink">
                                                                                    <div className="dropdown-header">Folder actions:</div>
                                                                                    <div className="dropdown-item" onClick={() => download_zip_folder(data.id, data.project_id)} >Download</div>
                                                                                    <div className="dropdown-item" onClick={() => share_folder(data.id, data.key, data.status, data.name)}>Share</div>
                                                                                    <div className="dropdown-item" onClick={() => delete_attribute(data.id)}>Delete</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )
                                    }
                                </div>
                            </>
                            :
                            ''
                    }

                    {
                        show_html == 0 ?
                            <div className="row x-docs">
                                {
                                    pending ?
                                        <div className='wc-custom-loading' style={{ minHeight: '200px' }}>
                                            <div className="spinner-border mx-auto text-secondary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        :
                                        photo_folder_data.length === 0 ? (
                                            <h4 className="col-12 text-center">
                                                No Photos found
                                            </h4>
                                        ) : (
                                            photo_folder_data.map((data, index) => {
                                                return (
                                                    <div className="col-xl-3 col-md-6 mb-4" key={index} style={{ cursor: 'pointer' }} onClick={() => folder_show(data.id, data.type)}>

                                                        <div className="card shadow h-100 py-2">
                                                            <div className="card-body">
                                                                <div className="row no-gutters">
                                                                    <div className="col mr-2">
                                                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{data.name}</div>
                                                                    </div>
                                                                    <div className="col-auto folder">
                                                                        <div className="dropdown no-arrow " id='drop_dwon_btn' onClick={(e) => dropdown_show(e)}>
                                                                            <span className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <i className="fas fa-ellipsis-h fa-sm fa-fw text-gray-400"></i>
                                                                            </span>
                                                                            <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in " id='drop_down' aria-labelledby="dropdownMenuLink">
                                                                                <div className="dropdown-header">Folder actions:</div>
                                                                                <div className="dropdown-item" onClick={() => download_zip_folder(data.id, data.project_id)} >Download</div>
                                                                                <div className="dropdown-item" onClick={() => share_folder(data.id, data.key, data.status, data.name)}>Share</div>
                                                                                <div className="dropdown-item" onClick={() => delete_attribute(data.id)}>Delete</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        )
                                }
                            </div>
                            :
                            ''
                    }

                    <br />
                    {/* <Link className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" to={`/point-cloud/${id}`}>Request Point Cloud Data Set</Link> */}
                    <hr />
                </div>
            </div>
        </ >
    )
}

export default Photo