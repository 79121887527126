import React from 'react'
import { Link } from "react-router-dom";

const FAQ = () => {
    return (
        <div>
            <div id="content">
                <div className="scan-toolbar">
                    <ul>
                        <li><Link to='/help-support'>Request Support</Link></li>
                        <li><Link to="/help-support/support-faqs">FAQs</Link></li>
                    </ul>
                </div>
            </div>
            <iframe src='https://www.xtour.com.au/faqs' style={{ height: '800px', width: '100%' }}></iframe>
        </div>
    )
}

export default FAQ