import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import axios from '../util/api-client';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from "react-router-dom";
import themeconfig from '../Config/themeconfig';
const ProjectBooking = () => {
  const navigate = useNavigate();
  let active_user_id = localStorage.getItem('active-user-id');
  const [active_user, setactive_user] = useState({})
  const [errors, setErrors] = useState({});
  const options = [
    { value: 'Pre Construction', label: 'Pre Construction' },
    { value: 'Dilapidation', label: 'Dilapidation' },
    { value: 'Pre Slab', label: 'Pre Slab' },
    { value: 'Pre Plaster', label: 'Pre Plaster' },
    { value: 'Defect / Final', label: 'Defect / Final' }
  ]

  const get_active_user = () => {
    var config = {
      method: 'get',
      url: themeconfig.APILinks.get_all_user + active_user_id,
      headers: {
      }
    };

    axios(config).then(function (response) {
      if (response) {
        if (response.data.result === true) {
          response.data.data.map((user) => {
            const user_data = user
            setactive_user(user_data)
          })
        }
      }
    }).catch(function (error) {
      console.log(error);
    });
  }

  const req_project_booking = (event) => {
    event.preventDefault();
    $('#project_booking_btn').addClass('wc-spinner');
    const formData = new FormData(event.target);
    let name = formData.get('name');
    let email = formData.get('email');
    let phone = formData.get('phone');
    let address = formData.get('address');
    let select_type = formData.get('select_type');
    let message = formData.get('message');

    // Perform validation for each field
    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = 'Full Name is required';
    }

    if (!email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = 'Invalid Email';
    }

    if (!phone.trim()) {
      newErrors.phone = 'Phone Number is required';
    }

    if (!address.trim()) {
      newErrors.address = 'Address is required';
    }

    if (!select_type.trim()) {
      newErrors.select_type = 'Scan type is required';
    }

    if (!message.trim()) {
      newErrors.message = 'Message is required';
    }

    // Update errors state
    setErrors(newErrors);

    // If there are any errors, return
    if (Object.keys(newErrors).length > 0) {
      $('#project_booking_btn').removeClass('wc-spinner');
      return;
    } else {
      let p_booking_data = {
        name: name,
        email: email,
        phone: phone,
        address: address,
        select_type: select_type,
        message: message,
        type: 1,
        user_id: active_user_id
      }

      var config = {
        method: 'post',
        url: themeconfig.APILinks.help_p_bookling_add,
        headers: {

        },
        data: p_booking_data
      };

      axios(config)
        .then(function (response) {
          if (response) {
            if (response.data.result === true) {
              $('#project_booking_btn').removeClass('wc-spinner');
              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setTimeout(() => {
                navigate('/dashboard');
              }, 1500);
            } else {
              $('#project_booking_btn').removeClass('wc-spinner');
              toast.error(response.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch(function (error) {
          $('#project_booking_btn').removeClass('wc-spinner');
          console.log(error);
        });

    }
  }

  useEffect(() => {
    get_active_user()
    $(document).on('keypress', '#Number', function (event) {
      var regex = new RegExp("^[0-9_ ]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    });

    $("#Number").keyup(function () {
      var mobile_ele = $("#Number");
      var mobileNum = mobile_ele.val();
      console.log(mobileNum)
      var formattedNum = mobileNum.replace(/(\d{2})(\d{3})(\d{3})/g, "04$1 $2 $3");
      mobile_ele.val(formattedNum);
    })
  }, [])


  return (
    <div>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row project-scan">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Project Bookings</h1>
          </div>
          <div className="col-xl-12 col-lg-12">
            <div className="col-xl-6 col-lg-6">
              <div>
                <form onSubmit={req_project_booking}>
                  <div className="form-group">
                    <label htmlFor="Name">Name</label>
                    <input type="name" className="form-control" name='name' placeholder="Enter Name" defaultValue={active_user.name}/>
                    {errors.name && <div className="text-danger error-message">{errors.name}</div>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input type="email" className="form-control" name="email" aria-describedby="emailHelp" placeholder="Enter email" defaultValue={active_user.email}/>
                    {errors.email && <div className="text-danger error-message">{errors.email}</div>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="Phone">Phone</label>
                    <input type="phone" className="form-control" name="phone" placeholder="Enter Phone" id='Number' defaultValue={active_user.phone_number}/>
                    {errors.phone && <div className="text-danger error-message">{errors.phone}</div>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="Name">Address</label>
                    <input type="name" className="form-control" name="address" placeholder="Enter Address" defaultValue={active_user.address}/>
                    {errors.address && <div className="text-danger error-message">{errors.address}</div>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="Select">Select scan type</label>
                    <Select
                      options={options}
                      name="select_type"
                      placeholder="Select"
                    />
                    {errors.select_type && <div className="text-danger error-message">{errors.select_type}</div>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="Feedback">Message</label>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="6" name='message' placeholder='Enter Message'></textarea>
                    {errors.message && <div className="text-danger error-message">{errors.message}</div>}
                  </div>
                  <button type="submit" className="btn btn-primary" id='project_booking_btn'>Request Booking</button>
                </form>
              </div>
            </div>
          </div>

        </div>
        <hr />
      </div>
    </div>
  )
}

export default ProjectBooking