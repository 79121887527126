import React, { useEffect, useState } from 'react';
import { Link, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import axios from '../../util/api-client';
import Select from 'react-select';
import $ from 'jquery';
import themeconfig from '../../Config/themeconfig';
import { useAuth } from "reactfire";


const Home = () => {
    const auth = useAuth();
    let token = localStorage.getItem('user-token')
    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    const client = urlParams.get('client');
    let url = currentUrl.split('/')[3]
    const [searchInput, setSearchInput] = useState("");
    const sidebar_data = themeconfig.sidebar_data.sidebar;
    let active_user_id = localStorage.getItem("user_role");
    let user_id = localStorage.getItem('active-user-id');
    let user_role = localStorage.getItem('user_role');
    const [active_user_data, setactive_user_data] = useState({})
    const [client_search, setclient_search] = useState('')
    const [all_client_data, setall_client_data] = useState([])
    const [client_data_defualt, setclient_data_defualt] = useState([])
    const [parent_data, setparent_data] = useState({})
    const get_client_data = () => {
        let obj = {
            user_role: 1
        }
        var config = {
            method: 'post',
            url: themeconfig.APILinks.get_client_data,
            headers: {
            },
            params: obj
        };

        axios(config)
            .then(function (response) {
                if (response) {
                    if (response.data.result === true) {
                        let all_client_filter = [...response.data.data];
                        let newnewElement = { value: '', label: 'All' };
                        all_client_filter.unshift(newnewElement);
                        setall_client_data(all_client_filter)
                        get_client_d_value(all_client_filter)
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const get_active_user = () => {
        var config = {
            method: 'get',
            url: themeconfig.APILinks.get_all_user + user_id,
            headers: {
            }
        };

        axios(config).then(function (response) {
            if (response) {
                if (response.data.result === true) {
                    response.data.data.map((user) => {
                        const user_data = user
                        setactive_user_data(user_data)
                        show_client_filter(user_data.user_role)
                        get_client(user_data.parent_id)
                    })
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    const get_client = (id = 0) => {
        var config = {
            method: 'get',
            url: themeconfig.APILinks.get_all_user + id,
            headers: {
            },
        };

        axios(config).then(function (response) {
            if (response) {
                if (response.data.result === true) {
                    response.data.data.map((user) => {
                        const user_data = user
                        setparent_data(user_data)
                    })
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const show_drop_dwon = () => {
        $('#drop_dwon_1').toggleClass(' show');
        $('#drop_dwon_2').toggleClass(' show');
    }

    const remove_class = () => {
        $('#drop_dwon_1').removeClass('show');
        $('#drop_dwon_2').removeClass('show');
    }

    const logout = () => {
        localStorage.removeItem('user_role');
        auth.signOut().then(() => {
            navigate('/login')
        }).catch((error) => {
            console.log(error)
        });
    }

    const is_Empty_search = () => {
        setSearchInput('');
    }

    const handleClientChange = (selectedOption) => {
        let d_value = [];
        d_value.push(selectedOption)
        setclient_search(selectedOption.value)
        setclient_data_defualt(d_value)
        setTimeout(() => {
            $("#search_btn").click();
        }, 500);
    };

    const projects_search_fun = (e) => {

        e.preventDefault();


        const queryParams = [];
        if (searchInput) {
            queryParams.push(`s=${searchInput}`);
        }
        if (client_data_defualt) {
            if (client_data_defualt.length > 0) {
                const client_search = client_data_defualt[0].value
                if (client_search) {
                    queryParams.push(`client=${client_search}`);
                }
            }
        }

        const queryString = queryParams.join('&');
        let url = ''
        if (active_user_data.user_role == 0) {
            url = '/projects-admin';
        } else {
            url = '/projects';
        }

        if (queryString) {
            url += `?${queryString}`;
        }
        // window.history.pushState(null, null, url);
        // console.log(url)
        window.location.href = url
    };



    const get_profile = (role = '') => {
        if (role == 0) {
            return (
                <Link to='/profile'>
                    <span className="dropdown-item">
                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                        Profile
                    </span>
                </Link>
            )
        } else if (role == 1) {
            return (
                <Link to='/client-profile'>
                    <span className="dropdown-item">
                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                        Profile
                    </span>
                </Link>
            )
        } else {
            return (
                <Link to='/profile'>
                    <span className="dropdown-item">
                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                        Profile
                    </span>
                </Link>
            )
        }
    }

    const show_client_filter = (role = 1) => {
        if (role === 0) {
            return (
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="client_id"
                    options={all_client_data}
                    // defaultValue={client_data_defualt}
                    value={client_data_defualt}
                    placeholder='Select Client'
                    onChange={(e) => handleClientChange(e)}
                />
            )
        } else {
            return null
        }
    }

    const get_client_d_value = (data = '') => {
        if (client) {
            data.forEach((element) => {
                if (element.value == client) {
                    let select_data = [];
                    select_data.push(element)
                    setclient_data_defualt(select_data)
                }
            });
        }
    }

    const get_profile_picture = (role = 1, logo = '') => {
        // console.log(role)
        if (role === 0) {
            return (
                <img className="img-profile rounded-circle" src={themeconfig.image.xtour_image} alt='profile' />
            )
        } else if (role === 1) {
            if (logo) {
                return (
                    <img className="img-profile rounded-circle" src={logo} alt='logo' />
                )
            } else {
                return (
                    <img className="img-profile rounded-circle" src={themeconfig.image.xtour_image} alt='logo' />
                )
            }
        } else if (role === 2) {
            if (parent_data) {
                if (parent_data.logo === '') {
                    return (
                        <img className="img-profile rounded-circle" src={themeconfig.image.xtour_image} alt='profile-img' />
                    )
                } else {
                    return (
                        <img className="img-profile rounded-circle" src={parent_data.logo} alt='profile-img' />
                    )
                }
            }
        }
    }

    const toggle_menu = () => {
        $("#wrapper .navbar-nav").toggleClass("active");
    }

    const click_profile = () => {
        get_active_user()
        get_client()
    }

    useEffect(() => {
        if (!document.cookie.includes('login=true')) {
            localStorage.removeItem('user-token');
            localStorage.removeItem('auth-token');
            localStorage.removeItem('active-user-id');
            localStorage.removeItem('user_role');
            localStorage.removeItem('parent_id');
        }
        get_client_data()
        const urlParams = new URLSearchParams(window.location.search);
        const s = urlParams.get('s');

        if (s) {
            setSearchInput(s);
        }

        get_active_user()

        const handleScrollResize = () => {
            if ($(window).width() <= 768 && $(window).scrollTop() > 0) {
                $('#accordionSidebar').addClass('active');
            } else {
                $('#accordionSidebar').removeClass('active');
            }
        };

        // Add the event listener when the component mounts
        $(window).on('resize scroll', handleScrollResize);

        // Initial check on component mount
        handleScrollResize();

        // Remove the event listener when the component unmounts
        return () => {
            $(window).off('resize scroll', handleScrollResize);
        };

    }, [])

    return (
        <React.Fragment>
            <>
                <div id="wrapper">
                    <ul className={active_user_id == 0 ? 'navbar-nav sidebar sidebar-dark accordion admin-sidebar' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'} id="accordionSidebar">
                        <a className="sidebar-brand d-flex align-items-center justify-content-center">
                            <div className="x-logo">
                                <img src={themeconfig.image.xtour_image} alt="" />
                            </div>
                        </a>

                        <hr className="sidebar-divider my-0" />
                        {
                            sidebar_data.map((data) => {
                                if (data) {
                                    if (data.active_role == active_user_id) {
                                        return data.menu_array.map((menu, index) => {
                                            if (menu.type === 0) {
                                                let currunturl = window.location.href;
                                                let active = [];
                                                let path = '';
                                                active = currunturl.split('/');
                                                if (active.length > 0) {
                                                    path = active[3];
                                                }
                                                return (
                                                    <li className={path === menu.slug ? "nav-item active" : 'nav-item'} key={index}>
                                                        <Link to={menu.slug} className='nav-link' onClick={() => is_Empty_search()}>
                                                            <span className="nav-text">{menu.label}</span>
                                                        </Link>
                                                    </li>
                                                )
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    </ul>

                    <div id="content-wrapper" className="d-flex flex-column">

                        <div id="content">

                            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={() => toggle_menu()}>
                                    <i className="fa fa-bars"></i>
                                </button>


                                <ul className="navbar-nav ml-auto w-100 justify-content-end">

                                    <div className="x-search">
                                        <form className="d-none d-sm-inline-flex gap-2 mr-auto ml-md-3 my-2 my-md-0 navbar-search" onSubmit={projects_search_fun}>
                                            <div className='w-100'>
                                                {show_client_filter(active_user_data.user_role)}
                                            </div>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control bg-light border-0 small"
                                                    placeholder="Search Projects"
                                                    aria-label="Search"
                                                    aria-describedby="basic-addon2"
                                                    value={searchInput}
                                                    onChange={(e) => setSearchInput(e.target.value)}
                                                />
                                                <div className="input-group-append">
                                                    {/* <a href={`/projects-admin/?s=${searchInput}`}> */}
                                                    <button className="btn btn-primary" type="submit" id='search_btn'>
                                                        <i className="fas fa-search fa-sm"></i>
                                                    </button>
                                                    {/* </a> */}
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="topbar-divider d-none d-sm-block"></div>
                                    <span className="wc-profile-click d-none" onClick={() => click_profile()}></span>
                                    <li className="nav-item dropdown no-arrow" id='drop_dwon_1'>
                                        <span className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => show_drop_dwon()}>
                                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{active_user_data.name}</span>
                                            {get_profile_picture(active_user_data.user_role, active_user_data.logo)}
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                            aria-labelledby="userDropdown" id='drop_dwon_2'>
                                            {get_profile(active_user_data.user_role)}
                                            <div className="dropdown-divider"></div>
                                            <Link to='/'>
                                                <span className="dropdown-item" data-toggle="modal"
                                                    data-target="#logoutModal" onClick={() => logout()}>
                                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                                    Logout
                                                </span>
                                            </Link>
                                        </div>
                                    </li>

                                </ul>

                            </nav>

                            <div className="" onClick={() => remove_class()}>

                                <Routes>
                                    <Route path='/' element={<Outlet />} >
                                        {
                                            sidebar_data.map((data, index) => {
                                                if (data) {
                                                    if (data.active_role == active_user_id) {
                                                        return data.menu_array.map((menu, index) => {
                                                            let Component = require(`../../Components/${menu.componentName}`).default;
                                                            return <Route key={index} path={menu.slug} element={<Component />} />;
                                                        })
                                                    } else {
                                                        return <Route key={index} path='*' element={<h3>You don't have access.</h3>} />
                                                    }
                                                }
                                            })
                                        }
                                    </Route>
                                </Routes>
                            </div>

                        </div>

                        <footer className="sticky-footer bg-white">
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                    <span>Copyright &copy; XTOUR 3D 2024</span>
                                </div>
                            </div>
                        </footer>
                    </div>

                </div>
            </>
        </React.Fragment>
    )
}
export default Home;