import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from '../util/api-client';
import themeconfig from '../Config/themeconfig';
import DataTable from 'react-data-table-component';
const Clients = () => {
  // let active_user_id = localStorage.getItem('active-user-id');
  const [all_clients_data, setall_clients_data] = useState([])
  const [pending, setpending] = useState(true);
  const get_all_clients = () => {
    let obj = {
      user_role: 1
    }
    var config = {
      method: 'GET',
      url: themeconfig.APILinks.get_all_clients,
      headers: {
      },
      params: obj
    };

    axios(config).then(function (response) {
      if (response) {
        if (response.data.result === true) {
          setall_clients_data(response.data.data)
        }
      }
    }).catch(function (error) {
      console.log(error);
    }).finally(() => {
      setpending(false);
    });
  }

  const columns = [
    {
      name: '#',
      selector: (row) => row.id,
      sortable: true,
      width: '100px',
      cell: (row) => {
        return (
          <div
          >{row.id}</div>
        )
      }
    },
    {
      name: 'Business Name',
      selector: row => row.business_name,
      sortable: true,
      width: '300px',
      cell: (row) => {
        return (
          <div>{row.business_name}</div>
        )
      }
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      width: '250px',
      cell: (row) => {
        return (
          <div>{row.email}</div>
        )
      }
    },
    {
      name: 'Projects',
      selector: row => row.project_count,
      sortable: true,
      width: '100px',
      cell: (row) => {
        return (
          <div>{row.project_count}</div>
        )
      }
    },
    {
      name: 'Users',
      selector: row => row.user_count,
      sortable: true,
      width: '100px',
      cell: (row) => {
        return (
          <Link to={`/clients/${row.id}`}>
            <div>{row.user_count}</div>
          </Link>
        )
      }
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      width: '100px',
      cell: (row) => {
        if (row.status === 1) {
          return (<i className="fa-sharp fa-solid fa-circle-xmark text-danger" style={{ fontSize: '20px' }}></i>)
        } else {
          return (<i className="fa-solid fa-circle-check text-success" style={{ fontSize: '20px' }}></i>)
        }
      }
    },
    {
      name: 'Edit',
      selector: row => row.id,
      width: '250px',
      cell: (row) => {
        return (
          <div>
            <Link to={`/clients/edit-clients/?id=${row.id}`}>
              <span><i className="fas fa-fw fa-edit"></i></span>
            </Link>
          </div>
        )
      }
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Per Page',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };


  useEffect(() => {
    get_all_clients()
  }, [])


  return (
    <div>
      <div className="container-fluid">

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">All Clients</h1>
          <Link to="/clients/add-clients" >
            <span className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-sm text-white-50"></i> Add Client</span>
          </Link>

        </div>

        <DataTable
          columns={columns}
          data={all_clients_data}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          progressPending={pending}
          progressComponent={
            <h6>Loading...</h6>
          }
        />
      </div>
    </div>
  )
}

export default Clients