import React, { useEffect, useState } from 'react'
import axios from '../util/api-client';
import { ToastContainer, toast } from 'react-toastify'
import themeconfig from '../Config/themeconfig';
import { useNavigate, useParams } from "react-router-dom";
import $ from 'jquery';
import ReCAPTCHA from "react-google-recaptcha";
const Feedback = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  let active_user_id = localStorage.getItem('active-user-id');
  const [active_user, setactive_user] = useState({})
  const [errors, setErrors] = useState({});
  const [isVerified, setIsVerified] = useState(false);

  // get active user

  const get_active_user = () => {
    var config = {
      method: 'get',
      url: themeconfig.APILinks.get_all_user + active_user_id,
      headers: {
      }
    };

    axios(config).then(function (response) {
      if (response) {
        if (response.data.result === true) {
          response.data.data.map((user) => {
            const user_data = user
            setactive_user(user_data)
          })
        }
      }
    }).catch(function (error) {
      console.log(error);
    });
  }

  // add project feedback 

  const add_project_feedback = (event) => {
    event.preventDefault();
    $('#project_feedback_btn').addClass('wc-spinner');
    const formData = new FormData(event.target);

    let name = formData.get('name');
    let email = formData.get('email');
    let phone = formData.get('phone_number');
    let feedback = formData.get('feedback');



    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = 'Invalid Email';
    }

    if (!phone.trim()) {
      newErrors.phone = 'Phone Number is required';
    }

    if (!feedback.trim()) {
      newErrors.feedback = 'feedback is required';
    }

    if (!isVerified) {
      // reCAPTCHA not verified, show an error
      toast.error('Please complete the reCAPTCHA verification.');
      $('#project_feedback_btn').removeClass('wc-spinner');
      return;
    }

    setErrors(newErrors)

    if (Object.keys(newErrors).length > 0) {
      $('#project_feedback_btn').removeClass('wc-spinner');
      return;
    } else {
      let feedback_obj = {
        name: name,
        email: email,
        feedback: feedback,
        phone_number: phone,
        project_id: id,
        user_id: active_user_id,
        type: 0
      }
      var config = {
        method: 'post',
        url: themeconfig.APILinks.add_feedback,
        headers: {

        },
        data: feedback_obj
      };

      axios(config)
        .then(function (response) {
          if (response) {
            if (response.data.result === true) {
              $('#project_feedback_btn').removeClass('wc-spinner');
              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setTimeout(() => {
                navigate('/projects');
              }, 1500);
            } else {
              $('#project_feedback_btn').removeClass('wc-spinner');
              toast.error(response.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch(function (error) {
          $('#project_feedback_btn').removeClass('wc-spinner');
          console.log(error);
        });

    }

  }

  // google recaptcha 

  const get_Google_reCAPTCHA = (value) => {
    if(value){
      setIsVerified(true);
    }
  }

  useEffect(() => {

    get_active_user()

    $(document).on('keypress', '#Number', function (event) {
      var regex = new RegExp("^[0-9_ ]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    });

    $("#Number").keyup(function () {
      var mobile_ele = $("#Number");
      var mobileNum = mobile_ele.val();
      console.log(mobileNum)
      var formattedNum = mobileNum.replace(/(\d{2})(\d{3})(\d{3})/g, "04$1 $2 $3");
      mobile_ele.val(formattedNum);
    })

  }, [])


  return (
    <div className='container-fluid'>
      <ToastContainer />
      <div className="row project-scan">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Project Feedback</h1>
        </div>

        <div className="col-xl-12 col-lg-12">
          <div className="col-xl-6 col-lg-6">
            <div>
              <form onSubmit={add_project_feedback}>
                <div className="form-group">
                  <label htmlFor="Name">Name</label>
                  <input type="name" className="form-control" placeholder="Name" name='name' defaultValue={active_user.name} />
                  {errors.name && <div className="text-danger error-message">{errors.name}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <input type="email" className="form-control" aria-describedby="emailHelp" name='email' placeholder="Enter email" defaultValue={active_user.email} />
                  {errors.email && <div className="text-danger error-message">{errors.email}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="Phone">Phone</label>
                  <input type="phone" className="form-control" name='phone_number' id='Number' placeholder="Phone" defaultValue={active_user.phone_number} />
                  {errors.phone && <div className="text-danger error-message">{errors.phone}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="Feedback">Feedback</label>
                  <textarea className="form-control" name='feedback' rows="6"></textarea>
                  {errors.feedback && <div className="text-danger error-message">{errors.feedback}</div>}
                </div>
                <ReCAPTCHA
                  sitekey={themeconfig.Google_reCAPTCHA}
                  onChange={get_Google_reCAPTCHA}
                />
                <br />
                <button type="submit" className="btn btn-primary" id='project_feedback_btn' disabled={!isVerified} style={{ cursor: 'pointer' }}>Submit Feedback</button>
              </form>
            </div>
          </div>
        </div>

      </div>

      <hr />

    </div>
  )
}

export default Feedback