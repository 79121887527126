import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import themeconfig from '../Config/themeconfig';
import axios from '../util/api-client';
import $ from 'jquery';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'
import ReactPaginate from 'react-paginate';
function Notes() {
  const { id } = useParams();
  let active_user_id = localStorage.getItem('active-user-id');
  let parent_id = localStorage.getItem('parent_id');
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [notes_data, setnotes_data] = useState([])
  const [loading, setloading] = useState(true)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const items = notes_data;

  function Items({ currentItems }) {

    return (<div className="row x-docs">
      {
        loading ?
          <div className='wc-custom-loading' style={{ minHeight: '100px' }}>
            <div className="spinner-border mx-auto text-secondary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          :
          currentItems.length === 0 ? (
            <h3 className="col-xl-12 col-md-12 mb-12 text-center">
              No notes found.
            </h3>
          ) : (
            currentItems.map((data, index) => {
              return (
                <div className="col-xl-12 col-md-12 mb-12 x-note" key={index}>
                  <div className="card shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            {data.created_date}<br />
                            <span className="grey">Uploaded by: {data.user_name}</span></div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">{data.title}</div>
                          <div> {data.notes}
                          </div>
                        </div>
                        <div className="col-auto note-icon">
                          <div className="dropdown no-arrow " id='drop_dwon_btn' onClick={() => delete_note(data.id)}>
                            <span className="dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="fa-sharp fa-solid fa-trash" ></i>
                            </span>
                          </div>
                          <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" id='delete_drop_down' aria-labelledby="dropdownMenuLink">
                            <div className="dropdown-header">Note actions:</div>
                            <div className="dropdown-item">Delete</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }))
      }
    </div>)

  }

  // get all notes data 

  const get_all_notes_data = () => {
    let obj = {
      project_id: id
    }
    var config = {
      method: 'get',
      url: themeconfig.APILinks.list_notes,
      headers: {},
      params: obj
    };

    axios(config)
      .then(function (response) {
        if (response) {
          if (response.data.result === true) {
            // console.log(response)
            setnotes_data(response.data.data)
          } else {
            console.log(response)
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      }).finally(() => {
        setloading(false);
      });
  }


  const add_notes_data = (event) => {
    event.preventDefault();
    $('#note_save_btn').addClass('wc-spinner');
    const formData = new FormData(event.target);

    let title = formData.get('title');
    let notes = formData.get('notes');

    const newErrors = {};

    if (!title.trim()) {
      newErrors.title = 'Title is required';
    }

    if (!notes.trim()) {
      newErrors.notes = 'Notes is required';
    }

    let obj = {
      title: title,
      notes: notes,
      project_id: id,
      user_id: active_user_id,
      client_id: parent_id
    }

    setErrors(newErrors)

    if (Object.keys(newErrors).length > 0) {
      $('#note_save_btn').removeClass('wc-spinner');
      return;
    } else {
      var config = {
        method: 'post',
        url: themeconfig.APILinks.add_notes,
        headers: {

        },
        data: obj
      };

      axios(config)
        .then(function (response) {
          if (response) {
            if (response.data.result === true) {
              $('#note_save_btn').removeClass('wc-spinner');
              get_all_notes_data()
              setShow(false);
              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              $('#note_save_btn').removeClass('wc-spinner');
              setShow(true);
              toast.error(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch(function (error) {
          $('#note_save_btn').removeClass('wc-spinner');
          console.log(error);
        });
    }
  }

  const delete_note = (id) => {
    Swal.fire({
      title: 'Are you sure you want delete this Note?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {
          id: id
        }
        var config = {
          method: 'post',
          url: themeconfig.APILinks.notes_delete,
          headers: {

          },
          params: obj
        };

        axios(config)
          .then(function (response) {
            if (response) {
              if (response.data.result === true) {
                Swal.fire(
                  'Deleted!',
                  `Note Deleted Successfully`,
                  'success'
                )
                get_all_notes_data()
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    })
  }

  function PaginatedItems({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          breakLabel="..."
          nextLabel={<i className="fas fa-angle-right ml-2"></i>}
          onPageChange={handlePageClick}
          containerClassName="d-flex align-items-center justify-content-center gap-2 list-unstyled"
          pageCount={pageCount}
          previousLabel={<i className="fas fa-angle-left mr-2"></i>}
          renderOnZeroPageCount={null}
          // pageLinkClassName="text-success-900 text-lg font-weight-bold h-8 sm:h-10 w-8 sm:w-10 d-flex align-items-center justify-content-center rounded"
          activeClassName="rounded bg-dark px-2 py-2 text-white"
        />
      </>
    );
  }

  useEffect(() => {
    get_all_notes_data();
  }, []);


  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={add_notes_data}>
            <div className="form-group">
              <label htmlFor="Name">Title</label>
              <input type="name" className="form-control" placeholder="Title" name='title' />
              {errors.title && <div className="text-danger">{errors.title}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="Feedback">Notes</label>
              <textarea className="form-control" name='notes' rows="6"></textarea>
              {errors.notes && <div className="text-danger">{errors.notes}</div>}
            </div>
            <Button type="submit" className="btn btn-primary mx-2" id='note_save_btn'>Save Notes</Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      <div className="container-fluid">

        <ToastContainer />
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Project Notes</h1>
          <Button variant="primary" onClick={handleShow}>
            <i className="fa fa-comment"></i>  Add Note
          </Button>
        </div>


        <PaginatedItems itemsPerPage={6} />
        <hr />
      </div>
    </>
  );
}

export default Notes;