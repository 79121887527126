import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import themeconfig from '../Config/themeconfig';
import { useParams, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'
import Select from 'react-select';
import axios from '../util/api-client';
import Swal from 'sweetalert2'
import $ from 'jquery';
import { CopyToClipboard } from "react-copy-to-clipboard";
import 'lightbox.js-react/dist/index.css'
import 'react-loading-skeleton/dist/skeleton.css'

const Documents = () => {
  const { id } = useParams();
  let currentUrl = window.location.href;
  let active_user_id = localStorage.getItem('active-user-id');
  let client = currentUrl.split('folder-id=')[1];

  const [show_html, setshow_html] = useState(0)

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [errors, setErrors] = useState({});
  const [document_errors, setdocument_Errors] = useState({});
  const [all_attribute_data, setall_attribute_data] = useState([])
  const [all_document_data, setall_document_data] = useState([])
  const [folder_option, setfolder_option] = useState([])

  const handleShow = () => setShow(true);
  const handle_uplord_file_Show = () => setShow1(true);


  const [share_url, setshare_url] = useState('')
  const [share_folder_name, setshare_folder_name] = useState('')


  const [share_model_show, setShare_model_show] = useState(false);
  const handle_share_Close = () => setShare_model_show(false);
  const [show_share_btn, setshow_share_btn] = useState(0)
  const [folder_share_id, setfolder_share_id] = useState(null)

  const [share_doc_model_show, setShare_doc_model_show] = useState(false);
  const handle_doc_share_Close = () => setShare_doc_model_show(false);
  const [share_doc_name, setshare_doc_name] = useState('')
  const [share_doc_url, setshare_doc_url] = useState('')
  const [show_doc_share_btn, setshow_doc_share_btn] = useState(0)
  const [doc_share_id, setdoc_share_id] = useState(null)
  const [doc_sub_folder, setdoc_sub_folder] = useState([])
  const [breadcrumb_data, setbreadcrumb_data] = useState([])
  const [isCopied, setIsCopied] = useState(false);
  const [showSelect, setShowSelect] = useState(true);
  const [loading, setloading] = useState(true)

  const handleClose = () => {
    setErrors({})
    setShow(false);
  }

  const handle_uplord_file_Close = () => {
    setShow1(false);
    setShowSelect(true);
    setdocument_Errors({})
  }

  // check folder filter or not

  const get_state = () => {
    var cookies = getCookiesMap(document.cookie);
    var cookieValue = cookies["folder_type"];

    if ((currentUrl.includes(`/projects-single/documents/${id}/?folder-id=`))) {
      setshow_html(cookieValue)
    }
  }

  function getCookiesMap(cookiesString) {
    return cookiesString.split(";")
      .map(function (cookieString) {
        return cookieString.trim().split("=");
      })
      .reduce(function (acc, curr) {
        acc[curr[0]] = curr[1];
        return acc;
      }, {});
  }

  // all folder list

  const list_all_atribute_data_doc = () => {
    let obj = {
      project_id: id,
      type: 2,
      parent_id: 0
    }
    var config = {
      method: 'get',
      url: themeconfig.APILinks.list_attribute,
      headers: {
      },
      params: obj
    };

    axios(config)
      .then(function (response) {
        if (response.data.result === true) {
          setall_attribute_data(response.data.data)
        }
      })
      .catch(function (error) {
        console.error(error)
      });
  }

  // all document list

  const list_all_document_data = () => {

    const decodedSearch = client !== undefined ? decodeURIComponent(client) : undefined;

    let obj = {};

    if (decodedSearch !== null && decodedSearch !== undefined) {
      obj.attribute_id = decodedSearch;
    }

    obj.project_id = id
    obj.type = 2

    var config = {
      method: 'get',
      url: themeconfig.APILinks.all_list_document,
      headers: {
      },
      params: obj
    };

    axios(config)
      .then(function (response) {
        if (response.data.result === true) {
          setall_document_data(response.data.data)
          setdoc_sub_folder(response.data.child_data)
          setbreadcrumb_data(response.data.breadcrumb_data)
        }
      })
      .catch(function (error) {
        console.log(error);
      }).finally(() => {
        setloading(false);
      });
  }


  // folder list dropdwon 

  const dropdown_show = (e) => {
    const dropdown = $(e.target).closest('.folder').find('.dropdown');
    const dropdownMenu = $(e.target).closest('.folder').find('.dropdown-menu');

    dropdown.toggleClass('show');
    dropdownMenu.toggleClass('show');

    e.preventDefault();

    e.stopPropagation();
  };

  // document list dropdwon

  const dropdown_document_show = (e) => {
    const dropdown = $(e.target).closest('.document').find('.dropdown');
    const dropdownMenu = $(e.target).closest('.document').find('.dropdown-menu');

    dropdown.toggleClass('show');
    dropdownMenu.toggleClass('show');

    e.preventDefault();

    e.stopPropagation();
  };

  // add folder

  const add_folder_data = (event) => {
    event.preventDefault();
    $('#folder_save_btn').addClass('wc-spinner');
    const formData = new FormData(event.target);
    let name = formData.get('name');

    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = 'Folder Name is required';
    }
    setErrors(newErrors)

    if (Object.keys(newErrors).length > 0) {
      $('#folder_save_btn').removeClass('wc-spinner');
      return;
    } else {
      let obj = {
        name: name,
        project_id: id,
        user_id: active_user_id,
        type: 2
      }
      if ((currentUrl.includes(`/projects-single/documents/${id}/?folder-id=`))) {
        let p_id = 0;
        p_id = currentUrl.split('?folder-id=')[1];
        obj.parent_id = p_id
      } else {
        obj.parent_id = 0
      }
      var config = {
        method: 'post',
        url: themeconfig.APILinks.add_attribute,
        headers: {
        },
        data: obj
      };

      axios(config)
        .then(function (response) {
          if (response) {
            if (response.data.result === true) {
              $('#folder_save_btn').removeClass('wc-spinner');
              list_all_atribute_data_doc()
              defult_folder_select_data_doc()
              list_all_document_data()
              setShow(false);
              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              $('#folder_save_btn').removeClass('wc-spinner');
              toast.error(response.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch(function (error) {
          $('#folder_save_btn').removeClass('wc-spinner');
          console.log(error);
        });

    }
  }

  // delete folder name

  const delete_attribute = (id = '') => {
    Swal.fire({
      title: 'Are you sure you want delete this Folder?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {
          id: id
        }
        var config = {
          method: 'post',
          url: themeconfig.APILinks.delete_attribute,
          headers: {

          },
          data: obj
        };

        axios(config)
          .then(function (response) {
            if (response) {
              if (response.data.result === true) {
                Swal.fire(
                  'Deleted!',
                  response.data.message,
                  'success'
                )
                list_all_atribute_data_doc()
                defult_folder_select_data_doc()
                list_all_document_data()
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',
                })
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    })
  }

  // defult folder data select 

  const defult_folder_select_data_doc = () => {
    const decodedclient = client !== undefined ? decodeURIComponent(client) : undefined;

    let obj = {};

    if (decodedclient !== null && decodedclient !== undefined) {
      obj.attribute_id = decodedclient;
    } else {
      obj.parent_id = 0
    }
    obj.project_id = id
    obj.type = 2

    var config = {
      method: 'get',
      url: themeconfig.APILinks.defult_folder_select,
      headers: {},
      params: obj
    };

    axios(config)
      .then(function (response) {
        if (response) {
          if (response.data.result === true) {
            // console.log(response.data.data)
            setfolder_option(response.data.data)
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  // add document data 

  const add_document_data = (event) => {
    event.preventDefault()
    $('#document_save_btn').addClass('wc-spinner');
    const formData = new FormData(event.target);
    let attribute_id = formData.get('attribute_id');

    let file = formData.get('file');
    const newErrors = {};

    if (!attribute_id) {
      newErrors.attribute_id = 'Folder is required';
    }

    if (file) {
      if (file.name === '') {
        newErrors.file = 'File is required';
      } else {
        // const allowedFormats = ['pdf'];
        const allowedFormats = ['doc', 'docx', 'xls', 'xlsx', 'csv', 'pdf', 'jpeg', 'jpg', 'png', 'heic', 'gif', 'rvt', 'dwg'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (fileExtension && !allowedFormats.includes(fileExtension)) {
          newErrors.file = 'Enter Valid File Format';
        }
      }
    }

    setdocument_Errors(newErrors)

    if (Object.keys(newErrors).length > 0) {
      $('#document_save_btn').removeClass('wc-spinner');
      return;
    } else {
      setdocument_Errors({})
      const formData = new FormData();
      formData.append('attribute_id', attribute_id);
      formData.append('file', file);
      formData.append('user_id', active_user_id);
      formData.append('project_id', id);
      formData.append('type', 2);
      formData.append('file_type', 'single')
      // formData.append('type', 1);

      var config = {
        method: 'post',
        url: themeconfig.APILinks.document_add,
        headers: {},
        data: formData
      };

      axios(config)
        .then(function (response) {
          if (response) {
            if (response.data.result === true) {
              $('#document_save_btn').removeClass('wc-spinner');
              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setShowSelect(true);
              list_all_atribute_data_doc()
              list_all_document_data()
              defult_folder_select_data_doc()

              setShow1(false)
            } else {
              $('#document_save_btn').removeClass('wc-spinner');
              toast.error(response.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch(function (error) {
          $('#document_save_btn').removeClass('wc-spinner');
          console.log(error);
        });
    }
  }

  // delete document 

  const delete_document = (id = '') => {
    Swal.fire({
      title: 'Are you sure you want delete this Document?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {
          id: id
        }
        var config = {
          method: 'post',
          url: themeconfig.APILinks.delete_document,
          headers: {

          },
          data: obj
        };

        axios(config)
          .then(function (response) {
            if (response) {
              if (response.data.result === true) {
                Swal.fire(
                  'Deleted!',
                  response.data.message,
                  'success'
                )
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',
                })
              }
              list_all_document_data()
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    })
  }


  // download pdf

  const download_pdf = (url = '', file_name = '') => {
    if (url) {
      window.location.href = `${themeconfig.APILinks.download_pdf}?file_url=${url}`
    }
  };

  const folder_show = (folder_id = '', folder_type = 1) => {

    const path = '/'; // Specify the desired path for the cookie here

    // Check if the 'folder_type' cookie already exists
    const existingCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('folder_type='));

    if (existingCookie) {
      // If the cookie exists, replace its value
      document.cookie = existingCookie.replace(/folder_type=\d+/, `folder_type=${folder_type}; path=${path}`);
    } else {
      // If the cookie doesn't exist, create a new one
      document.cookie = `folder_type=${folder_type}; path=${path}`;
    }

    // Construct the URL with both folder_id and folder_type as query parameters
    let url = `/projects-single/documents/${id}/?folder-id=${folder_id}`;

    // Redirect the user to the constructed URL
    window.location.href = url;
  }

  // download zip folder

  const download_zip_folder = (attribute_id = '', project_id = '') => {
    window.location.href = themeconfig.APILinks.zip_download + `?project_id=${project_id}&attribute_id=${attribute_id}&user_id=${active_user_id}`
    // window.location.href = `https://api.xtour.com.au/space/public/api/download_zip_file_to_space?project_id=${project_id}&attribute_id=${attribute_id}&user_id=${active_user_id}`
    // window.location.href = themeconfig.APILinks.zip_download + `?project_id=${project_id}&attribute_id=${attribute_id}`
  }

  // share folder

  const share_folder = (folder_id = '', folder_key = '', status = 0, folder_name = '') => {
    setshare_folder_name(folder_name)
    setfolder_share_id(folder_id)
    show_share_button(status)
    setshow_share_btn(status)
    let url = ''
    url = `${themeconfig.site_url}/share/${id}/${folder_id}/${folder_key}`
    // `https://xqm.xtour.com.au/share/${id}/${folder_id}/${folder_key}`
    setshare_url(url)
    setShare_model_show(true);
  }

  const show_share_button = () => {
    //console.log(show_share_btn)
    if (show_share_btn === 0) {
      return (
        <Button type="button" className="btn btn-primary w-100 d-block" onClick={() => toggle_btn('Disable')}>Disable Share Link</Button>
      )
    } else {
      return (
        <Button type="button" className="btn btn-primary w-100 d-block" onClick={() => toggle_btn('Enable')}>Enable Share Link</Button>
      )
    }

  }

  const toggle_btn = (type = '') => {

    if (type === 'Enable') {
      var data = new FormData();
      data.append('id', folder_share_id);
      data.append('status', '0');
      var config = {
        method: 'post',
        url: themeconfig.APILinks.change_status_folder,
        headers: {
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          if (response) {
            if (response.data.result === true) {
              list_all_atribute_data_doc()
              list_all_document_data()
              setshow_share_btn(0)
              show_share_button()
              toast.success('Folder Enabled Successfully', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              toast.error('Please try after some time!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    } else {
      var data = new FormData();
      data.append('id', folder_share_id);
      data.append('status', '1');
      var config = {
        method: 'post',
        url: themeconfig.APILinks.change_status_folder,
        headers: {
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          if (response) {
            if (response.data.result === true) {
              list_all_atribute_data_doc()
              list_all_document_data()
              setshow_share_btn(1)
              show_share_button()
              toast.success('Access to this folder is disabled', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              toast.error('Please try after some time!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  // share doc

  const share_doc = (project_id = '', doc_id = '', doc_key = '', doc_name = '', status = '') => {
    // console.log(status)
    setdoc_share_id(doc_id)
    setshow_doc_share_btn(status)
    setshare_doc_name(doc_name)
    setShare_doc_model_show(true)
    let url = ''
    url = `${themeconfig.site_url}/share-doc/${project_id}/${doc_id}/${doc_key}`
    // `https://xqm.xtour.com.au/share-doc/${project_id}/${doc_id}/${doc_key}`
    setshare_doc_url(url)
  }

  const show_doc_share_button = () => {
    if (show_doc_share_btn === 0) {
      return (
        <Button type="button" className="btn btn-primary w-100 d-block" onClick={() => toggle_doc_btn('Disable')}>Disable Share Link</Button>
      )
    } else {
      return (
        <Button type="button" className="btn btn-primary w-100 d-block" onClick={() => toggle_doc_btn('Enable')}>Enable Share Link</Button>
      )
    }
  }

  const toggle_doc_btn = (type = '') => {
    if (type === 'Enable') {
      var data = new FormData();
      data.append('id', doc_share_id);
      data.append('status', '0');
      var config = {
        method: 'post',
        url: themeconfig.APILinks.change_status_doc,
        headers: {
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          if (response) {
            if (response.data.result === true) {
              list_all_document_data()
              setshow_doc_share_btn(0)
              show_doc_share_button()
              toast.success('File Enabled Successfully', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              toast.error('Please try after some time!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      var data = new FormData();
      data.append('id', doc_share_id);
      data.append('status', '1');
      var config = {
        method: 'post',
        url: themeconfig.APILinks.change_status_doc,
        headers: {
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          if (response) {
            if (response.data.result === true) {
              list_all_document_data()
              setshow_doc_share_btn(1)
              show_doc_share_button()
              toast.success('Access to this file is disabled', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              toast.error('Please try after some time!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const create_folder = () => {
    setShowSelect(false);
    $('#doc_create_fol_btn').addClass('d-none')
  }

  // get_create_folder_button_doc_uplord

  const get_create_btn_fol = (url = '') => {
    if ((url.includes(`/projects-single/documents/${id}/?folder-id=`))) {
      return null
    } else {
      return (
        <Button type="button" id='doc_create_fol_btn' className="btn btn-primary mt-2" onClick={() => create_folder()}>Create Folder</Button>
      )
    }
  }

  // get_folder_option_doc_uplord

  const get_folder_option = (url = '') => {
    if ((url.includes(`/projects-single/documents/${id}/?folder-id=`))) {
      return (<Select
        className="basic-single"
        classNamePrefix="select"
        options={folder_option}
        defaultValue={folder_option}
        name='attribute_id'
        id='doc_fol_select'
      />)
    } else {
      return showSelect ? (
        <Select
          className="basic-single"
          classNamePrefix="select"
          options={folder_option}
          name="attribute_id"
          id="doc_fol_select"
        />
      ) : (
        <div className="mt-2">
          <input
            type="text"
            className="form-control"
            name="attribute_id"
            placeholder="Create Folder"
          />
        </div>
      )
    }
  }

  const admin_fol_show = () => {
    const path = '/'; // Specify the desired path for the cookie here

    // Check if the 'folder_type' cookie already exists
    const existingCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('folder_type='));

    if (existingCookie) {
      // If the cookie exists, replace its value
      document.cookie = existingCookie.replace(/folder_type=\d+/, `folder_type=${2}; path=${path}`);
    } else {
      // If the cookie doesn't exist, create a new one
      document.cookie = `folder_type=${2}; path=${path}`;
    }

    // Construct the URL with both folder_id and folder_type as query parameters

    let url = `/projects-single/documents/${id}/?folder-id=${0}`;
    window.location.href = url
  }

  useEffect(() => {

    $(document).on("click", '.container-fluid', function () {
      $('.dropdown-menu.show#drop_down').removeClass('show');
      $('.dropdown.show#drop_dwon_btn').removeClass('show');
      $('.dropdown.show#drop_dwon_btn1').removeClass('show');
      $('.dropdown-menu.show#drop_down1').removeClass('show');
    });

    get_state()
    list_all_atribute_data_doc()
    list_all_document_data()
    defult_folder_select_data_doc()
  }, [])


  return (
    <>
      <div className="container-fluid">
        <ToastContainer />
        <div>
          {
            show_html == 0 || show_html == 2 ?
              <div className="d-sm-flex align-items-center justify-content-between mb-4">

                <div className='d-flex gap-2' style={{ fontSize: '25px' }}>
                  <a href={`/projects-single/documents/${id}`}>Documents</a>
                  {breadcrumb_data.map((breadcrumb, index) => {
                    if (breadcrumb_data.length - index > 2) {
                      return null
                    } else {
                      return (
                        <div key={index}>
                          <i className="fa-duotone fa-greater-than mr-2"></i>
                          {
                            breadcrumb.status === 1 ? (
                              <span>{breadcrumb.name}</span>
                            ) : (
                              <a href={`/projects-single/documents/${id}/?folder-id=${breadcrumb.id}`}>
                                {breadcrumb.name}
                              </a>
                            )
                          }
                        </div>)
                    }

                  })}
                </div>

                {
                  client == 0 ?
                    ''
                    :
                    <div className="d-flex gap-2">
                      <Button variant="primary" onClick={handle_uplord_file_Show}>
                        <i className="fa fa-upload mr-2"></i>
                        Upload
                      </Button>
                      <Button variant="primary" onClick={handleShow}>
                        <i className="fa fa-plus mr-2"></i>
                        Create Folder
                      </Button>
                    </div>
                }

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Create Folder</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={add_folder_data}>
                      <div className="form-group">
                        <label htmlFor="Name">Document Folder Name</label>
                        <input type="name" className="form-control" name='name' />
                        {errors.name && <div className="text-danger">{errors.name}</div>}
                      </div>
                      <Button type="submit" className="btn btn-primary mx-2" id='folder_save_btn'>Create Folder</Button>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                    </form>
                  </Modal.Body>
                </Modal>

                <Modal show={share_model_show} onHide={handle_share_Close}>
                  <Modal.Header closeButton>
                    <Modal.Title>{share_folder_name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form className='share-folder'>
                      <div className="form-group">
                        <label htmlFor="Name">Share Link Below</label>
                        {
                          isCopied ?
                            <span>
                              Copied!
                            </span>
                            :
                            ''
                        }
                        <input type="name" className="form-control" defaultValue={share_url} name='Link' />
                        <CopyToClipboard text={share_url} onCopy={onCopyText}>
                          <div className="copy-area" style={{ cursor: 'pointer' }}>
                            <i className="fa-solid fa-copy"></i>
                          </div>
                        </CopyToClipboard>
                      </div>
                      {show_share_button(show_share_btn)}
                    </form>
                  </Modal.Body>
                </Modal>
              </div>
              :
              ''
          }

          {
            show_html == 0 ?
              <div className="row x-docs">
                <div className="col-xl-3 col-md-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => admin_fol_show()}>
                  <div className="card shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters">
                        <div className="col mr-2">
                          <div className="h5 mb-0 font-weight-bold text-gray-800">Xtour Documents</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {all_attribute_data.length === 0 ? (
                  <></>
                ) : (
                  all_attribute_data.map((data, index) => {
                    return (
                      <div className="col-xl-3 col-md-6 mb-4" key={index} style={{ cursor: 'pointer' }} onClick={() => folder_show(data.id, data.type)}>
                        <div className="card shadow h-100 py-2">
                          <div className="card-body">
                            <div className="row no-gutters">
                              <div className="col mr-2">
                                <div className="h5 mb-0 font-weight-bold text-gray-800">{data.name}</div>
                              </div>
                              <div className="col-auto folder">
                                <div className="dropdown no-arrow " id='drop_dwon_btn' onClick={(e) => dropdown_show(e)}>
                                  <span className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-ellipsis-h fa-sm fa-fw text-gray-400"></i>
                                  </span>
                                  <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in " id='drop_down' aria-labelledby="dropdownMenuLink">
                                    <div className="dropdown-header">Folder actions:</div>
                                    <div className="dropdown-item" onClick={() => download_zip_folder(data.id, data.project_id)} >Download</div>
                                    <div className="dropdown-item" onClick={() => share_folder(data.id, data.key, data.status, data.name)}>Share</div>
                                    <div className="dropdown-item" onClick={() => delete_attribute(data.id)}>Delete</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                )
                }
              </div>
              :
              ''
          }
        </div>

        {/* <hr /> */}

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <Modal show={show1} onHide={handle_uplord_file_Close}>
            <Modal.Header closeButton>
              <Modal.Title>Upload Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={add_document_data}>
                <div className="form-group">
                  <label htmlFor="Folder">Folder :</label>

                  {get_folder_option(currentUrl)}

                  {/* <div className='mt-2'>
                    <input type="text" className="form-control d-none" name='attribute_id' placeholder='Create Folder' />
                  </div> */}

                  {get_create_btn_fol(currentUrl)}

                  {document_errors.attribute_id && <div className="text-danger">{document_errors.attribute_id}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="File">File :</label>
                  <input type="file" className="form-control" name='file' />
                  {document_errors.file && <div className="text-danger">{document_errors.file}</div>}
                </div>
                <Button type="submit" className="btn btn-primary mx-2" id='document_save_btn'>Upload Document</Button>
                <Button variant="secondary" onClick={handle_uplord_file_Close}>
                  Close
                </Button>
              </form>
            </Modal.Body>
          </Modal>

          <Modal show={share_doc_model_show} onHide={handle_doc_share_Close}>
            <Modal.Header closeButton>
              <Modal.Title>{share_doc_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className='share-folder'>
                <div className="form-group">
                  <label htmlFor="Name">Share Link Below</label>
                  {
                    isCopied ?
                      <span>
                        Copied!
                      </span>
                      :
                      ''
                  }
                  <input type="name" className="form-control" defaultValue={share_doc_url} name='Link' />
                  <CopyToClipboard text={share_doc_url} onCopy={onCopyText}>
                    <div className="copy-area" style={{ cursor: 'pointer' }}>
                      <i className="fa-solid fa-copy"></i>
                    </div>
                  </CopyToClipboard>
                </div>
                {show_doc_share_button(show_doc_share_btn)}
              </form>
            </Modal.Body>
          </Modal>

        </div>

        {
          show_html == 2 ?
            <>
              <div className="row x-docs">

                {
                  loading ?
                    <div className='wc-custom-loading' style={{ minHeight: '200px' }}>
                      <div className="spinner-border mx-auto text-secondary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                    :
                    all_document_data.length === 0 ? (
                      <h4 className="col-12 text-center">
                        No Documents found.
                      </h4>
                    ) : (
                      all_document_data.map((document, index) => {
                        let filename = ''
                        filename = document.file_name.split('.')[0];
                        return (
                          <div className="col-xl-3 col-md-6 mb-4" key={index}>
                            <div className="card shadow h-100 py-2">
                              <div className="card-body">
                                <div className="row flex-nowrap no-gutters">
                                  <div className="col mr-2 wc-width-full ">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                      {document.created_date}<br />
                                      <span className="grey">Uploaded by: {document.user_name}</span>
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{filename}</div>

                                  </div>
                                  {
                                    client == 0 ?
                                      <div className="col-auto document">
                                        <div className="dropdown no-arrow " id='drop_dwon_btn1' onClick={(e) => dropdown_document_show(e)}>
                                          <a className="dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-ellipsis-h fa-sm fa-fw text-gray-400"></i>
                                          </a>
                                          <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in " id='drop_down1' aria-labelledby="dropdownMenuLink" style={{ cursor: 'pointer' }}>
                                            <div className="dropdown-header">Document actions:</div>
                                            <div onClick={() => download_pdf(document.file_url, document.file_name)} className="dropdown-item" >Download</div>
                                            {console.log(document.status)}
                                            <div className="dropdown-item" onClick={() => share_doc(document.project_id, document.id, document.key, document.file_name, document.status)}>Share</div>
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <div className="col-auto document">
                                        <div className="dropdown no-arrow " id='drop_dwon_btn1' onClick={(e) => dropdown_document_show(e)}>
                                          <a className="dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-ellipsis-h fa-sm fa-fw text-gray-400"></i>
                                          </a>
                                          <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in " id='drop_down1' aria-labelledby="dropdownMenuLink" style={{ cursor: 'pointer' }}>
                                            <div className="dropdown-header">Document actions:</div>
                                            <div onClick={() => download_pdf(document.file_url, document.file_name)} className="dropdown-item" >Download</div>
                                            {console.log(document.status)}
                                            <div className="dropdown-item" onClick={() => share_doc(document.project_id, document.id, document.key, document.file_name, document.status)}>Share</div>
                                            <div className="dropdown-item" onClick={() => delete_document(document.id)}>Delete</div>
                                          </div>
                                        </div>
                                      </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }))
                }
              </div>
              <hr />
              {
                client == 0 ?
                  ''
                  :
                  <div className="row x-docs">
                    <div className='d-flex gap-2' style={{ fontSize: '25px' }}><span>Folders</span></div>

                    {
                      loading ?
                        <div className='wc-custom-loading' style={{ minHeight: '200px' }}>
                          <div className="spinner-border mx-auto text-secondary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                        :
                        doc_sub_folder.length === 0 ? (
                          <h4 className="col-12 text-center">
                            No Folders found.
                          </h4>
                        ) : (
                          doc_sub_folder.map((data, index) => {
                            return (
                              <div className="col-xl-3 col-md-6 mb-4" key={index} style={{ cursor: 'pointer' }} onClick={() => folder_show(data.id, data.type)}>
                                <div className="card shadow h-100 py-2">
                                  <div className="card-body">
                                    <div className="row no-gutters">
                                      <div className="col mr-2">
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{data.name}</div>
                                      </div>
                                      <div className="col-auto folder">
                                        <div className="dropdown no-arrow " id='drop_dwon_btn' onClick={(e) => dropdown_show(e)}>
                                          <span className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-ellipsis-h fa-sm fa-fw text-gray-400"></i>
                                          </span>
                                          <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in " id='drop_down' aria-labelledby="dropdownMenuLink">
                                            <div className="dropdown-header">Folder actions:</div>
                                            <div className="dropdown-item" onClick={() => download_zip_folder(data.id, data.project_id)} >Download</div>
                                            <div className="dropdown-item" onClick={() => share_folder(data.id, data.key, data.status, data.name)}>Share</div>
                                            <div className="dropdown-item" onClick={() => delete_attribute(data.id)}>Delete</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        )
                    }
                  </div>
              }
            </>
            :
            ''
        }
        <br />
        <Link className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" to={`/point-cloud/${id}`}>Request Point 	Cloud Data Set</Link>
        <hr />
      </div>


    </ >
  )
}

export default Documents