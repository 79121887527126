import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../util/api-client';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import themeconfig from '../Config/themeconfig';
import Swal from 'sweetalert2'
const AdminUserView = () => {
    const { user_id } = useParams();
    const navigate = useNavigate();

    const [pending, setPending] = useState(true);
    const [all_user_data, setall_user_data] = useState([])
    const [active_user, setactive_user] = useState({})

    const get_active_user = () => {
        var config = {
            method: 'get',
            url: themeconfig.APILinks.get_all_user + user_id,
            headers: {
            }
        };

        axios(config).then(function (response) {
            if (response) {
                if (response.data.result === true) {
                    if (response.data.data && response.data.data.length > 0) {
                        response.data.data.map(data => {
                            setactive_user(data)
                        })
                    }

                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const get_all_user = () => {
        let obj = {
            parent_id: user_id
        }
        var config = {
            method: 'get',
            url: themeconfig.APILinks.user_list,
            headers: {

            },
            params: obj
        };

        axios(config)
            .then(function (response) {
                if (response) {
                    if (response.data.result === true) {
                        setall_user_data(response.data.data)
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            }).finally(() => {
                // Set loading to false after the request is complete
                setPending(false);
            });

    }

    const delete_user = (id = 0) => {
        Swal.fire({
            title: 'Are you sure you want delete this User?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    id: id
                }
                var config = {
                    method: 'post',
                    url: themeconfig.APILinks.delete_user,
                    headers: {},
                    data: obj
                };

                axios(config)
                    .then(function (response) {
                        if (response) {
                            if (response.data.result === true) {
                                Swal.fire(
                                    'Deleted!',
                                    'User Deleted',
                                    'success'
                                )
                                navigate("/clients");
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: `${response.data.message}`,
                                })
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            }
        })
    }

    const columns = [
        {
            name: '#',
            selector: (row) => row.id,
            sortable: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div
                    >{row.id}</div>
                )
            }
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: '300px',
            cell: (row) => {
                return (
                    <div>{row.name}</div>
                )
            }
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: '350px',
            cell: (row) => {
                return (
                    <div>{row.email}</div>
                )
            }
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '150px',
            cell: (row) => {
                if (row.status === 1) {
                    return (<i className="fa-sharp fa-solid fa-circle-xmark text-danger" style={{ fontSize: '20px' }}></i>)
                } else {
                    return (<i className="fa-solid fa-circle-check text-success" style={{ fontSize: '20px' }}></i>)
                }
            }
        },
        {
            name: 'Action',
            selector: row => row.id,
            sortable: true,
            width: '150px',
            cell: (row) => {
                return (
                    <div className='d-flex gap-2'>
                        <Link to={`/clients/edit-user/${row.id}`}>
                            <i className="fas fa-fw fa-edit" style={{ cursor: 'pointer' }}></i>
                        </Link>
                        <i className="fa-sharp fa-solid fa-trash" style={{ cursor: 'pointer' }} onClick={() => delete_user(row.id)}></i>
                    </div>
                )
            }
        }
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Per Page',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    const LoderComponent = () => {
        return (
            <div className="text-center d-flex align-items-center gap-2 m-3">
                <span>Loading...</span>
            </div>
        )
    }

    useEffect(() => {
        get_active_user()
        get_all_user()
    }, [])

    return (
        <>
            <div>
                <div className="container-fluid">

                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <div className='d-sm-flex align-items-center gap-2 flex-wrap'>
                            <Link to={`/clients`} className="h3 mb-0 text-gray-800">All Client</Link>
                            <span className='h3 mb-0'>
                                <i className="fa-solid fa-chevron-right mb-0"></i>
                            </span>
                            <span className='h3 mb-0'>
                                {active_user.name}
                            </span>
                        </div>
                        <div>
                            <Link to={`/clients/${user_id}/add-user`} className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-sm text-white-50"></i> Add User</Link>
                        </div>
                    </div>

                    <div className="row recent-scans">

                        <DataTable
                            columns={columns}
                            data={all_user_data}
                            pagination
                            progressPending={pending}
                            progressComponent={<LoderComponent />}
                            paginationComponentOptions={paginationComponentOptions}
                        />

                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminUserView