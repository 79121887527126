import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import themeconfig from '../Config/themeconfig';
import { useNavigate, Link } from "react-router-dom";
import Select from 'react-select';
import $ from 'jquery';
import axios from '../util/api-client';
import { ToastContainer, toast } from 'react-toastify'
const UpdateProject = () => {
    const navigate = useNavigate();
    let currunt_url = window.location.href;
    let user_role = localStorage.getItem('user_role');
    let edit_project_id = currunt_url.split('?id=')[1];
    const [edit_project_data, setedit_project_data] = useState({})
    const [embed_url, setembed_url] = useState([])
    const [remove_embed_url, setremove_embed_url] = useState(true)
    const [client, setClient] = useState([]);
    const [titleError, setTitleError] = useState('');
    const [clientError, setclientError] = useState('')
    const [urlErrors, setUrlErrors] = useState(['']);

    const get_client = () => {
        let obj = {
            user_role: 1
        }
        var config = {
            method: 'post',
            url: themeconfig.APILinks.get_client_data,
            headers: { "Content-Type": "multipart/form-data" },
            params: obj
        };

        axios(config)
            .then(function (response) {
                // console.log(response)
                setClient(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    // get project details 

    const get_project_data = () => {
        let obj = {
            id: edit_project_id
        }
        var config = {
            method: 'get',
            url: themeconfig.APILinks.get_all_project,
            headers: {
            },
            params: obj
        };

        axios(config)
            .then(function (response) {
                if (response) {
                    if (response.data.result === true) {
                        let project = response.data.data;
                        if (project.length > 0) {
                            project.map((p_data) => {
                                setedit_project_data(p_data)
                                // console.log(p_data.embed)
                                setembed_url(p_data.embed)
                                if (p_data.embed.length === 0) {
                                    setremove_embed_url(false)
                                }
                            })
                        } else {
                            navigate('/projects-admin')
                        }

                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // delete project 
    const delete_project = () => {
        Swal.fire({
            title: 'Are you sure you want delete this project?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    id: edit_project_id
                }
                var config = {
                    method: 'post',
                    url: themeconfig.APILinks.delete_project,
                    headers: {

                    },
                    data: obj
                };

                axios(config)
                    .then(function (response) {
                        if (response) {
                            if (response.data.result === true) {
                                Swal.fire(
                                    'Deleted!',
                                    response.data.message,
                                    'success'
                                )
                                navigate("/projects-admin");
                            } else {
                                toast.error(response.data.message, {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        })
    }

    const remove_url = (url_id, index, data) => {
        // if (data.length != 1) {
        Swal.fire({
            title: 'Are you sure you want to delete this url?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                if (url_id) {
                    // Call API to remove URL
                    let obj = {
                        id: url_id
                    };
                    var config = {
                        method: 'post',
                        url: themeconfig.APILinks.delete_embed_url,
                        headers: {},
                        params: obj
                    };

                    axios(config)
                        .then(function (response) {
                            if (response && response.data.result === true) {
                                get_project_data();
                                Swal.fire(
                                    'Deleted!',
                                    'Url Deleted Successfully!',
                                    'success'
                                );
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else if (index !== undefined) {
                    // Directly remove URL from state
                    setembed_url(prevUrls => prevUrls.filter((_, i) => i !== index));
                    Swal.fire(
                        'Deleted!',
                        'Url Deleted Successfully!',
                        'success'
                    );
                }
            }
        });
        // } else {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: 'Any one project url is required!',
        //     })
        // }

    }

    const add_embed_url = () => {
        let have_url = true
        embed_url.map((val) => {
            if (val.url == '') {
                have_url = false
            }
        })

        if (have_url) {
            setembed_url(prevUrls => [...prevUrls, { name: '', url: '' }]);
        }
    };

    const change_project_status = (status_id = '', status_name = '') => {
        Swal.fire({
            title: 'Are you sure you want update the status?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update it!',
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    id: edit_project_data.id,
                    status: status_id,
                    type: 'project_status',
                };
                var config = {
                    method: 'post',
                    url: themeconfig.APILinks.update_project,
                    headers: {},
                    data: obj,
                };

                axios(config)
                    .then(function (response) {
                        if (response) {
                            if (response.data.result === true) {
                                get_project_data()
                                Swal.fire('Updated!', 'Status has been Updated.', 'success');
                                setTimeout(() => {
                                    navigate("/projects-admin");
                                }, 1500);
                            } else {
                                console.log(response);
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        });
    }

    const display_btn = (project_status = '') => {
        if (project_status === 0) {
            return (
                <button type="submit" className="btn btn-primary" onClick={() => change_project_status(1, 'Disable')}>Disable Project</button>
            );
        } else if (project_status === 1) {
            return (
                <button type="submit" className="btn btn-primary" onClick={() => change_project_status(0, 'Enable')}>Enable Project</button>
            );
        } else {
            return null;
        }
    }

    const handleUrlChange = (index, event, type = '') => {
        let name = ''
        let url = ''
        if (type) {
            if (type == 'name') {
                name = event.target.value
            } else {
                url = event.target.value
            }
        }

        setembed_url(prevUrls => {
            // console.log(prevUrls)
            const updatedUrls = [...prevUrls];
            if (type == 'name') {
                updatedUrls[index].name = name;
            } else {
                updatedUrls[index].url = url;
            }
            // console.log(updatedUrls)
            return updatedUrls;
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        $('#update_project').addClass('wc-spinner');
        // Gather form data using FormData
        const formData = new FormData(event.target);

        const embedData = embed_url.map(({ id, url, name }) => {
            if (id) {
                return { id, name, url };
            } else {
                return { id: 0, name, url, };
            }
        });
        // console.log(embedData)
        // Create an object to store form data

        let id = edit_project_id;
        let title = formData.get('title');
        let client = formData.get('client_id');
        let embed_url1 = embedData;
        let active_user = localStorage.getItem('active-user-id');

        let isValid = true;
        let isValid1 = true;

        if (title.trim() === '') {
            setTitleError('Project Title is required.');
            isValid = false;
        } else {
            setTitleError('');
            isValid = true;
        }

        if (client.trim() === '') {
            setclientError('Client is required.');
            isValid1 = false;
        } else {
            setclientError('');
            isValid1 = true;
        }

        const urlFormatRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        const newUrlErrors = embed_url1.map((field) => {
            if (field.url.trim() === '') {
                return 'URL cannot be empty.';
            }
            // else if (!urlFormatRegex.test(field.url.trim())) {
            //     return 'Please Enter Valid URL.';
            // }
            return '';
        });


        setUrlErrors(newUrlErrors);

        //console.log(isValid)
        if (!isValid || !isValid1 || newUrlErrors.some((error) => error !== '')) {
            // There are errors, don't submit
            $('#update_project').removeClass('wc-spinner');
            return;
        } else {
            var form_Data = new FormData();
            form_Data.append('id', id);
            form_Data.append('title', title);
            form_Data.append('embed_url', JSON.stringify(embed_url1));
            form_Data.append('client_id', client);
            form_Data.append('user_id', active_user);

            // Now you have all the form data in formDataObject

            var config = {
                method: 'post',
                url: themeconfig.APILinks.update_project,
                headers: {},
                data: form_Data,
            };

            axios(config).then(function (response) {
                if (response) {
                    if (response.data.result === true) {
                        $('#update_project').removeClass('wc-spinner');
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setTimeout(() => {
                            navigate("/projects-admin");
                        }, 1500);
                    } else {
                        $('#update_project').removeClass('wc-spinner');
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }
            }).catch(function (error) {
                $('#update_project').removeClass('wc-spinner');
                toast.warn('Please try after some time!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
        }


    };

    useEffect(() => {
        get_project_data()
        get_client()
    }, [])


    return (
        <div>
            <div className="container-fluid">

                <div className="row project-scan">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <ToastContainer />
                    </div>

                    <div className="col-xl-12 col-lg-12">
                        <div>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <h4>Project Address</h4>
                                    <input type="text" name="title" defaultValue={edit_project_data.title} className="form-control" placeholder="Scan Type - Address" />
                                    {titleError && <div className="error-message text-danger">{titleError}</div>}
                                </div>
                                <hr />
                                <div className="form-group">
                                    <h6>URL Name</h6>
                                    {embed_url && embed_url.map((field, index) => (
                                        <div className="wc-repeater-group" key={index}>
                                            <div className="form-group">
                                                <h6>{`Project URL ${index + 1}`}</h6>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="wc-url-name"
                                                    placeholder="Enter URL Name"
                                                    defaultValue={field.name}
                                                    onChange={(e) => { handleUrlChange(index, e, 'name'); }}
                                                />
                                                <input
                                                    type="text"
                                                    name="embed_url"
                                                    className="form-control"
                                                    placeholder="Project URL"
                                                    defaultValue={field.url}
                                                    onChange={(e) => handleUrlChange(index, e, 'embed_url')}
                                                />
                                                {urlErrors[index] && <div className="error-message text-danger">{urlErrors[index]}</div>}
                                            </div>
                                            {
                                                remove_embed_url ? <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => remove_url(field.id, index, embed_url)}
                                                >
                                                    <i className="fa-solid fa-xmark"></i>
                                                </button>
                                                    :
                                                    ''
                                            }
                                        </div>
                                    ))}
                                </div>

                                <div className="form-group">
                                    <button type="button" className="btn btn-primary" onClick={add_embed_url} >
                                        +
                                    </button>
                                </div>

                                <div className="form-group">
                                    <div className="col-xl-3 col-md-6 mb-4" style={{ cursor: 'pointer' }} >
                                        <h6>Project Document Folder</h6>
                                        <Link to={`/folder/documents/${edit_project_id}`}>
                                            <div className="card shadow h-100 p-3">
                                                <div className="card-body">
                                                    <div className="row no-gutters">
                                                        <div className="col mr-2">
                                                            <div className="h5 mb-0 font-weight-bold text-gray-800">Xtour Documents</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                <hr />
                                <h4>Assign Project to Client</h4>
                                {
                                    edit_project_data.client_data ?
                                        <div>
                                            <Select
                                                options={client}
                                                name="client_id"
                                                defaultValue={edit_project_data.client_data}
                                                placeholder="Select"
                                            />
                                            {clientError && <div className="error-message text-danger">{clientError}</div>}
                                        </div>
                                        : ''
                                }

                                <br />
                                <button type="submit" className="btn btn-primary" id='update_project'>Update Project</button>

                            </form>
                        </div>

                        <div style={{ marginTop: '50px' }} >
                            {display_btn(edit_project_data.status)}
                            <button type="submit" className="btn btn-primary float-right" onClick={() => delete_project()}>Delete Project</button>
                        </div>

                    </div>

                </div>

                <hr />
            </div>
        </div>
    )
}

export default UpdateProject
