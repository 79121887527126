import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from '../util/api-client';
import ReactApexChart from 'react-apexcharts';
import themeconfig from '../Config/themeconfig';
const Dashboard = () => {
    let active_user_role = localStorage.getItem('user_role');
    let active_user_id = localStorage.getItem('active-user-id');
    const [project_data, set_project_data] = useState([])
    const [notes_data, setnotes_data] = useState([])
    const [project_view_count_array, setproject_view_count_array] = useState([])
    const [project_loading, setproject_loading] = useState(true);

    let chart_month_array = themeconfig.chart.chart_month;

    const get_chart_data = () => {
        let obj = {
            user_id: active_user_id
        }
        var config = {
            method: 'get',
            url: themeconfig.APILinks.analytics_list,
            headers: {},
            params: obj
        };
        axios(config)
            .then(function (response) {
                if (response.data.result === true) {
                    const chart_data = response.data.data.chart_data;
                    const new_project_view_chartData = chart_month_array.map((month) => {
                        const existingData = chart_data.find((data) => data.months === month);
                        return existingData ? existingData.views : 0;
                    });
                    setproject_view_count_array(new_project_view_chartData)
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const chartOptions = {
        chart: {
            type: 'bar',
        },
        colors: ['#000000'], // Set the color to black
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        plotOptions: {
            bar: {
                columnWidth: '40%', // Set the desired column width (e.g., 50%)
            },
        },
    };

    const chartSeries = [
        {
            name: 'Views',
            data: project_view_count_array,
        },
    ];

    const get_all_project = () => {
        let obj = {
            user_role: active_user_role,
            user_id: active_user_id
        }
        var config = {
            method: 'get',
            url: themeconfig.APILinks.project_list,
            headers: {
            },
            params: obj
        };

        axios(config).then(function (response) {
            if (response) {
                if (response.data.result === true) {
                    let all_project_data = response.data.data;
                    let lastThreeProjects = []
                    lastThreeProjects = all_project_data.slice(0, 3);
                    set_project_data(lastThreeProjects)
                }
            }
        }).catch(function (error) {
            console.log(error);
        }).finally(() => {
            setproject_loading(false);
        });
    }

    const get_notes = () => {
        let obj = {
            user_id: active_user_id,
            per_page: 5
        }
        var config = {
            method: 'get',
            url: themeconfig.APILinks.get_logs_list,
            headers: {},
            params: obj
        };

        axios(config)
            .then(function (response) {
                if (response) {
                    if (response.data.result === true) {
                        setnotes_data(response.data.data)
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const display_btn = (role = '') => {
        if (role) {
            if (role == 0) {
                return (
                    <Link to="/projects-admin/add-project" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-sm text-white-50"></i> Create Project</Link>
                )
            } else {
                return (
                    <Link to="/project-booking" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-sm text-white-50"></i> Project Booking</Link>
                )
            }
        }

    }

    const display_view_all_project = (role = '') => {
        if (role == 0) {
            return (
                <div className="text-center">
                    <Link to='/projects-admin'><button type="button" className="btn btn-primary">View all projects</button></Link>
                </div>
            )
        } else {
            return (
                <div className="text-center">
                    <Link to='/projects'><button type="button" className="btn btn-primary">View all projects</button></Link>
                </div>)
        }
    }

    // start check image url is valid or not

    function isValidImageUrl(url) {
        const img = new Image();
        img.src = url;

        return new Promise((resolve) => {
            img.onload = function () {
                resolve(true);
            };

            img.onerror = function () {
                resolve(false);
            };
        });
    }

    function ImageComponent({ imageUrl, defaultImageUrl }) {
        const [isValid, setIsValid] = React.useState(false);

        React.useEffect(() => {
            isValidImageUrl(`https://my.matterport.com/api/v1/player/models/${imageUrl}/thumb`).then((valid) => {
                setIsValid(valid);
            });
        }, [imageUrl]);

        return (
            <div className="card-body scan-thumb wc-projects-grid-image">
                {isValid ? (
                    <img src={`https://my.matterport.com/api/v1/player/models/${imageUrl}/thumb`} alt="Project" />
                ) : (
                    <img src={defaultImageUrl} alt="Default" />
                )}
            </div>
        );
    }

    // end check image url is valid or not

    const project_view = (id) => {

        let project_data = {
            project_id: id,
            user_id: active_user_id
        }

        var config = {
            method: 'post',
            url: themeconfig.APILinks.analytics_add,
            headers: {},
            data: project_data
        };

        axios(config)
            .then(function (response) {
                console.log(response)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    useEffect(() => {
        get_chart_data()
        get_all_project()
        get_notes()
    }, []);

    return (
        <div>
            <div className="container-fluid">

                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Recent Projects</h1>
                    {display_btn(active_user_role)}
                </div>

                <div className="row recent-scans">
                    {
                        project_loading ?
                            <div className='wc-custom-loading'>
                                <div className="spinner-border mx-auto text-secondary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            project_data.length === 0 ? (
                                <h3 className="col-md-12 text-center">No result found</h3>
                            ) :
                                project_data.map((project, index) => {
                                    if (active_user_role == 1) {
                                        return (<div key={index} className={project.status === 1 ? "col-xl-4 col-lg-4 disable-click" : "col-xl-4 col-lg-4"}>
                                            <div className="card shadow mb-4" onClick={() => project_view(project.id)}>
                                                <Link to={`/projects-single/model/${project.id}`}>
                                                    <div className="card-header project-view-card py-3">
                                                        {
                                                            project.status === 1 ? <i className="fa-solid fa-ban"></i> : ''
                                                        }
                                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                            {/* {project.title}<br /> */}
                                                            <h4 className="grey">{project.title}</h4>
                                                        </div>
                                                        <h6 className="m-0 font-weight-bold text-primary">{project.created_date}</h6>
                                                    </div>
                                                    <ImageComponent
                                                        imageUrl={project.image}
                                                        defaultImageUrl={themeconfig.image.xtour_image}
                                                    />
                                                </Link>
                                            </div>
                                        </div>)
                                    } else if (active_user_role == 2) {
                                        return (<div key={index} className={project.status === 1 ? "col-xl-4 col-lg-4 disable-click" : "col-xl-4 col-lg-4"}>
                                            <div className="card shadow mb-4" onClick={() => project_view(project.id)}>
                                                <Link to={`/projects-single/model/${project.id}`}>
                                                    <div className="card-header project-view-card py-3">
                                                        {
                                                            project.status === 1 ? <i className="fa-solid fa-ban"></i> : ''
                                                        }
                                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                            {/* {project.title}<br /> */}
                                                            <h4 className="grey">{project.title}</h4>
                                                        </div>
                                                        <h6 className="m-0 font-weight-bold text-primary">{project.created_date}</h6>
                                                    </div>
                                                    <ImageComponent
                                                        imageUrl={project.image}
                                                        defaultImageUrl={themeconfig.image.xtour_image}
                                                    />

                                                </Link>
                                            </div>
                                        </div>)
                                    } else {
                                        return (<div key={index} className="col-xl-4 col-lg-4">
                                            <div className="card shadow mb-4">
                                                <Link to={`/projects-admin/edit-project/?id=${project.id}`}>
                                                    <div className="card-header py-3">
                                                        {
                                                            project.status === 1 ? <i className="fa-solid fa-ban"></i> : ''
                                                        }
                                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                            {project.created_date}<br />
                                                            <span className="grey">Client: {project.client_name}</span>
                                                        </div>
                                                        <h6 className="m-0 font-weight-bold text-primary">{project.title}</h6>
                                                    </div>

                                                    <ImageComponent
                                                        imageUrl={project.image}
                                                        defaultImageUrl={themeconfig.image.xtour_image}
                                                    />

                                                </Link>
                                            </div>
                                        </div>)
                                    }
                                })}
                </div>

                {display_view_all_project(active_user_role)}



                <hr />

                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">All project views</h6>
                            </div>
                            <div className="card-body">
                                <div className="column-chart">
                                    <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={350} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Recent Activity</h6>
                            </div>
                            <div className="card-body">
                                <table className="table">
                                    {/* {console.log(notes_data)} */}
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ fontWeight: 'bold' }}>Description</th>
                                            <th scope="col" style={{ fontWeight: 'bold' }}>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {notes_data.length === 0 ? (
                                            <tr>
                                                <td colSpan="2" className='text-center fw-bold'>No data found</td>
                                            </tr>
                                        ) : (
                                            notes_data.map((data, index) => (
                                                <tr key={index}>
                                                    <th>{data.description}</th>
                                                    <td>{data.created_date}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard