import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import themeconfig from '../Config/themeconfig';
import axios from '../util/api-client';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Swal from 'sweetalert2'
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify'

const Folder = () => {

    let { project_id } = useParams();

    let active_user_id = localStorage.getItem('active-user-id');

    const [doc_model, setdoc_model] = useState(false)
    const [document_data, setdocument_data] = useState([])
    const [document_errors, setdocument_Errors] = useState({});
    const [loading, setloading] = useState(true);

    const folder_option = [{ value: 0, label: 'Xtour Documents' }]

    const handle_uplord_doc_Show = () => {
        setdoc_model(true);
    }

    const handle_uplord_doc_Close = () => {
        setdoc_model(false);
        setdocument_Errors({});
    }

    const list_all_document_data = () => {
        let obj = {};
        obj.attribute_id = 0;
        obj.project_id = project_id
        obj.type = 2

        var config = {
            method: 'get',
            url: themeconfig.APILinks.all_list_document,
            headers: {
            },
            params: obj
        };

        axios(config)
            .then(function (response) {
                if (response.data.result === true) {
                    setdocument_data(response.data.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            }).finally(() => {
                setloading(false);
            });
    }

    const add_document = (event) => {
        event.preventDefault()
        $('#document_save_btn').addClass('wc-spinner');
        const formData = new FormData(event.target);
        let attribute_id = formData.get('attribute_id');
        let file = formData.get('file');
        const newErrors = {};

        if (!attribute_id) {
            newErrors.attribute_id = 'Folder is required';
        }

        if (file) {
            if (file.name === '') {
                newErrors.file = 'File is required';
            } else {
                const allowedFormats = ['doc', 'docx', 'xls', 'xlsx', 'csv', 'pdf', 'jpeg', 'jpg', 'png', 'heic', 'gif', 'rvt', 'dwg','ifc'];
                const fileExtension = file.name.split('.').pop().toLowerCase();

                if (fileExtension && !allowedFormats.includes(fileExtension)) {
                    newErrors.file = 'Enter Valid File Format';
                }
            }
        }

        setdocument_Errors(newErrors)

        if (Object.keys(newErrors).length > 0) {
            $('#document_save_btn').removeClass('wc-spinner');
            return;
        } else {
            const formData = new FormData();
            formData.append('attribute_id', 0);
            formData.append('file', file);
            formData.append('user_id', active_user_id);
            formData.append('project_id', project_id);
            formData.append('type', 2);
            formData.append('file_type', 'single')

            var config = {
                method: 'post',
                url: themeconfig.APILinks.document_add,
                headers: {},
                data: formData
            };

            axios(config)
                .then(function (response) {
                    if (response) {
                        if (response.data.result === true) {
                            $('#document_save_btn').removeClass('wc-spinner');
                            list_all_document_data()
                            setdoc_model(false)
                            toast.success(response.data.message, {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        } else {
                            $('#document_save_btn').removeClass('wc-spinner');
                            toast.error(response.data.message, {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                })
                .catch(function (error) {
                    $('#document_save_btn').removeClass('wc-spinner');
                    toast.warn('Please try after some time.', {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    console.log(error);
                });
        }
    }

    const dropdown_show = (e) => {
        const dropdown = $(e.target).closest('.folder').find('.dropdown');
        const dropdownMenu = $(e.target).closest('.folder').find('.dropdown-menu');

        dropdown.toggleClass('show');
        dropdownMenu.toggleClass('show');

        e.preventDefault();

        e.stopPropagation();
    };

    const delete_document = (id = '') => {
        Swal.fire({
            title: 'Are you sure you want delete this Document?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    id: id
                }
                var config = {
                    method: 'post',
                    url: themeconfig.APILinks.delete_document,
                    headers: {

                    },
                    data: obj
                };

                axios(config)
                    .then(function (response) {
                        if (response) {
                            if (response.data.result === true) {
                                Swal.fire(
                                    'Deleted!',
                                    response.data.message,
                                    'success'
                                )
                            }
                            list_all_document_data()
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        })
    }

    useEffect(() => {
        $(document).on("click", '.container-fluid', function () {
            $('.dropdown.show#drop_dwon_btn1').removeClass('show');
            $('.dropdown-menu.show#drop_down1').removeClass('show');
        });
        list_all_document_data()
		console.log('deni')
    }, [])


    return (
        <div className='container-fluid'>
            <ToastContainer />
            <Link to={`/projects-admin/edit-project/?id=${project_id}`}>
                <div className='d-flex align-items-center gap-2 ' style={{ fontSize: '25px' }}>
                    <i className="fa-solid fa-backward-fast"></i>
                    {/* <span>Back to Project</span> */}
                </div>
            </Link>
            <hr />
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <div className='d-flex gap-2 ' style={{ fontSize: '25px' }}>
                    <a>Documents</a>
                </div>

                <div className="d-flex gap-2">
                    <Button variant="primary" onClick={handle_uplord_doc_Show}>
                        <i className="fa fa-upload mr-2"></i>
                        Upload
                    </Button>
                    <Modal show={doc_model} onHide={handle_uplord_doc_Close}>
                        <Modal.Header closeButton>
                            <Modal.Title>Upload Document</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={add_document}>
                                <div className="form-group">
                                    <label htmlFor="Folder">Folder :</label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        options={folder_option}
                                        name='attribute_id'
                                    />
                                    {document_errors.attribute_id && <div className="text-danger">{document_errors.attribute_id}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="File">File :</label>
                                    <input type="file" className="form-control" name='file' />
                                    {document_errors.file && <div className="text-danger">{document_errors.file}</div>}
                                </div>
                                <Button type="submit" className="btn btn-primary mx-2" id='document_save_btn'>Upload Document</Button>
                                <Button variant="secondary" onClick={handle_uplord_doc_Close}>
                                    Close
                                </Button>
                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>

            <div className="row x-docs">

                {
                    loading ?
                        <div className='wc-custom-loading' style={{ minHeight: '200px' }}>
                            <div className="spinner-border mx-auto text-secondary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        document_data.length === 0 ? (
                            <h4 className="col-12 text-center">
                                No Documents found.
                            </h4>
                        ) : (
                            document_data.map((document, index) => {
                                let filename = ''
                                filename = document.file_name.split('.')[0];
                                return (
                                    <div className="col-xl-3 col-md-6 mb-4" key={index}>
                                        <div className="card shadow h-100 py-2">
                                            <div className="card-body">
                                                <div className="row no-gutters">
                                                    <div className="col mr-2">
                                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                            {document.created_date}<br />
                                                            <span className="grey">Uploaded by: {document.user_name}</span>
                                                        </div>
                                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{filename}</div>

                                                    </div>
                                                    <div className="col-auto folder" onClick={(e) => dropdown_show(e)}>
                                                        <div className="dropdown no-arrow " id='drop_dwon_btn1'>
                                                            <a className="dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fas fa-ellipsis-h fa-sm fa-fw text-gray-400"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in " id='drop_down1' aria-labelledby="dropdownMenuLink" style={{ cursor: 'pointer' }}>
                                                                <div className="dropdown-header">Document actions:</div>
                                                                {/* <div className="dropdown-item">Download</div>
                                                        <div className="dropdown-item">Share</div> */}
                                                                <div className="dropdown-item" onClick={() => delete_document(document.id)}>Delete</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }))
                }
            </div>
        </div>
    )
}

export default Folder