import axios from "axios";
import { getUserToken } from "../Components/FirebaseProvider";

axios.interceptors.request.use(async function (config) {
  const token = await getUserToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return Promise.reject(error);
  });

export default axios;