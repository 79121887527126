import React, { useEffect, useState } from 'react';
import themeconfig from '../Config/themeconfig';
import axios from '../util/api-client';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
import { useUser } from 'reactfire';
import GlobleClientUpdateLogin from './GlobleClientUpdateLogin';

const GlobleClientUpdate = () => {
  const navigate = useNavigate();
  const { data: firebaseUser } = useUser();
  let currentUrl = window.location.href;

  // states
  const [userDataFetched, setUserDataFetched] = useState(false);
  const [edit_user_data, setedit_user_data] = useState({});
  const [formErrors, setFormErrors] = useState({
    name: '',
    phone_number: '',
    email: '',
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageError, setImageError] = useState('');

  const get_client_data = () => {
    var config = {
      method: 'post',
      url: themeconfig.APILinks.authactiveuser,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        if (response) {
          if (response.data.result === true) {
            setUserDataFetched(true);
            setedit_user_data(response.data.data)
          } else {
            navigate('/');
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file)
    setSelectedImage(url);
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    const fileInput = document.getElementById('formFile');
    fileInput.value = null;
  };

  // save client details

  const upadte_client_data = (event) => {
    event.preventDefault();
    $('#update_client').addClass('wc-spinner');
    const formData = new FormData(event.target);

    const fullName = formData.get('name');
    const phoneNumber = formData.get('phone_number');
    const email = formData.get('email');
    const address = formData.get('address');
    const logoFile = formData.get('logo');

    const errors = {};

    if (!fullName) {
      errors.name = 'Name is required!';
    }

    if (!phoneNumber) {
      errors.phone_number = 'phoneNumber is required!';
    }

    if (logoFile.name === '' || logoFile.name === null || logoFile.name === undefined) {

    } else {
      const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif']; // Add more types if needed
      if (!allowedImageTypes.includes(logoFile.type)) {
        setImageError('Please select a valid image file (JPEG, PNG, GIF).');
      } else {
        setImageError('');
      }
    }

    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      $('#update_client').removeClass('wc-spinner');
      return;
    } else {
      let obj = {
        name: fullName,
        phone_number: phoneNumber,
        email: email,
        address: address,
        logo: logoFile,
      }
      const form_Data = new FormData();
      if (obj.logo) {
        if (obj.logo.name === '') {
          // Do nothing, as the file name is empty
        } else {
          const logoFile = obj.logo;
          form_Data.append('logo', logoFile);
        }
      }

      form_Data.append('id', edit_user_data.id);
      form_Data.append('type', 'one_time');
      // Append other non-empty and non-null key-value pairs to form_Data

      Object.entries(obj).forEach(([key, value]) => {
        if (key !== 'logo' && value !== null && value !== '') {
          // console.log(key)
          form_Data.append(key, value);
        }
      });

      var config = {
        method: 'post',
        url: themeconfig.APILinks.update_user,
        headers: {

        },
        data: form_Data
      };

      axios(config)
        .then(function (response) {
          if (response) {
            if (response.data.result === true) {
              $('#update_client').removeClass('wc-spinner');
              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setTimeout(() => {
                navigate("/");
              }, 3000);
            } else {
              $('#update_client').removeClass('wc-spinner');
              toast.error(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        })
        .catch(function (error) {
          $('#update_client').removeClass('wc-spinner');
          console.log(error);
        });

    }
  }

  useEffect(() => {
    // Wait for user to be logged in, then get client data
    firebaseUser && !userDataFetched && get_client_data()
  }, [firebaseUser, userDataFetched])


  return (
    <div>
      <ToastContainer />
      {firebaseUser ? (
        <div className="container-fluid">
          <div className="row account-contant">
            <div className="col-12">
              <div className="card card-statistics">
                <div className="card-body p-0">
                  <div className="row no-gutters">
                    <div className="col-12">
                      <div className="page-account-form">
                        <div className="form-titel border-bottom p-3">
                          <h5 className="mb-0 py-2">Edit the clients profile</h5>
                        </div>
                        <div className="p-4">
                          <form id="client_form_data" onSubmit={upadte_client_data}>
                            <div className="form-row">
                              <h5 className="mb-0 py-2">
                                <i className="fa fa-user"></i> User Information
                              </h5>
                              <div className="form-group col-md-12">
                                <label htmlFor="name1">Full Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={edit_user_data.name}
                                  id="name1"
                                  name="name"
                                />
                                {formErrors.name && (
                                  <div className="error-message text-danger m-1">{formErrors.name}</div>
                                )}
                              </div>

                              <div className="form-group col-md-12">
                                <label htmlFor="phone1">Phone Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="phone1"
                                  name="phone_number"
                                  defaultValue={edit_user_data.phone_number}
                                />
                                {formErrors.phone_number && (
                                  <div className="error-message text-danger m-1">{formErrors.phone_number}</div>
                                )}
                              </div>
                              <div className="form-group col-md-12">
                                <label htmlFor="email1">Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email1"
                                  name="email"
                                  defaultValue={edit_user_data.email}
                                  disabled
                                />
                                {formErrors.email && (
                                  <div className="error-message text-danger m-1">{formErrors.email}</div>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="add1">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                id="add1"
                                name="address"
                                defaultValue={edit_user_data.address}
                              />
                            </div>

                            <div className='wc-logo-upload'>
                              <div className="form-group">
                                <label htmlFor="add2">Logo</label>
                                {selectedImage && (
                                  <div className='wc-logo-upload-box'>
                                    <img
                                      src={selectedImage ? selectedImage : ''}
                                      alt="Selected logo"
                                    />
                                    {selectedImage ? <button onClick={handleRemoveImage}>X</button> : ''}
                                  </div>
                                )}
                                <br />
                                <input
                                  className="form-control"
                                  type="file"
                                  name="logo"
                                  id="formFile"
                                  onChange={handleImageChange}
                                />
                                {imageError && <div className="error-message text-danger">{imageError}</div>}
                              </div>
                            </div>

                            <div className="form-group"></div>
                            <button type="submit" className="btn btn-primary " id='update_client'>
                              Update Information
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : <GlobleClientUpdateLogin />}
    </div>
  );
};

export default GlobleClientUpdate;




