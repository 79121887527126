import React, { useEffect, useState } from 'react'
import axios from '../util/api-client';
import { useParams } from "react-router-dom";
import themeconfig from '../Config/themeconfig';
import ReactApexChart from 'react-apexcharts';
const Statistics = () => {
  const { id } = useParams();
  let active_user_id = localStorage.getItem('active-user-id');
  const [notes_data, setnotes_data] = useState([])
  const [project_view_count_array, setproject_view_count_array] = useState([])
  const [project_data, setproject_data] = useState({})
  let chart_month_array = themeconfig.chart.chart_month;

  // get project data 

  const get_project_data = () => {
    let obj = {
      id: id
    }
    var config = {
      method: 'get',
      url: themeconfig.APILinks.get_all_project,
      headers: {
      },
      params: obj
    };

    axios(config)
      .then(function (response) {
        if (response) {
          if (response.data.result === true) {
            let project_data = response.data.data
            project_data.map((project) => {
              setproject_data(project)
            })
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const get_chart_data = () => {
    let obj = {
      project_id: id
    }
    var config = {
      method: 'get',
      url: themeconfig.APILinks.analytics_list,
      headers: {},
      params: obj
    };
    axios(config)
      .then(function (response) {
        if (response.data.result === true) {
          const chart_data = response.data.data.chart_data;
          const new_project_view_chartData = chart_month_array.map((month) => {
            const existingData = chart_data.find((data) => data.months === month);
            return existingData ? existingData.views : 0;
          });
          setproject_view_count_array(new_project_view_chartData)
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const chartOptions = {
    chart: {
      type: 'bar',
    },
    colors: ['#000000'], // Set the color to black
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    plotOptions: {
      bar: {
        columnWidth: '40%', // Set the desired column width (e.g., 50%)
      },
    },
  };

  const chartSeries = [
    {
      name: 'Views',
      data: project_view_count_array
    },
  ];

  const get_notes = () => {
    let obj = {
      // user_id: active_user_id,
      project_id: id,
      per_page: 5
    }
    var config = {
      method: 'get',
      url: themeconfig.APILinks.get_logs_list,
      headers: {},
      params: obj
    };

    axios(config)
      .then(function (response) {
        if (response) {
          if (response.data.result === true) {
            setnotes_data(response.data.data)
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    get_project_data()
    get_chart_data()
    get_notes()
  }, [])


  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="row x-notes">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">{project_data.title}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">All project views</h6>
                </div>
                <div className="card-body">
                  <div className="column-chart">
                    <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={350} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">Recent Activity</h6>
                </div>
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" style={{ fontWeight: 'bold' }}>Description</th>
                        <th scope="col" style={{ fontWeight: 'bold' }}>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notes_data.length === 0 ? (
                        <tr>
                          <td colSpan="2" className='text-center fw-bold'>No data found</td>
                        </tr>
                      ) : (
                        notes_data.map((data, index) => (
                          <tr key={index} >
                            <th>{data.description}</th>
                            <td>{data.created_date}</td>
                          </tr>
                        ))
                      )}
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default Statistics