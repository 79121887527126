
import React, { useEffect } from 'react'
import Home from '../portal/home/Home';
import { useUser } from "reactfire";
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
const ProtectedRoute = () => {

    const { status, data: user } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (status === 'loading') return;
        if (!user) {
            navigate("/login")
        }
    }, [navigate, status, user]);

    return user ? (
        <Home />
    ) : null;
}

export default ProtectedRoute