import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import axios from '../util/api-client';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import themeconfig from '../Config/themeconfig';
import { useNavigate, useParams } from "react-router-dom";
const AddUserAdmin = () => {

    const { user_id } = useParams();
    const navigate = useNavigate();
    const [project_data, setproject_data] = useState([])
    const [formErrors, setFormErrors] = useState({});

    const get_project_data = () => {
        let obj = {
            user_id: user_id,
            user_role: 1
        }
        var config = {
            method: 'get',
            url: themeconfig.APILinks.get_project_defualt_data,
            headers: {},
            params: obj
        };

        axios(config).then(function (response) {
            setproject_data(response.data.data)
        }).catch(function (error) {
            console.log(error);
        });

    }

    // add user data 

    const add_user = (event) => {
        event.preventDefault();
        $('#add_user_submit').addClass('wc-spinner');

        const formData = new FormData(event.target);
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone_number');
        const selectedProjects = formData.getAll('project_id');
        const validSelectedProjects = selectedProjects.filter(projectId => projectId.trim() !== '');

        const errors = {};

        if (!name.trim()) {
            errors.name = 'Full Name is required';
        }

        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            errors.email = 'Invalid Email';
        }

        // if (!phone.trim()) {
        //     errors.phone_number = 'Phone Number is required';
        // }

        if (validSelectedProjects.length === 0) {
            errors.project_id = 'At least one project must be selected';
        }

        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            $('#add_user_submit').removeClass('wc-spinner');
            return;
        } else {
            let add_obj = {
                name: name,
                email: email,
                phone_number: phone,
                project_id: selectedProjects.join(','),
                parent_id: user_id,
                user_role: 2
            };

            const form_Data = new FormData();
            Object.entries(add_obj).forEach(([key, value]) => {
                if (value !== null && value !== '') {
                    form_Data.append(key, value);
                }
            });

            var config = {
                method: 'post',
                url: themeconfig.APILinks.add_user,
                headers: {
                },
                data: form_Data
            };

            // console.log(add_obj);
            axios(config)
                .then(function (response) {
                    if (response) {
                        if (response.data.result === true) {
                            $('#add_user_submit').removeClass('wc-spinner');
                            toast.success(response.data.message, {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setTimeout(() => {
                                navigate(`/clients/${user_id}`)
                            }, 1500);
                        } else {
                            $('#add_user_submit').removeClass('wc-spinner');
                            toast.error(response.data.message, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                })
                .catch(function (error) {
                    $('#add_user_submit').removeClass('wc-spinner');
                    console.log(error);
                });
        }
    };


    useEffect(() => {
        get_project_data()
        $(document).on('keypress', '#Number', function (event) {
            var regex = new RegExp("^[0-9_ ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        });
    }, [])
    return (
        <div>
            <div className="container-fluid">
                <div className="row project-scan">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <ToastContainer />
                    </div>

                    <div className="col-xl-12 col-lg-12">
                        <div>
                            <form onSubmit={add_user}>
                                <h3>Create user</h3>
                                <div className="form-group">
                                    <h6>Name</h6>
                                    <input type="text" className="form-control" name='name' placeholder='Enter Full Name' />
                                    {formErrors.name && <div className="text-danger">{formErrors.name}</div>}
                                </div>
                                <div className="form-group">
                                    <h6>Email</h6>
                                    <input type="email" className="form-control" name='email' placeholder='Enter Email' />
                                    {formErrors.email && <div className="text-danger">{formErrors.email}</div>}
                                </div>
                                <div className="form-group">
                                    <h6>Phone</h6>
                                    <input type="tel" className="form-control" name='phone_number' placeholder='Enter Phone Number' id='Number' />
                                    {formErrors.phone_number && <div className="text-danger">{formErrors.phone_number}</div>}
                                </div>
                                <div className="form-group">
                                    <h6>Assign Projects to this user</h6>
                                    <Select
                                        isMulti
                                        name="project_id"
                                        options={project_data}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                    {formErrors.project_id && <div className="text-danger">{formErrors.project_id}</div>}
                                </div>
                                <br />
                                <button type="submit" className="btn btn-primary" id='add_user_submit'>Add User</button>
                            </form>
                        </div>
                    </div>
                </div>

                <hr />
            </div>
        </div>
    )
}

export default AddUserAdmin