import React, { useEffect, useState } from 'react'
import axios from '../util/api-client';
import { useParams } from "react-router-dom";
import themeconfig from '../Config/themeconfig';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const ShareLinkPhotoDownload = () => {
  var { project_id, photo_id, photo_key } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [photoData, setphotoData] = useState({})
  const [show_html, setshow_html] = useState(false)

  const get_data = () => {
    let obj = {
      attachment_id: photo_id,
      project_id: project_id,
      key: photo_key
    }
    var config = {
      method: 'post',
      url: themeconfig.APILinks.check_doc_key,
      headers: {},
      data: obj
    };

    axios(config)
      .then(function (response) {
        if (response.data.result === true) {
          //console.log(response.data.data)
          setshow_html(true)
          setphotoData(response.data.data)
        } else {
          setshow_html(false)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        // Set loading to false after the request is complete
        setIsLoading(false);
      });
  }

  // download photo

  const download_photo = (url = '', file_name = '') => {
    if (url) {
		window.location.href = `${themeconfig.APILinks.download_photo}?file_url=${url}`
      //window.location.href = `${themeconfig.APILinks.download_photo}?url=${url}&original_name=${file_name}`
      // `https://api.xtour.com.au/api/v1/document/download?url=${url}&original_name=${file_name}`
    }
  };

  const Skeleton_show = () => {
    return (
      <div className="wc_download_folder_wrap">
        <div className="wc_download_folder_col" style={{ minHeight: '315px' }}>
          <div className='image-share' style={{ minHeight: '54px', maxWidth: '54px', margin: '0 auto 15px' }}>
            <Skeleton height={54} width={54} />
          </div>
          <h1 style={{ minHeight: '38px', maxWidth: '340px', margin: '0 auto 20px' }}>
            <Skeleton height={38} width={340} />
          </h1>
          <div style={{ minHeight: '54px', maxWidth: '54px', margin: '0 auto 15px' }}>
            <Skeleton height={54} width={54} />
          </div>
          <div style={{ minHeight: '38px', maxWidth: '128px', margin: '0 auto' }}>
            <Skeleton height={38} />
          </div>
        </div>
      </div>
    )
  }

  const show_folder_html = (type = false) => {
    if (type === true) {
      return (
        <div className="wc_download_folder_wrap">
          <div className="wc_download_folder_col">
            <div className='image-share'>
              <img src={themeconfig.image.xtour_image} />
            </div>
            <h1>{photoData.file_name}</h1>
            <div>
              <span className="wc_download_folder_icon "><i className="fa-solid fa-image"></i></span>
            </div>
            <button className="btn btn-primary" onClick={() => download_photo(photoData.file_url, photoData.file_name)}>Download Photo</button>
          </div>
        </div>
      )
    } else {
      return (
        <div className="wc_download_folder_wrap">
          <div className="wc_download_folder_col">
            <h1>Access to this link has been disabled</h1>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    get_data()
  }, [])


  // Conditional rendering based on isLoading

  if (isLoading) {
    return (<div>
      {Skeleton_show()}
    </div>)
  } else {
    return (
      <div>
        {show_folder_html(show_html)}
      </div>
    );
  }
}

export default ShareLinkPhotoDownload


