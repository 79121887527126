import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify'
import themeconfig from '../../Config/themeconfig';
const Resetpassword = () => {
    const navigate = useNavigate();
    const currentUrl = window.location.href;
    let key = currentUrl.split('=')[1]
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const isPasswordValid = (password) => {
            const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
            return passwordPattern.test(password);
        };

        if (password.trim() === '' || confirmPassword.trim() === '') {
            setPasswordError('Both fields are required.');
            return;
        }

        if (password !== confirmPassword) {
            setPasswordError('Please Enter Both Password Same!...');
            return;
        }

        if (password.length < 8 || confirmPassword.length < 8) {
            setPasswordError('Password must be at least 8 characters long.');
            return;
        } else if (!isPasswordValid(password)) {
            setPasswordError('At least 8 characters. Must include at least one: uppercase letter, lowercase letter, number, and symbol.');
            return;
        } else {
            setPasswordError('')
        }
       
        // if (passwordError === '') {
            console.log('GOOD')
            let reset_pass_obj = {
                remember_token: key,
                new_password: password
            }
            var config = {
                method: 'POST',
                url: themeconfig.APILinks.reset_password,
                headers: {

                },
                data: reset_pass_obj
            };

            axios(config).then(function (response) {
                if (response) {
                    if (response.data.original.result === true) {
                        toast.success(response.data.original.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setPassword('');
                        setConfirmPassword('');
                        setPasswordError('');
                        setTimeout(() => {
                            navigate('/');
                        }, 2000);
                    } else {
                        toast.error(response.data.original.message, {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setTimeout(() => {
                            navigate('/');
                        }, 2000);
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });
        // }

    };

    return (
        <div className="bg-gradient-primary">
            <div className="container">
                <ToastContainer />
                <div className="row min-vh-100 align-items-center justify-content-center">
                    <div className="col-12">
                        <div className="card login-wrap o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                
                                        <div className="p-3 p-md-5">
                                            <div className="text-center">
                                                <img
                                                    className="log-logo"
                                                    src={themeconfig.image.xtour_image}
                                                    alt="Logo"
                                                />
                                                <h6>Reset Password</h6>
                                                <br />
                                            </div>
                                            <form className="user" onSubmit={handleSubmit}>
                                                {passwordError && (
                                                    <p className="error-message text-danger m-1">{passwordError}</p>
                                                )}
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        className="form-control form-control-user"
                                                        name="password"
                                                        placeholder="Enter New Password"
                                                        value={password}
                                                        onChange={handlePasswordChange}

                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        className="form-control form-control-user"
                                                        name="confirmPassword"
                                                        placeholder="Enter Confirm Password"
                                                        value={confirmPassword}
                                                        onChange={handleConfirmPasswordChange}

                                                    />
                                                </div>
                                                <button
                                                    id="submit_btn"
                                                    type="submit"
                                                    className="btn btn-dark btn-user btn-block"
                                                >
                                                    Continue
                                                </button>
                                                <hr />
                                            </form>
                                            <hr />
                                            <div className="text-center">
                                                <Link to="/">
                                                    <span style={{ color: 'black', textDecoration: 'none' }}>
                                                        Don’t Reset password? Log in
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Resetpassword;
