import React, { useEffect, useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import themeconfig from '../Config/themeconfig';
import axios from '../util/api-client';
const Project_UC = () => {

  const location = useLocation();

  let active_user_role = localStorage.getItem('user_role');
  let active_user_id = localStorage.getItem('active-user-id');
  const [get_all_project_data, setget_all_project_data] = useState([])
  const [project_loading, setproject_loading] = useState(true);
  let currentUrl = window.location.href;
  let search_project = '';
  search_project = currentUrl.split('s=')[1];
  const items = get_all_project_data;

  // start check image url is valid or not

  function isValidImageUrl(url) {
    const img = new Image();
    img.src = url;

    return new Promise((resolve) => {
      img.onload = function () {
        resolve(true);
      };

      img.onerror = function () {
        resolve(false);
      };
    });
  }

  function ImageComponent({ imageUrl, defaultImageUrl }) {
    const [isValid, setIsValid] = React.useState(false);

    React.useEffect(() => {
      isValidImageUrl(`https://my.matterport.com/api/v1/player/models/${imageUrl}/thumb`).then((valid) => {
        setIsValid(valid);
      });
    }, [imageUrl]);

    return (
      <div className="card-body scan-thumb wc-projects-grid-image">
        {isValid ? (
          <img src={`https://my.matterport.com/api/v1/player/models/${imageUrl}/thumb`} alt="Project" />
        ) : (
          <img src={defaultImageUrl} alt="Default" />
        )}
      </div>
    );
  }

  // end check image url is valid or not


  const project_view = (id) => {

    let project_data = {
      project_id: id,
      user_id: active_user_id
    }

    var config = {
      method: 'post',
      url: themeconfig.APILinks.analytics_add,
      headers: {},
      data: project_data
    };

    axios(config)
      .then(function (response) {
        //console.log(response)
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  function Items({ currentItems }) {
    return (
      <div className="row recent-scans">
        {
          project_loading ?
            <div className='wc-custom-loading' style={{ minHeight: '780px' }}>
              <div className="spinner-border mx-auto text-secondary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            :
            currentItems.length === 0 ? (
              <h3 className="col-md-12 text-center">No result found</h3>
            ) :
              currentItems.map((project, index) => {

                return (<div key={index} className={project.status === 1 ? "col-xl-4 col-lg-4 disable-click" : "col-xl-4 col-lg-4"}>
                  <div className="card shadow mb-4" onClick={() => project_view(project.id)}>
                    <Link to={`/projects-single/model/${project.id}`}>
                      <div className="card-header project-view-card py-3">
                        {
                          project.status === 1 ? <i className="fa-solid fa-ban"></i> : ''
                        }
                        <div className="font-weight-bold text-primary text-uppercase mb-1">
                          {/* {project.title}<br /> */}
                          <h4 className="grey">{project.title}</h4>
                        </div>
                        <h6 className="m-0 font-weight-bold text-primary">{project.created_date}</h6>
                      </div>
                      <ImageComponent
                        imageUrl={project.image}
                        defaultImageUrl={themeconfig.image.xtour_image}
                      />
                    </Link>
                  </div>
                </div>)
              })}
      </div>
    );
  }

  // get all project data 

  const get_all_project = () => {
    const decodedSearchProject = search_project !== undefined ? decodeURIComponent(search_project) : undefined;
    let obj = {};

    // Only include the "s" parameter if decodedSearchProject is not null and not undefined
    if (decodedSearchProject !== null && decodedSearchProject !== undefined) {
      obj.s = decodedSearchProject;
    }
    obj.user_role = active_user_role
    obj.user_id = active_user_id
    // let obj = {
    //   user_role: active_user_role,
    //   user_id: active_user_id
    // }
    var config = {
      method: 'get',
      url: themeconfig.APILinks.project_list,
      headers: {
      },
      params: obj
    };

    axios(config)
      .then(function (response) {
        if (response) {
          if (response.data.result === true) {
            setget_all_project_data(response.data.data)
            // console.log(response.data.data)
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      }).finally(() => {
        setproject_loading(false);
      });

  }


  function PaginatedItems({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          breakLabel="..."
          nextLabel={<i className="fas fa-angle-right ml-2"></i>}
          onPageChange={handlePageClick}
          containerClassName="d-flex align-items-center justify-content-center gap-2 list-unstyled"
          pageCount={pageCount}
          previousLabel={<i className="fas fa-angle-left mr-2"></i>}
          renderOnZeroPageCount={null}
          activeClassName="rounded bg-dark px-2 py-2 text-white"
        />
      </>
    );
  }

  useEffect(() => {
    get_all_project()
  }, [location])


  return (
    <div>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">All Projects</h1>
          <Link to='/project-booking' className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-sm text-white-50"></i> Project Booking</Link>
        </div>
        <PaginatedItems itemsPerPage={6} />
      </div>
    </div >
  )
}

export default Project_UC