import React, { useEffect, useState } from 'react'
import axios from '../util/api-client';
import { useParams } from "react-router-dom";
import themeconfig from '../Config/themeconfig';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const ShareLinkFolderDownload = () => {
    var { project_id, folder_id, folder_key } = useParams();

    let user_id = localStorage.getItem("active-user-id");

    const [isLoading, setIsLoading] = useState(true);
    const [folderData, setfolderData] = useState({})
    const [show_html, setshow_html] = useState(false)
    const get_data = () => {
        let obj = {
            attribute_id: folder_id,
            project_id: project_id,
            key: folder_key
        }
        var config = {
            method: 'post',
            url: themeconfig.APILinks.check_folder_key,
            headers: {},
            data: obj
        };

        axios(config)
            .then(function (response) {
                if (response.data.result === true) {
                    setshow_html(true)
                    setfolderData(response.data.data)
                } else {
                    setshow_html(false)
                }
            })
            .catch(function (error) {
                console.log(error);
            }).finally(() => {
                // Set loading to false after the request is complete
                setIsLoading(false);
            });
    }

    const show_folder_html = (type = false) => {
        if (type === true) {
            return (
                <div className="wc_download_folder_wrap">
                    <div className="wc_download_folder_col">
                        <div className='image-share'>
                            <img src={themeconfig.image.xtour_image} />
                        </div>
                        <h1>{folderData.name}</h1>
                        <div>
                            <span className="wc_download_folder_icon "><i className="fa-solid fa-folder"></i></span>
                        </div>
                        <button className="btn btn-primary" onClick={() => download_folder()}>Download Folder</button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="wc_download_folder_wrap">
                    <div className="wc_download_folder_col">
                        <h1>Access to this link has been disabled</h1>
                    </div>
                </div>
            )
        }
    }

    const Skeleton_show = () => {
        return (
            <div className="wc_download_folder_wrap">
                <div className="wc_download_folder_col" style={{ minHeight: '315px' }}>
                    <div className='image-share' style={{ minHeight: '54px', maxWidth: '54px', margin: '0 auto 15px' }}>
                        <Skeleton height={54} width={54} />
                    </div>
                    <h1 style={{ minHeight: '38px', maxWidth: '340px', margin: '0 auto 20px' }}>
                        <Skeleton height={38} width={340} />
                    </h1>
                    <div style={{ minHeight: '54px', maxWidth: '54px', margin: '0 auto 15px' }}>
                        <Skeleton height={54} width={54} />
                    </div>
                    <div style={{ minHeight: '38px', maxWidth: '128px', margin: '0 auto' }}>
                        <Skeleton height={38} />
                    </div>
                </div>
            </div>
        )
    }

    const download_folder = () => {
        window.location.href = themeconfig.APILinks.zip_download + `?project_id=${project_id}&attribute_id=${folder_id}&user_id=${user_id}`
    }

    useEffect(() => {
        get_data()
    }, [])


    if (isLoading) {
        return (<div>
            {Skeleton_show()}
        </div>)
    } else {
        return (
            <div>
                {show_folder_html(show_html)}
            </div>
        );
    }
}

export default ShareLinkFolderDownload


