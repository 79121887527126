import React, { useEffect, useState } from 'react';
import axios from '../util/api-client';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import themeconfig from '../Config/themeconfig';

const Profile = () => {
    let user_id = localStorage.getItem('active-user-id');
    const [active_user, setactive_user] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        email: '',
        address: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        phone_number: '',
        email: '',
        address: '',
    });

    // get active user data
    const get_active_user = () => {
        var config = {
            method: 'get',
            url: themeconfig.APILinks.get_all_user + user_id,
            headers: {},
        };

        axios(config)
            .then(function (response) {
                if (response) {
                    if (response.data.result === true) {
                        let active_user = response.data.data;
                        active_user.map((user) => {
                            setactive_user(user);
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const profile_update = (event) => {
        event.preventDefault();
        $('#profile_update').addClass('wc-spinner');
        const formElement = document.querySelector('#profile_form');
        const formData = new FormData(formElement);
        const formDataJSON = Object.fromEntries(formData);

        // Custom validation for all fields except password
        let isValid = true;
        const newErrors = { ...errors };

        for (const key in formDataJSON) {
            if (key !== 'password' && key !== 'address') {
                if (formDataJSON[key].trim() === '') {
                    newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')} cannot be blank.`;
                    isValid = false;
                } else {
                    newErrors[key] = '';
                }

                if (key === 'email' && !isValidEmail(formDataJSON[key])) {
                    newErrors[key] = 'Invalid email format.';
                    isValid = false;
                }
            }
        }

        setErrors(newErrors);

        // If any validation fails, stop form submission
        if (!isValid) {
            $('#profile_update').removeClass('wc-spinner');
            return;
        }

        // Proceed with form submission if all validations pass
        // const user_update_obj = Object.fromEntries(
        //   Object.entries(formDataJSON).filter(([_, v]) => v !== null && v !== '')
        // );

        // const user_update_obj = Object.fromEntries(
        //   Object.entries(formDataJSON).filter(([key, value]) => {
        //     // Exclude properties with specific names
        //     return value !== null && value !== '' && key !== 'address';
        //   })
        // );

        const user_update_obj = Object.fromEntries(
            Object.entries(formDataJSON).filter(([key, value]) => {
                // Include "address" property regardless of whether it's blank or not
                if (key === 'address') {
                    return true;
                }

                // Exclude other properties with blank values
                return value !== null && value !== '';
            })
        );




        var config = {
            method: 'post',
            url: themeconfig.APILinks.update_user,
            headers: {},
            data: user_update_obj,
        };

        axios(config)
            .then(function (response) {
                if (response) {
                    if (response.data.result === true) {
                        $('#profile_update').removeClass('wc-spinner');
                        toast.success('Profile Update Successfully!...', {
                            position: 'top-right',
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                        });
                        get_active_user();
                    } else {
                        $('#profile_update').removeClass('wc-spinner');
                        toast.error(response.data.message, {
                            position: 'top-right',
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log('error', error)
                $('#profile_update').removeClass('wc-spinner');
                toast.error('Please try some time later!..', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            });
    };

    useEffect(() => {
        get_active_user();
        $(document).on('keypress', '#phone1', function (event) {
            var regex = new RegExp("^[0-9_ ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        });

        $("#phone1").keyup(function () {
            var mobile_ele = $("#phone1");
            var mobileNum = mobile_ele.val();
            console.log(mobileNum)
            var formattedNum = mobileNum.replace(/(\d{2})(\d{3})(\d{3})/g, "04$1 $2 $3");
            mobile_ele.val(formattedNum);
        })
    }, []);

    return (
        <div>
            <ToastContainer />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 m-b-30">
                        <div className="d-block d-sm-flex flex-nowrap align-items-center">
                            <div className="page-title mb-2 mb-sm-0">
                                <h2>My Profile</h2>
                            </div>
                            <div className="ml-auto d-flex align-items-center">
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row account-contant">
                    <div className="col-12">
                        <div className="card card-statistics">
                            <div className="card-body p-0">
                                <div className="row no-gutters">
                                    <div className="col-xl-3 pb-xl-0 pb-5 border-right">
                                        <div className="page-account-profil pt-5">
                                            <div className="profile-img text-center rounded-circle">
                                                <div className="pt-5">
                                                    <div className="profile pt-4">
                                                        <h4 className="mb-1">{active_user.name}</h4>
                                                        {/* <p>XTOUR</p> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-md-8 col-12 border-t border-right">
                                        <div className="page-account-form">
                                            <div className="form-titel border-bottom p-3">
                                                <h5 className="mb-0 py-2">Edit Your Profile Settings</h5>
                                            </div>
                                            <div className="p-4">
                                                <form id='profile_form' onSubmit={profile_update}>
                                                    <div className="form-row"><h5 className="mb-0 py-2"><i className="fa fa-user"></i> User Information</h5>
                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="name1">Full Name</label>
                                                            <input type="text" className="form-control" id="name1" name='name' defaultValue={active_user.name} />
                                                            {errors.name && <div className="error-message text-danger">{errors.name}</div>}
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="phone1">Phone Number</label>
                                                            <input type="text" className="form-control" id="phone1" name='phone_number' defaultValue={active_user.phone_number} />
                                                            {errors.phone_number && <div className="error-message text-danger">{errors.phone_number}</div>}
                                                        </div>
                                                        {/* <div className="form-group col-md-12">
                              <label htmlFor="email1">Email</label>
                              <input type="email" className="form-control" id="email1" name='email' defaultValue={active_user.email} />
                              {errors.email && <div className="error-message text-danger">{errors.email}</div>}
                            </div> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="add1">Address</label>
                                                        <input type="text" className="form-control" id="add1" name='address' defaultValue={active_user.address} />
                                                        {errors.address && <div className="error-message text-danger">{errors.address}</div>}
                                                    </div>

                                                    <div className="form-row"><h5 className="mb-0 py-2"><i className="fa fa-lock"></i> Account Information</h5>
                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="email1">Email</label>
                                                            <input type="email" className="form-control" id="email1" name='email' defaultValue={active_user.email} disabled />
                                                            {errors.email && <div className="error-message text-danger">{errors.email}</div>}
                                                        </div>
                                                        <input type="text" className="form-control d-none" name='id' defaultValue={active_user.id} />
                                                    </div>
                                                    <div className="form-group">
                                                    </div>
                                                    <button type="submit" className="btn btn-primary " id='profile_update'>Update Information</button>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
