import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from './auth/login/Login';
import EmailLogin from './auth/login/EmailLogin';
import ProtectedRoute from './util/ProtectedRoute';
import Forgotpassword from './auth/forgotpassword/Forgotpassword';
import Resetpassword from './auth/resetpassword/Resetpassword';
import GlobleUserUpdate from './Components/GlobleUserUpdate'
import GlobleClientUpdate from './Components/GlobleClientUpdate'
import ShareLinkDocDownload from './Components/ShareLinkDocDownload'
import ShareLinkFolderDownload from './Components/ShareLinkFolderDownload'
import ShareLinkPhotoDownload from './Components/ShareLinkPhotoDownload'
import FirebaseProvider from './Components/FirebaseProvider';

const App = () => {
  return (
    <FirebaseProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/email-login" element={<EmailLogin />} />
          <Route path='forgotpassword' element={<Forgotpassword />} />
          <Route path='resetpassword' element={<Resetpassword />} />
          <Route path='update-client' element={<GlobleClientUpdate />} />
          <Route path='update-user' element={<GlobleUserUpdate />} />
          <Route path='share/:project_id/:folder_id/:folder_key' element={<ShareLinkFolderDownload />} />
          <Route path='share-doc/:project_id/:doc_id/:doc_key' element={<ShareLinkDocDownload />} />
          <Route path='share-photo/:project_id/:photo_id/:photo_key' element={<ShareLinkPhotoDownload />} />
          <Route path="/*" element={<ProtectedRoute />} />
          <Route index element={<Navigate to="/dashboard" />} />
        </Routes>
      </BrowserRouter>
    </FirebaseProvider>
    );
};

export default App;

