import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import axios from '../util/api-client';
import ReactPaginate from 'react-paginate';
import themeconfig from '../Config/themeconfig';
const Projects = () => {

    const location = useLocation();

    let active_user_role = localStorage.getItem('user_role');
    const [all_project_data, setall_project_data] = useState([])
    const [project_loading, setproject_loading] = useState(true);
    let currentUrl = window.location.href;
    let search_project = '';
    search_project = currentUrl.split('s=')[1];
    let search_client = '';
    search_client = currentUrl.split('client=')[1];
    const items = all_project_data;

    // start check image url is valid or not

    function isValidImageUrl(url) {
        const img = new Image();
        img.src = url;

        return new Promise((resolve) => {
            img.onload = function () {
                resolve(true);
            };

            img.onerror = function () {
                resolve(false);
            };
        });
    }

    function ImageComponent({ imageUrl, defaultImageUrl }) {
        const [isValid, setIsValid] = React.useState(false);

        React.useEffect(() => {
            isValidImageUrl(`https://my.matterport.com/api/v1/player/models/${imageUrl}/thumb`).then((valid) => {
                setIsValid(valid);
            });
        }, [imageUrl]);
        return (
            <div className="card-body scan-thumb wc-projects-grid-image">
                {isValid ? (
                    <img src={`https://my.matterport.com/api/v1/player/models/${imageUrl}/thumb`} alt="Project" />
                ) : (
                    <img src={defaultImageUrl} alt="Default" />
                )}
            </div>
        );
    }

    // end check image url is valid or not

    function Items({ currentItems }) {
        return (
            <div className="row recent-scans">
                {
                    project_loading ?
                        <div className='wc-custom-loading' style={{ minHeight: '780px' }}>
                            <div className="spinner-border mx-auto text-secondary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        currentItems.length === 0 ? (
                            <h3 className="col-md-12 text-center">No result found</h3>
                        ) :
                            currentItems.map((project, index) => {
                                return (
                                    <div key={index} className="col-xl-4 col-lg-4">
                                        <div className="card shadow mb-4">
                                            <Link to={`/projects-admin/edit-project/?id=${project.id}`}>
                                                <div className="card-header py-3">
                                                    {project.status === 1 ? <i className="fa-solid fa-ban"></i> : ''}
                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                        {project.created_date}<br />
                                                        <span className="grey">Client: {project.client_name}</span>
                                                    </div>
                                                    <h6 className="m-0 font-weight-bold text-primary">{project.title}</h6>
                                                </div>

                                                <ImageComponent
                                                    imageUrl={project.image}
                                                    defaultImageUrl={themeconfig.image.xtour_image}
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                );
                            })}
            </div>
        );
    }

    const get_all_project = () => {
        if (search_project) {
            search_project = search_project.split("&").shift();
        }
        const decodedSearchProject = search_project !== undefined ? decodeURIComponent(search_project) : undefined;
        const decodedSearchclient = search_client !== undefined ? decodeURIComponent(search_client) : undefined;

        let obj = {};

        // Only include the "s" parameter if decodedSearchProject is not null and not undefined
        if (decodedSearchProject !== null && decodedSearchProject !== undefined) {
            obj.s = decodedSearchProject;
        }
        if (decodedSearchclient !== null && decodedSearchclient !== undefined) {
            obj.user_id = decodedSearchclient
        }

        var config = {
            method: 'GET',
            url: themeconfig.APILinks.project_list,
            headers: {},
            params: obj,
        };

        axios(config).then(function (response) {
            if (response) {
                if (response.data.result === true) {
                    setall_project_data(response.data.data)
                }
            }
        }).catch(function (error) {
            console.log(error);
        }).finally(() => {
            setproject_loading(false);
        });
    }

    const display_btn = (role = '') => {
        if (role == 0) {
            return (
                <Link to="/projects-admin/add-project" >
                    <button className="btn btn-primary">Create Project</button>
                </Link>
            )
        } else {
            return (
                null
            )
        }
    }

    function PaginatedItems({ itemsPerPage }) {
        const [itemOffset, setItemOffset] = useState(0);

        const endOffset = itemOffset + itemsPerPage;
        const currentItems = items.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(items.length / itemsPerPage);

        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % items.length;
            setItemOffset(newOffset);
        };

        return (
            <>
                <Items currentItems={currentItems} />
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fas fa-angle-right ml-2"></i>}
                    onPageChange={handlePageClick}
                    containerClassName="d-flex align-items-center justify-content-center gap-2 list-unstyled"
                    pageCount={pageCount}
                    previousLabel={<i className="fas fa-angle-left mr-2"></i>}
                    renderOnZeroPageCount={null}
                    // pageLinkClassName="text-success-900 text-lg font-weight-bold h-8 sm:h-10 w-8 sm:w-10 d-flex align-items-center justify-content-center rounded"
                    activeClassName="rounded bg-dark px-2 py-2 text-white"
                />
            </>
        );
    }

    useEffect(() => {
        get_all_project()
    }, [location])


    return (
        <div>
            <div className="container-fluid">

                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">All Projects</h1>
                    {display_btn(active_user_role, 'ADD_PROJECT')}
                </div>

                <PaginatedItems itemsPerPage={6} />
            </div>
        </div>
    )
}

export default Projects