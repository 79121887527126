import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from '../util/api-client';
import Model from './Model'
import Documents from './Documents'
import Notes from './Notes'
import Photo from './Photo'
import Feedback from './Feedback'
import Statistics from './Statistics'

const ProjectSingle = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;

  const select_component = (url = '') => {
    if (url.includes(`/projects-single/model/${id}`)) {
      return <Model />
    } else if (url.includes(`/projects-single/documents/${id}`)) {
      return <Documents />
    } else if (url.includes(`/projects-single/photos/${id}`)) {
      return <Photo />
    } else if (url.includes(`/projects-single/notes/${id}`)) {
      return <Notes />
    } else if (url.includes(`/projects-single/feedback/${id}`)) {
      return <Feedback />
    } else if (url.includes(`/projects-single/statistics/${id}`)) {
      return <Statistics />
    } else {
      navigate('/projects')
    }
  }

  useEffect(() => {

    var config = {
      method: 'get',
      url: `https://api.xtour.com.au/api/v1/project/list?id=${id}`,
      headers: { 
      }
    };

    axios(config)
    .then(function (response) {
		if(response.data.data.length > 0){
			let project_data = response.data.data[0]; 
      		if(project_data.status === 1){
				navigate('/projects')
			}
		}
    })
    .catch(function (error) {
      console.log(error);
    });

  });

  return (
    <>
    <div className="scan-toolbar">
    <ul>
    <li><Link to={`/projects-single/model/${id}`}>Models</Link></li>
    <li><Link to={`/projects-single/documents/${id}`}>Documents</Link></li>
    <li><Link to={`/projects-single/photos/${id}`}>Photos</Link></li>
    <li><Link to={`/projects-single/notes/${id}`}>Notes</Link></li>
    <li><Link to={`/projects-single/feedback/${id}`}>Feedback</Link></li>
    <li><Link to={`/projects-single/statistics/${id}`}>Statistics</Link></li>
    </ul>
    </div>
    {select_component(currentPath)}
    </>
    )
}

export default ProjectSingle 