import React, { useEffect, useState } from 'react';
import themeconfig from '../Config/themeconfig';
import axios from '../util/api-client';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
const UpdateClients = () => {
    const navigate = useNavigate();
    let currunt_url = window.location.href;
    let edit_user_id = currunt_url.split('?id=')[1];
    let active_user = localStorage.getItem('active-user-id');
    let active_user_role = localStorage.getItem('user_role');
    const [edit_client_data, setedit_client_data] = useState({});
    const [errors, setErrors] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageError, setImageError] = useState('');
    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const get_client = () => {
        let obj = {
            id: edit_user_id,
        };
        var config = {
            method: 'GET',
            url: themeconfig.APILinks.get_all_clients,
            headers: {},
            params: obj,
        };

        axios(config)
            .then(function (response) {
                if (response) {
                    if (response.data.result === true) {
                        if (response.data.data) {
                            response.data.data.map((client) => {
                                setedit_client_data(client);
                                if (client.logo === '') {
                                    setSelectedImage(themeconfig.image.xtour_image)
                                } else {
                                    setSelectedImage(client.logo)
                                }
                            });
                        }
                    } else {
                        navigate('/clients')
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const delete_client = () => {
        Swal.fire({
            title: 'Are you sure you want delete this Client?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    id: edit_user_id,
                };
                var config = {
                    method: 'post',
                    url: themeconfig.APILinks.delete_clients,
                    headers: {},
                    data: obj,
                };

                axios(config)
                    .then(function (response) {
                        if (response) {
                            if (response.data.result === true) {
                                Swal.fire('Deleted!', response.data.message, 'success');
                                navigate('/clients');
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: `${response.data.message}`
                                })
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        });
    };

    const change_project_status = (status_id = '', status_name = '') => {
        Swal.fire({
            title: 'Are you sure you want Updated this Client Status?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update it!',
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    id: edit_user_id,
                    status: status_id,
                    type: 'user_status',
                };
                // console.log(obj);
                var config = {
                    method: 'post',
                    url: themeconfig.APILinks.user_update,
                    headers: {},
                    data: obj,
                };

                axios(config)
                    .then(function (response) {
                        if (response) {
                            if (response.data.result === true) {
                                get_client();
                                Swal.fire('Updated!', 'Status has been Updated.', 'success');
                                navigate('/clients');
                            } else {
                                console.log(response);
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        });
    };

    const display_btn = (status_id = '') => {
        if (status_id === 0) {
            return (
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => change_project_status(1, 'Disable')}
                >
                    Disable Client
                </button>
            );
        } else if (status_id === 1) {
            return (
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => change_project_status(0, 'Enable')}
                >
                    Enable Client
                </button>
            );
        } else {
            return null;
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const url = URL.createObjectURL(file)
        setSelectedImage(url);
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        const fileInput = document.getElementById('formFile');
        fileInput.value = null;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        $('#update_client').addClass('wc-spinner');
        const formElement = document.querySelector('#client_form_data');
        const formData = new FormData(formElement);
        let formDataJSON = Object.fromEntries(formData);
        const newErrors = {};
        let isValid = true;
        // console.log(selectedImage)
        // if (selectedImage === null) {
        //     console.log('image empty')
        //     isValid = false;
        // } 

        if (selectedImage === null || selectedImage === '' || selectedImage === undefined) {
            setImageError('Please select a Logo!.');
            setSelectedImage('')
            isValid = false;
        } else {
            setImageError('')
        }
        //  else if (formDataJSON.logo.type) {
        //     const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif']; // Add more types if needed
        //     if (!allowedImageTypes.includes(formDataJSON.logo.type)) {
        //         setImageError('Please select a valid logo file (JPEG, PNG, GIF).');
        //         setSelectedImage('')
        //         isValid = false;
        //     }
        // }

        for (const key of formData.keys()) {
            const value = formData.get(key);
            if (key !== 'password') {
                if (typeof value === 'string' && value.trim() === '') {
                    newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')} cannot be blank.`;
                    isValid = false;
                } else {
                    newErrors[key] = '';
                }

                if (key === 'email' && !isValidEmail(value)) {
                    newErrors[key] = 'Invalid email format.';
                    isValid = false;
                }
            }
        }

        setErrors(newErrors);

        if (!isValid) {
            $('#update_client').removeClass('wc-spinner');
            return;
        }


        const form_Data = new FormData();
        if (formDataJSON.logo) {
            if (formDataJSON.logo.name === '') {
                // Do nothing, as the file name is empty
            } else {
                const logoFile = formDataJSON.logo;
                form_Data.append('logo', logoFile);
            }
        }

        // Append other non-empty and non-null key-value pairs to form_Data

        Object.entries(formDataJSON).forEach(([key, value]) => {
            if (key !== 'logo' && value !== null && value !== '') {
                form_Data.append(key, value);
            }
        });

        var config = {
            method: 'post',
            url: themeconfig.APILinks.user_update,
            headers: {},
            data: form_Data
        };

        axios(config)
            .then(function (response) {
                if (response) {
                    if (response.data.result === true) {
                        $('#update_client').removeClass('wc-spinner');
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        get_client()

                        setTimeout(() => {
                            navigate('/clients');
                        }, 1500);
                    } else {
                        $('#update_client').removeClass('wc-spinner');
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }
            })
            .catch(function (error) {
                $('#update_client').removeClass('wc-spinner');
                console.log(error);
            });

    };



    useEffect(() => {
        get_client();
    }, []);

    return (
        <div>
            <ToastContainer />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 m-b-30">
                        <div className="d-block d-sm-flex flex-nowrap align-items-center">
                            <div className="page-title mb-2 mb-sm-0">
                                <h2>Update Client</h2>
                            </div>
                            <div className="ml-auto d-flex align-items-center"></div>
                        </div>
                    </div>
                </div>

                <div className="row account-contant">
                    <div className="col-12">
                        <div className="card card-statistics">
                            <div className="card-body p-0">
                                <div className="row no-gutters">
                                    <div className="col-xl-3 pb-xl-0 pb-5 border-right">
                                        <div className="page-account-profil pt-5">
                                            <div className="profile-img text-center rounded-circle">
                                                <div className="pt-5">
                                                    <div className="profile pt-4">
                                                        <div className='profile-img-box'>
                                                            {
                                                                edit_client_data.logo ?
                                                                    <img src={edit_client_data.logo} />
                                                                    :
                                                                    <img src={selectedImage} />
                                                            }
                                                        </div>
                                                        <h4 className="mb-1">{edit_client_data.business_name}</h4>
                                                        {/* <p>XTOUR</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-md-8 col-12 border-t border-right">
                                        <div className="page-account-form">
                                            <div className="form-titel border-bottom p-3">
                                                <h5 className="mb-0 py-2">Edit the clients profile</h5>
                                            </div>
                                            <div className="p-4">
                                                <form onSubmit={handleSubmit} id="client_form_data">
                                                    <div className="form-row">
                                                        <h5 className="mb-0 py-2">
                                                            <i className="fa fa-user"></i> User Information
                                                        </h5>
                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="name1">Full Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                name="name"
                                                                defaultValue={edit_client_data.name}
                                                            />
                                                            {errors.name && (
                                                                <div className="text-danger">{errors.name}</div>
                                                            )}
                                                        </div>

                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="name1">Business Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                name="business_name"
                                                                defaultValue={edit_client_data.business_name}
                                                            />
                                                            {errors.business_name && (
                                                                <div className="text-danger">
                                                                    {errors.business_name}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="phone1">Phone Number</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="phone1"
                                                                name="phone_number"
                                                                defaultValue={edit_client_data.phone_number}
                                                            />
                                                            {errors.phone_number && (
                                                                <div className="text-danger">
                                                                    {errors.phone_number}
                                                                </div>
                                                            )}
                                                        </div>
                                                        {
                                                            active_user_role == 1 ?
                                                                <div className="form-group col-md-12">
                                                                    <label htmlFor="email1">Email</label>
                                                                    <input
                                                                        type="email"
                                                                        className="form-control"
                                                                        id="email1"
                                                                        // name="email"
                                                                        defaultValue={edit_client_data.email}
                                                                        disabled
                                                                    />
                                                                    {errors.email && (
                                                                        <div className="text-danger">{errors.email}</div>
                                                                    )}
                                                                </div>
                                                                :
                                                                <div className="form-group col-md-12">
                                                                    <label htmlFor="email1">Email</label>
                                                                    <input
                                                                        type="email"
                                                                        className="form-control"
                                                                        id="email1"
                                                                        name="email"
                                                                        defaultValue={edit_client_data.email}
                                                                    />
                                                                    {errors.email && (
                                                                        <div className="text-danger">{errors.email}</div>
                                                                    )}
                                                                </div>
                                                        }

                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="add1">Address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="add1"
                                                            name="address"
                                                            defaultValue={edit_client_data.address}
                                                        />
                                                        {errors.address && (
                                                            <div className="text-danger">{errors.address}</div>
                                                        )}
                                                    </div>
                                                    <div className='wc-logo-upload'>
                                                        <div className="form-group">
                                                            <label htmlFor="add2">Company logo</label>
                                                            {selectedImage && (
                                                                <div className='wc-logo-upload-box'>
                                                                    <img
                                                                        src={selectedImage ? selectedImage : ''}
                                                                        alt="Selected logo"
                                                                    />
                                                                    {selectedImage ? <button onClick={handleRemoveImage}>X</button> : ''}
                                                                </div>
                                                            )}
                                                            <br />
                                                            <input
                                                                className="form-control"
                                                                type="file"
                                                                name="logo"
                                                                id="formFile"
                                                                onChange={handleImageChange}
                                                            />
                                                            {imageError && <div className="error-message text-danger">{imageError}</div>}
                                                        </div>
                                                    </div>

                                                    <div className="form-row">
                                                        <h5 className="mb-0 py-2">
                                                            <i className="fa fa-lock"></i> Account Information
                                                        </h5>
                                                        <div className="form-group col-md-12">
                                                            <input
                                                                type="text"
                                                                className="form-control d-none"
                                                                name="id"
                                                                defaultValue={edit_client_data.id}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group"></div>
                                                    <button type="submit" className="btn btn-primary " id='update_client'>
                                                        Update Information
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                {display_btn(edit_client_data.status)}

                <button
                    type="button"
                    className="btn btn-primary float-right"
                    onClick={() => delete_client()}
                >
                    Delete Client
                </button>
            </div >
        </div >
    );
};

export default UpdateClients;