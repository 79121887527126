import React, { useEffect, useState } from 'react';
import themeconfig from '../Config/themeconfig';
import axios from '../util/api-client';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'

const ClientProfile = () => {
    const navigate = useNavigate();
    let active_user = localStorage.getItem('active-user-id');

    const [edit_client_data, setedit_client_data] = useState({});
    const [errors, setErrors] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);

    const get_client = () => {
        let obj = {
            id: active_user,
        };
        var config = {
            method: 'GET',
            url: themeconfig.APILinks.get_all_clients,
            headers: {},
            params: obj,
        };

        axios(config)
            .then(function (response) {
                if (response) {
                    if (response.data.result === true) {
                        if (response.data.data) {
                            response.data.data.map((client) => {
                                setedit_client_data(client);
                                setSelectedImage(client.logo)
                            });
                        }
                    } else {
                        navigate('/clients')
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const url = URL.createObjectURL(file)
        setSelectedImage(url);
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        const fileInput = document.getElementById('formFile');
        fileInput.value = null;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        $('#update_client').addClass('wc-spinner');
        const formElement = document.querySelector('#client_form_data');
        const formData = new FormData(formElement);
        let formDataJSON = Object.fromEntries(formData);
        const newErrors = {};
        let isValid = true;

        for (const key of formData.keys()) {
            const value = formData.get(key);
            if (key !== 'password' && key !== 'address') {
                if (typeof value === 'string' && value.trim() === '') {
                    newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')} cannot be blank.`;
                    isValid = false;
                } else {
                    newErrors[key] = '';
                }
            }
        }
        setErrors(newErrors);

        if (!isValid) {
            $('#update_client').removeClass('wc-spinner');
            return;
        }


        const form_Data = new FormData();


        // Append other non-empty and non-null key-value pairs to form_Data

        Object.entries(formDataJSON).forEach(([key, value]) => {
            if (value !== null && value !== '') {
                form_Data.append(key, value);
            } else if (key === 'address') {
                form_Data.append(key, '');
            }
        });


        var config = {
            method: 'post',
            url: themeconfig.APILinks.user_update,
            headers: {},
            data: form_Data
        };

        axios(config)
            .then(function (response) {
                if (response) {
                    if (response.data.result === true) {
                        $('#update_client').removeClass('wc-spinner');
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        get_client()
                        $('.wc-profile-click').click();
                        // setTimeout(() => {
                        //     navigate('/dashboard');
                        // }, 1500);
                    } else {
                        $('#update_client').removeClass('wc-spinner');
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }
            })
            .catch(function (error) {
                $('#update_client').removeClass('wc-spinner');
                console.log(error);
            });

    };

    useEffect(() => {
        get_client();
        $(document).on('keypress', '#phone1', function (event) {
            var regex = new RegExp("^[0-9_ ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        });
    }, []);

    return (
        <div>
            <ToastContainer />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 m-b-30">
                        <div className="d-block d-sm-flex flex-nowrap align-items-center">
                            <div className="page-title mb-2 mb-sm-0">
                                <h2>My Profile</h2>
                            </div>
                            <div className="ml-auto d-flex align-items-center"></div>
                        </div>
                    </div>
                </div>

                <div className="row account-contant">
                    <div className="col-12">
                        <div className="card card-statistics">
                            <div className="card-body p-0">
                                <div className="row no-gutters">
                                    <div className="col-xl-3 pb-xl-0 pb-5 border-right">
                                        <div className="page-account-profil pt-5">
                                            <div className="profile-img text-center rounded-circle">
                                                <div className="pt-5">
                                                    <div className="profile pt-4">
                                                        <div className='profile-img-box'>
                                                            {
                                                                edit_client_data.logo ?
                                                                    <img src={edit_client_data.logo} alt={themeconfig.image.xtour_image} />
                                                                    :
                                                                    <img src={themeconfig.image.xtour_image} />
                                                            }
                                                        </div>
                                                        <h4 className="mb-1">{edit_client_data.business_name}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-md-8 col-12 border-t border-right">
                                        <div className="page-account-form">
                                            <div className="form-titel border-bottom p-3">
                                                <h5 className="mb-0 py-2">Edit Your Profile Settings</h5>
                                            </div>
                                            <div className="p-4">
                                                <form onSubmit={handleSubmit} id="client_form_data">
                                                    <div className="form-row">
                                                        <h5 className="mb-0 py-2">
                                                            <i className="fa fa-user"></i> User Information
                                                        </h5>
                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="name1">Full Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                name="name"
                                                                defaultValue={edit_client_data.name}
                                                            />
                                                            {errors.name && (
                                                                <div className="text-danger">{errors.name}</div>
                                                            )}
                                                        </div>

                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="name1">Business Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                name="business_name"
                                                                defaultValue={edit_client_data.business_name}
                                                            />
                                                            {errors.business_name && (
                                                                <div className="text-danger">
                                                                    {errors.business_name}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="phone1">Phone Number</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="phone1"
                                                                name="phone_number"
                                                                defaultValue={edit_client_data.phone_number}
                                                            />
                                                            {errors.phone_number && (
                                                                <div className="text-danger">
                                                                    {errors.phone_number}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="add1">Address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="add1"
                                                            name="address"
                                                            defaultValue={edit_client_data.address}
                                                        />
                                                        {errors.address && (
                                                            <div className="text-danger">{errors.address}</div>
                                                        )}
                                                    </div>
                                                    <div className='wc-logo-upload'>
                                                        <div className="form-group">
                                                            <label htmlFor="add2">Company logo</label>
                                                            {selectedImage && (
                                                                <div className='wc-logo-upload-box'>
                                                                    <img
                                                                        src={selectedImage ? selectedImage : ''}
                                                                        alt="Selected logo"
                                                                    />
                                                                    {selectedImage ? <button onClick={handleRemoveImage}>X</button> : ''}
                                                                </div>
                                                            )}
                                                            <br />
                                                            <input
                                                                className="form-control"
                                                                type="file"
                                                                name="logo"
                                                                id="formFile"
                                                                onChange={handleImageChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-row">
                                                        <h5 className="mb-0 py-2">
                                                            <i className="fa-solid fa-envelope"></i> Account Information
                                                        </h5>
                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="email1">Email</label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="email1"
                                                                name="email"
                                                                defaultValue={edit_client_data.email}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            <input
                                                                type="text"
                                                                className="form-control d-none"
                                                                name="parent_id"
                                                                defaultValue={active_user}
                                                            />
                                                            <input
                                                                type="text"
                                                                className="form-control d-none"
                                                                name="id"
                                                                defaultValue={edit_client_data.id}
                                                            />
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary " id='update_client'>
                                                        Update Information
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </div>
    );
};

export default ClientProfile;