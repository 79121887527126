import React from 'react'
import { Link } from "react-router-dom";
const Knowledgebase = () => {
    return (
        <div>
            <div id="content">
                <div className="scan-toolbar">
                    <ul>
                        <li><Link to='/help-support'>Request Support</Link></li>
                        <li><Link to="/help-support/knowledgebase">Knowledgebase</Link></li>
                        <li><Link to="/help-support/support-faqs">FAQs</Link></li>
                    </ul>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row project-scan">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Knowledgebase</h1>
                    </div>

                    <div className="col-xl-12 col-lg-12">
                        <div className="col-xl-6 col-lg-6">
                            <div>
                                Knowledgebase articles go here
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default Knowledgebase