import React, { useEffect, useState } from 'react'
import axios from '../util/api-client';
import Swal from 'sweetalert2'
import themeconfig from '../Config/themeconfig';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
const UpdateUser = () => {
    const navigate = useNavigate();
    let currunt_url = window.location.href;
    let edit_user_id = currunt_url.split('?id=')[1];
    let active_user = localStorage.getItem('active-user-id');
    let active_user_role = localStorage.getItem('user_role');
    const [edit_user_data, setedit_user_data] = useState({})
    const [formErrors, setFormErrors] = useState({});
    const [all_project_data, setall_project_data] = useState([])
    const get_edit_user_data = () => {
        let obj = {
            id: edit_user_id
        }
        var config = {
            method: 'get',
            url: themeconfig.APILinks.user_list,
            headers: {
            },
            params: obj
        };

        axios(config).then(function (response) {
            if (response) {
                if (response.data.result === true) {
                    let user_data = []
                    user_data = response.data.data
                    user_data.map((data) => {
                        setedit_user_data(data)
                    })
                } else {
                    console.log('error')
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const get_project_data = () => {
        let obj = {
            user_id: active_user,
            user_role: active_user_role
        }
        var config = {
            method: 'get',
            url: themeconfig.APILinks.get_project_defualt_data,
            headers: {},
            params: obj
        };

        axios(config).then(function (response) {
            if (response) {
                if (response.data.result === true) {
                    setall_project_data(response.data.data)
                    // console.log(response.data.data)
                }
            }
        }).catch(function (error) {
            console.log(error);
        });

    }

    // delete user data 

    const delete_user_data = () => {
        Swal.fire({
            title: 'Are you sure you want delete this User?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    id: edit_user_id
                }
                var config = {
                    method: 'post',
                    url: themeconfig.APILinks.delete_user,
                    headers: {},
                    data: obj
                };

                axios(config)
                    .then(function (response) {
                        if (response) {
                            if (response.data.result === true) {
                                Swal.fire(
                                    'Deleted!',
                                    'User Deleted',
                                    'success'
                                )
                                setTimeout(() => {
                                    navigate('/users')
                                }, 1000);
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: `${response.data.message}`,
                                })
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            }
        })
    }

    const update_user = (event) => {
        event.preventDefault();
        $('#update_user_btn').addClass('wc-spinner');
        const formData = new FormData(event.target);
        const name = formData.get('name');
        const email = formData.get('email');
        const phone = formData.get('phone_number');
        const selectedProjects = formData.getAll('project_id');

        const validSelectedProjects = selectedProjects.filter(projectId => projectId.trim() !== '');

        const errors = {};

        if (!name.trim()) {
            errors.name = 'Full Name is required';
        }

        // if (!email.trim()) {
        //     errors.email = 'Email is required';
        // } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        //     errors.email = 'Invalid Email';
        // }

        // if (!phone.trim()) {
        //     errors.phone_number = 'Phone Number is required';
        // }

        if (validSelectedProjects.length === 0) {
            errors.project_id = 'At least one project must be selected';
        }

        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            $('#update_user_btn').removeClass('wc-spinner');
            console.log(errors)
            return;
        } else {
            let add_obj = {
                name: name,
                // email: email,
                phone_number: phone,
                project_id: selectedProjects.join(','),
                id: edit_user_data.id,
                user_role: 2
            };

            const form_Data = new FormData();
            Object.entries(add_obj).forEach(([key, value]) => {
                if (value !== null && value !== '') {
                    form_Data.append(key, value);
                }
            });

            var config = {
                method: 'post',
                url: themeconfig.APILinks.user_update,
                headers: {

                },
                data: add_obj
            };

            axios(config)
                .then(function (response) {
                    if (response) {
                        if (response.data.result === true) {
                            $('#update_user_btn').removeClass('wc-spinner');
                            toast.success(response.data.message, {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setTimeout(() => {
                                navigate('/users')
                            }, 1500);
                        } else {
                            $('#update_user_btn').removeClass('wc-spinner');
                            toast.error(response.data.message, {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                })
                .catch(function (error) {
                    $('#update_user_btn').removeClass('wc-spinner');
                    console.log(error);
                });
        }
    }

    useEffect(() => {
        get_project_data()
        get_edit_user_data()
        $(document).on('keypress', '#business-phone', function (event) {
            var regex = new RegExp("^[0-9_ ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        });
    }, [])


    return (
        <div>
            <div className="container-fluid">

                <div className="row project-scan">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <ToastContainer />
                    </div>

                    <div className="col-xl-12 col-lg-12">
                        <div>

                            <form onSubmit={update_user}>
                                <h3>Edit user</h3>
                                <div className="form-group">
                                    <h6>Name</h6>
                                    <input type="text" className="form-control" id="business-name" name='name' defaultValue={edit_user_data.name} />
                                    {formErrors.name && <div className="text-danger">{formErrors.name}</div>}
                                </div>
                                <div className="form-group">
                                    <h6>Email</h6>
                                    <input type="email" className="form-control" id="business-email" name='email' defaultValue={edit_user_data.email} disabled />
                                    {/* {formErrors.email && <div className="text-danger">{formErrors.email}</div>} */}
                                </div>
                                <div className="form-group">
                                    <h6>Phone</h6>
                                    <input type="phone" className="form-control" id="business-phone" name='phone_number' defaultValue={edit_user_data.phone_number} />
                                    {formErrors.phone_number && <div className="text-danger">{formErrors.phone_number}</div>}
                                </div>
                                <br />
                                <div className="form-group">
                                    <h6>Assign Projects to this user</h6>
                                    {
                                        edit_user_data.project_data ?
                                            <>
                                                <Select
                                                    isMulti
                                                    name="project_id"
                                                    options={all_project_data}
                                                    defaultValue={edit_user_data.project_data}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                />
                                                {formErrors.project_id && <div className="text-danger">{formErrors.project_id}</div>}
                                            </>
                                            :
                                            ''
                                    }

                                </div>
                                <button type="submit" className="btn btn-primary" id='update_user_btn'>Update User</button>
                            </form>
                            <div>

                            </div>
                            <br />
                            <button type="submit" className="btn btn-primary float-right" onClick={() => delete_user_data()}>Delete User</button>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default UpdateUser

