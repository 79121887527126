import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify'
import $ from 'jquery';
import axios from '../util/api-client';
import themeconfig from '../Config/themeconfig';
import { useNavigate } from "react-router-dom";
const AddClients = () => {
    const navigate = useNavigate();
    let active_user = localStorage.getItem('active-user-id');
    const [Data, setData] = useState({
        business_name: '',
        email: '',
    });

    const [errors, setErrors] = useState({
        business_name: '',
        email: '',
    });

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const add_clients_data = (event) => {
        event.preventDefault();
        $('#add_client_submit').addClass('wc-spinner');
        let isValid = true;
        const newErrors = { ...errors };

        if (Data.business_name.trim() === '') {
            newErrors.business_name = 'Business Name is required!...';
            isValid = false;
        } else {
            newErrors.business_name = '';
        }

        if (Data.email.trim() === '') {
            newErrors.email = 'Email is required!...';
            isValid = false;
        } else if (!isValidEmail(Data.email)) {
            newErrors.email = 'Please Enter Valid Email.';
            isValid = false;
        } else {
            newErrors.email = '';
        }

        setErrors(newErrors);

        if (!isValid) {
            $('#add_client_submit').removeClass('wc-spinner');
            return;
        } else {

            let obj = {
                parent_id: active_user,
                business_name: Data.business_name,
                email: Data.email,
                user_role: 1
            }
            // console.log(obj)
            var config = {
                method: 'post',
                url: themeconfig.APILinks.add_user,
                headers: {
                },
                data: obj
            };

            axios(config)
                .then(function (response) {
                    if (response) {
                        if (response.data.result === true) {
                            console.log(response)
                            $('#add_client_submit').removeClass('wc-spinner');
                            setData({
                                business_name: '',
                                email: '',
                            });
                            toast.success('Client Add Successfully!...', {
                                position: "top-right",
                                autoClose: 1000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setTimeout(() => {
                                navigate("/clients");
                            }, 1500);
                        } else {
                            $('#add_client_submit').removeClass('wc-spinner');
                            toast.error(response.data.message, {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });


        }

    };

    return (
        <div className="container-fluid">
            <div className="row project-scan">
                <div className="d-sm-flex align-items-center justify-content-between mb-4"></div>
                <ToastContainer />
                <div className="col-xl-12 col-lg-12">
                    <div>
                        <form onSubmit={add_clients_data} id="add_clients">
                            <div className="form-group">
                                <h6>Business Name</h6>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="business-name"
                                    name="business_name"
                                    value={Data.business_name}
                                    onChange={(e) =>
                                        setData({ ...Data, business_name: e.target.value })
                                    }
                                />
                                {errors.business_name && (
                                    <div className="error-message text-danger m-1">{errors.business_name}</div>
                                )}
                            </div>
                            <div className="form-group">
                                <h6>Email</h6>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="business-email"
                                    name="email"
                                    value={Data.email}
                                    onChange={(e) =>
                                        setData({ ...Data, email: e.target.value })
                                    }
                                />
                                {errors.email && (
                                    <div className="error-message text-danger m-1">{errors.email}</div>
                                )}
                            </div>
                            <br />
                            <div>
                                <small>
                                    By pressing 'Add Client' this will send the registered email a
                                    link to create their own password and input the other required
                                    profile information
                                </small>
                            </div>
                            <button type="submit" className="btn btn-primary" id='add_client_submit'>
                                Add Client
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <hr />
        </div>
    );
};

export default AddClients;
